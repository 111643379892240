import { AppRoute } from "@app/routes/constants";
import { ReactComponent as KrechetMobileLight } from "@assets/logo_krechet_mobile_light.svg";
import { ReactComponent as KrechetMobileDark } from "@assets/logo_krechet_mobile_dark.svg";
import { ReactComponent as KrechetDesktopLight } from "@assets/logo_krechet_desktop_light.svg";
import { ReactComponent as KrechetDesktopDark } from "@assets/logo_krechet_desktop_dark.svg";
import { ENavMenuItem, MainTab } from "@pages/Main/constants";
import { MenuGroup } from "@app/layouts/MainLayout/components/constants";
import type { AppConfig } from "../../types";

const telegramBotEnabled = false;

export const krechetStageConfig: AppConfig = {
  app: {
    icon: {
      desktop: {
        dark: {
          Icon: KrechetDesktopDark,
          path: "src/assets/logo_krechet_desktop_dark.svg",
        },
        light: {
          Icon: KrechetDesktopLight,
          path: "src/assets/logo_krechet_desktop_light.svg",
        },
      },
      mobile: {
        dark: {
          Icon: KrechetMobileDark,
          path: "src/assets/logo_krechet_mobile_dark.svg",
        },
        light: {
          Icon: KrechetMobileLight,
          path: "src/assets/logo_krechet_mobile_light.svg",
        },
      },
    },
  },
  features: {
    appEnabled: true,

    authResetPasswordEnabled: false,
    authUserSelfRegistrationEnabled: false,

    chatsAvailable: true,
    documentsPageEnabled: false,

    mainIncludeOpenSourcesEnabled: true,
    mainMultiQueryEnabled: true,

    onboardingEnabled: false,

    reportCrossingBordersExtended: true,
    reportFioAsGift: false,
    reportFooterDescriptionEnabled: true,
    reportPdfFooterLinkEnabled: true,
    reportPossibleNetworksCreateReportEnabled: true,
    reportStatusInverted: false,

    tabEmailLightSearch: false,
    tabFioSopdEnabled: false,
    tabFullDossierLightDriverLicenseEnabled: false,
    tabMainSopdEnabled: false,
    tabPhoneLightSearch: false,
    tabVkSopdEnabled: false,

    telegramBotEnabled,
    themeSwitchEnabled: true,

    userSettingsGroupType: false,
  },
  hosts: {
    apiDocsHref: "https://docs.odyssey-search.ru",
  },
  navMenu: {
    items: [
      {
        children: [
          MainTab.FullDossier,
          MainTab.Fio,
          MainTab.Phone,
          MainTab.Info,
          MainTab.Address,
          MainTab.Photo,
          MainTab.Email,
          MainTab.InnOrSnils,
          MainTab.Passport,
          MainTab.Auto,
          MainTab.DriverLicense,
          MainTab.Company,
          MainTab.CreditRating,
          MainTab.Scoring,
          MainTab.Vk,
          MainTab.Telegram,
        ],
        key: MenuGroup.Checking,
      },
      {
        children: [ENavMenuItem.Analytics, ENavMenuItem.History],
        key: MenuGroup.Cabinet,
      },
      {
        children: [ENavMenuItem.Action, ENavMenuItem.Accesses],
        key: MenuGroup.Organization,
      },
      ...(telegramBotEnabled ? [{ key: MenuGroup.Telegram }] : []),
    ],
  },
  pages: {
    analytics: {
      graphColors: {
        all: {
          /**
           * --color-main-0
           */
          bgGradientBottom: "rgba(61, 101, 143, 0)",
          /**
           * --color-main-300
           */
          bgGradientTop: "rgba(61, 101, 143, 0.3)",
          /**
           * --color-main-1000
           */
          borderColor: "rgb(61, 101, 143)",
        },
        target: {
          /**
           * --color-base-accident-0
           */
          bgGradientBottom: "rgba(251, 139, 91, 0)",
          /**
           * --color-base-accident-300
           */
          bgGradientTop: "rgba(251, 139, 91, 0.3)",
          /**
           * --color-base-accident-1000
           */
          borderColor: "rgba(251, 139, 91, 1)",
        },
      },
    },
  },
  routes: [
    { key: AppRoute.Analytics },
    { key: AppRoute.Employees },
    { key: AppRoute.Accesses },
    { key: AppRoute.History },
    { key: AppRoute.Action },
    { key: AppRoute.User },
    { key: AppRoute.AccessesAdd },
    { key: AppRoute.AccessesEdit },
    { key: AppRoute.Search },
    { key: AppRoute.Alerts },
  ],
  title: "Krechet",
};
