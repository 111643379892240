import { validationBounds, validationRegex } from "./constants";

export const correctLettersSpacesHyphenDashRegexCallback = (value?: string) => {
  if (!value) return true;

  return validationRegex.lettersSpacesHyphenDash.test(value);
};

export const correctLettersRegexCallback = (value?: string) => {
  if (!value) return true;

  return validationRegex.letters.test(value);
};

export const correctKadNumberRegexCallback = (value?: string) => {
  if (!value) return true;

  return validationRegex.kadNumber.test(value);
};

export const correctDigitRegexCallback = (value?: string) => {
  if (!value) return true;

  return validationRegex.digits.test(value);
};

export const correctAutoNumberRegexCallback = (value?: string) => {
  if (!value) return true;

  return validationRegex.autoNumber.test(value);
};

export const correctVinRegexCallback = (value?: string) => {
  if (!value) return true;

  return validationRegex.vin.test(value);
};

export const correctSnilsRegexCallback = (value?: string) => {
  if (!value) return true;

  return validationRegex.snils.test(value);
};

export const correctPassportRegexCallback = (value?: string) => {
  if (!value) return true;

  return validationRegex.passport.test(value);
};

export const correctUrlRegexCallback = (value?: string) => {
  if (!value) return true;

  return validationRegex.url.test(value);
};

export const correctDateCallback = (value?: string) => {
  if (!value) return true;

  if (validationRegex.date.test(value)) {
    const splitDate = value.split(".");
    const isDayCorrect =
      Number(splitDate[0]) >= validationBounds.Day.Min &&
      Number(splitDate[0]) <= validationBounds.Day.Max;
    const isMonthCorrect =
      Number(splitDate[1]) >= validationBounds.Month.Min &&
      Number(splitDate[1]) <= validationBounds.Month.Max;
    const isYearCorrect =
      Number(splitDate[2]) >= validationBounds.Year.Min &&
      Number(splitDate[2]) <= validationBounds.Year.Max;
    return isDayCorrect && isMonthCorrect && isYearCorrect;
  }
  return false;
};
