import { useParams } from "react-router-dom";
import { useReportsScoringRetrieve } from "@femida1/gui_web_core";
import { Skeleton } from "antd";
import type { ReportCommonProps } from "@femida1/schemas";
import { RetrieveScoring } from "@entities/RetrieveScoring/RetrieveScoring";

export const SearchScoringWidget = ({
  type,
}: Omit<ReportCommonProps, "reportId">) => {
  const { reportId = "" } = useParams();

  const { data: scoringReport, isLoading } = useReportsScoringRetrieve({
    id: reportId,
  });

  return (
    <Skeleton loading={isLoading}>
      {scoringReport && (
        <RetrieveScoring
          report={scoringReport}
          type={type}
          reportId={reportId}
        />
      )}
    </Skeleton>
  );
};
