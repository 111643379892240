import { isMainTab, type MainTab } from "@pages/Main/constants";
import { Permission } from "@femida1/schemas";
import { mapPermissionToMainTab } from "./constants";

const isValidPermission = (
  permission: Permission,
): permission is keyof typeof mapPermissionToMainTab => {
  return Object.hasOwn(mapPermissionToMainTab, permission);
};

export const getAvailableCheckTabs = <T extends { key: string }[]>(
  tabs: T | undefined,
  permissions?: Permission[],
): T | undefined => {
  if (!tabs) return undefined;

  if (!permissions || permissions.includes(Permission.SuperuserPermission)) {
    return tabs;
  }

  const availableTabKeys: MainTab[] = permissions
    .filter((permission) => isValidPermission(permission))
    .map((permission) => mapPermissionToMainTab[permission])
    .filter((mainTabLike) => isMainTab(mainTabLike));

  const availableTabKeysSet = new Set(availableTabKeys);

  return tabs.filter(
    (tab) => tab.key && availableTabKeysSet.has(tab.key as MainTab),
  ) as T;
};
