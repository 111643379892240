import type { FC } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Carousel, Modal } from "antd";
import { Flex, Block, Text, Button, useBooleanState } from "@femida1/uikit";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import type { CarouselRef } from "antd/es/carousel";
import s from "./OnboardingModal.module.scss";
import type { OnboardingPage } from "./types";
import { OnboardingModalStorage } from "../OnboardingModalStorage";
import { mapOnboardingContent } from "./constants";

interface OnboardingModalProps {
  onboardingPage: OnboardingPage;
}

export const OnboardingModal: FC<OnboardingModalProps> = ({
  onboardingPage,
}) => {
  const isShowOnboarding = OnboardingModalStorage.get(onboardingPage);

  useEffect(() => {
    if (isShowOnboarding === null)
      OnboardingModalStorage.set(onboardingPage, true);
  }, [isShowOnboarding, onboardingPage]);

  const carouselRef = useRef<CarouselRef>(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const {
    state: isModalVisible,
    setTrue: showModal,
    setFalse: hideModal,
  } = useBooleanState(false);
  const { state: isPopupVisible, setFalse: hidePopup } = useBooleanState(true);

  const handleNext = useCallback(() => {
    carouselRef.current?.next();
  }, []);

  const handlePrev = useCallback(() => {
    carouselRef.current?.prev();
  }, []);

  const onSlideChange = useCallback((current: number) => {
    setCurrentSlide(current);
  }, []);

  const onHideOnboarding = useCallback(() => {
    hidePopup();
    OnboardingModalStorage.set(onboardingPage, false);
  }, [hidePopup, onboardingPage]);

  if (!isShowOnboarding) return null;

  const slides = mapOnboardingContent[onboardingPage];
  const isLastSlide = slides.length === currentSlide + 1;

  return (
    <>
      {isPopupVisible && (
        <Block className={!isModalVisible ? s.popup : s.popup_hide}>
          <Flex align="center" justify="space-between">
            <Text variant="label-4">
              Что нового? Посмотрите обзор, как пользоваться новым интерфейсом.
            </Text>
            <Flex align="center" gap={12}>
              <Button type="flat_secondary" size="s" onClick={onHideOnboarding}>
                <Text variant="body-1" color="secondary">
                  Больше не показывать
                </Text>
              </Button>
              <Button type="primary" size="s" onClick={showModal}>
                <Text color="inherit" variant="body-1">
                  Смотреть обзор
                </Text>
              </Button>
            </Flex>
          </Flex>
        </Block>
      )}

      <Modal
        open={isModalVisible}
        onCancel={hideModal}
        wrapClassName={s.modalWrap}
        destroyOnClose
        footer={[
          <Text
            key="counter"
            color="secondary"
            variant="body-5"
          >{`${currentSlide + 1}/${slides.length}`}</Text>,
          <Flex gap={16} key="actionButtons">
            {currentSlide > 0 && (
              <Button
                key="back"
                type="flat_secondary"
                size="s"
                onClick={handlePrev}
              >
                Назад
              </Button>
            )}
            <Button
              key="next"
              type="primary"
              size="s"
              onClick={() => {
                if (isLastSlide) {
                  onHideOnboarding();
                  hideModal();
                } else handleNext();
              }}
            >
              {isLastSlide ? "Ок" : "Далее"}
            </Button>
          </Flex>,
        ]}
      >
        <Carousel
          arrows
          ref={carouselRef}
          infinite={false}
          dots={false}
          afterChange={onSlideChange}
        >
          {slides.map((slide) => (
            <Flex key={slide.image} vertical className={s.slide}>
              <img
                src={slide.image}
                alt={slide.title}
                className={s.slide__image}
              />
              <Flex vertical gap={10} className={s.slide__textContainer}>
                <Text
                  variant="label-4"
                  className={s.slide__textContainer__text}
                >
                  {slide.title}
                </Text>
                <Text variant="body-5" className={s.slide__textContainer__text}>
                  {slide.label}
                </Text>
              </Flex>
            </Flex>
          ))}
        </Carousel>
        <Flex justify="space-between" className={s.slide__arrowContainer}>
          <Button
            type="flat"
            onClick={handlePrev}
            className={s.slide__arrowContainer__arrow}
            disabled={currentSlide === 0}
          >
            <IoIosArrowBack size={32} />
          </Button>
          <Button
            type="flat"
            onClick={handleNext}
            className={s.slide__arrowContainer__arrow}
            disabled={isLastSlide}
          >
            <IoIosArrowForward size={32} />
          </Button>
        </Flex>
      </Modal>
    </>
  );
};
