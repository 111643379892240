import type { FC } from "react";
import { useLayoutEffect } from "react";
import Sider from "antd/es/layout/Sider";
import { Drawer } from "@femida1/uikit";

import { ReportLayoutAsideMenu } from "./components/ReportLayoutAsideMenu/ReportLayoutAsideMenu";

interface ReportLayoutAsideProps {
  collapsed: boolean;
  isTablet: boolean;
  onCollapse: (collapsed: boolean) => void;
  siderClassName: string;
}

export const ReportLayoutAside: FC<ReportLayoutAsideProps> = ({
  collapsed,
  onCollapse,
  isTablet,
  siderClassName,
}) => {
  useLayoutEffect(() => onCollapse(true), [onCollapse]);

  return isTablet ? (
    <Drawer
      width={230}
      placement="left"
      visible={!collapsed}
      onClose={() => onCollapse(true)}
    >
      <ReportLayoutAsideMenu collapsed={collapsed} onCollapse={onCollapse} />
    </Drawer>
  ) : (
    <Sider
      className={siderClassName}
      defaultCollapsed
      width={280}
      collapsedWidth={0}
      collapsed={collapsed}
      onCollapse={onCollapse}
    >
      <ReportLayoutAsideMenu collapsed={collapsed} onCollapse={onCollapse} />
    </Sider>
  );
};
