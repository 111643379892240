import { Flex, Block } from "@femida1/uikit";
import type { FC } from "react";
import { HistoryList } from "@components/HistoryList/HistoryList";
import { useMainTabSearchParam } from "@pages/Main/hooks/useMainTabSearchParam";
import { useAppFeatures } from "@app/providers";
import { PageHeader } from "@app/layouts/PageHeader/PageHeader";
import { AppRoute } from "@app/routes/constants";
import { OnboardingModalContainer } from "@components/OnboardingModalContainer";
import s from "./History.module.scss";

export const History: FC = () => {
  const [tab] = useMainTabSearchParam();
  const { onboardingEnabled } = useAppFeatures();

  return (
    <Flex vertical gap={32} className={s.wrapper}>
      {onboardingEnabled ? (
        <OnboardingModalContainer onboardingPage="history" />
      ) : null}
      <PageHeader
        title="История проверок"
        mapPathToTitle={{ [AppRoute.History]: "История проверок" }}
        paths={[AppRoute.History]}
      />
      <Block>
        <Flex vertical gap={32}>
          <HistoryList withPagination limit={15} tab={tab} />
        </Flex>
      </Block>
    </Flex>
  );
};
