import { useCallback, useEffect, useState } from "react";
import { Flex, Block, Text } from "@femida1/uikit";
// import { UserSettingsApi } from './components/UserSettingsApi/UserSettingsApi';
import { AppRoute } from "@app/routes/constants";
import {
  useDocumentDownload,
  useDocumentsRetrieveAll,
  useCurrentUserQuery,
} from "@femida1/gui_web_core";
import { PageHeader } from "@app/layouts/PageHeader/PageHeader";
import { downloadFile } from "@utils/files/downloadFile";
import type { Document } from "@femida1/schemas";
import s from "./Documents.module.scss";
import { DocumentCard } from "./components/DocumentCard/DocumentCard";

export const Documents = () => {
  const [documents, setDocuments] = useState<Document[]>([]);
  const { data: user } = useCurrentUserQuery();
  const { mutate: documentsRetrieveAll } = useDocumentsRetrieveAll();
  const { mutate: documentDownload } = useDocumentDownload();
  const group = user?.groups?.[0];

  useEffect(() => {
    documentsRetrieveAll(
      { group_id: group?.group_id ?? 0 },
      {
        onSuccess(data) {
          setDocuments(data);
        },
      },
    );
  }, [documentsRetrieveAll, group?.group_id]);

  const onDownload = useCallback(
    (filename: string) => {
      documentDownload(
        {
          group_id: group?.group_id ?? 0,
          file_name: filename,
        },
        {
          onSuccess(data) {
            downloadFile({ data, filename });
          },
        },
      );
    },
    [documentDownload, group?.group_id],
  );

  return (
    <Flex vertical gap={32} className={s.wrapper}>
      <PageHeader
        title="Мои документы"
        mapPathToTitle={{ [AppRoute.Documents]: "Мои документы" }}
        paths={[AppRoute.Documents]}
      />
      {!documents.length ? (
        <Block>
          <Flex justify="center">
            <Text variant="body-6" color="secondary">
              На данный момент пусто. Здесь будут отображаться документы.
            </Text>
          </Flex>
        </Block>
      ) : (
        documents.map((doc) => (
          <Block key={doc.file_name}>
            <DocumentCard
              onDownload={() => onDownload(doc.file_name)}
              title={doc.file_name}
              size={doc.size}
            />
          </Block>
        ))
      )}
    </Flex>
  );
};
