import { Text, Flex, Download } from "@femida1/uikit";
import { CSVLink } from "react-csv";
import { useCallback, useMemo, useState } from "react";
import type {
  CrossingBorders,
  CrossingBordersSummaryKeys,
} from "@femida1/schemas";
import {
  RetrieveCard,
  type RetrieveCardCommonProps,
} from "@femida1/gui_web_core";
import s from "./CrossingBordersSection.module.scss";
import { SummaryDetail } from "./components/SummaryDetail/SummaryDetail";
import { CrossingBordersTable } from "./components/CrossingBordersTable/CrossingBordersTable";
import type { CrossingBordersFilters } from "./types";
import {
  CrossingBordersDateOrder,
  crossingBorderItemEnumOrder,
  crossingBorderItemTitles,
  crossingBordersSummaryTexts,
  summaryKeys,
} from "./constants";
import { sortFilterData } from "./utils/sortFilterData";

interface CrossingBordersSectionProps extends RetrieveCardCommonProps {
  crossingBorders?: CrossingBorders;
}

export const CrossingBordersSection = ({
  crossingBorders,
  status,
  isSubReport,
}: CrossingBordersSectionProps) => {
  const [filters, setFilters] = useState<CrossingBordersFilters>({});
  const [dateSortOrder, setDateSortOrder] = useState<CrossingBordersDateOrder>(
    CrossingBordersDateOrder.Asc,
  );

  const onToggleDateSortOrder = useCallback(() => {
    setDateSortOrder((prevDateSortOrder) =>
      prevDateSortOrder === CrossingBordersDateOrder.Asc
        ? CrossingBordersDateOrder.Desc
        : CrossingBordersDateOrder.Asc,
    );
  }, []);

  const sortedFilteredData = useMemo(
    () =>
      sortFilterData({
        data: crossingBorders?.result.crossing_border_items,
        dateSortOrder,
        filters,
      }),
    [crossingBorders, filters, dateSortOrder],
  );

  if (!crossingBorders || crossingBorders.found <= 0) {
    return null;
  }

  return (
    <RetrieveCard
      isSubReport={isSubReport}
      expanded={isSubReport}
      dataId={3}
      subtitle={
        <CSVLink
          className={s.container__link}
          data={[
            Object.values(crossingBorderItemTitles),
            ...(sortedFilteredData?.map((value) =>
              crossingBorderItemEnumOrder.map((key) => value[key]),
            ) ?? []),
          ]}
        >
          <Text variant="subheader-2">Экспортировать в CSV</Text>
          <Download />
        </CSVLink>
      }
      title="Пересечение границы"
      status={status}
    >
      <div className={s.container}>
        <Flex vertical gap={16}>
          <Text variant="subheader-4">Краткая сводка</Text>
          <Flex vertical gap={16}>
            {summaryKeys.map((summaryKey: CrossingBordersSummaryKeys) => (
              <div className={s.container__item} key={summaryKey}>
                <Text variant="body-5" color="secondary">
                  {crossingBordersSummaryTexts[summaryKey]}
                </Text>
                <SummaryDetail
                  summaryKey={summaryKey}
                  summary={crossingBorders?.result[summaryKey]}
                />
              </div>
            ))}
          </Flex>
        </Flex>
        {crossingBorders?.result.crossing_border_items && (
          <CrossingBordersTable
            data={sortedFilteredData}
            limit={4}
            filters={filters}
            onFiltersChange={setFilters}
            dateSortOrder={dateSortOrder}
            onToggleDateSortOrder={onToggleDateSortOrder}
          />
        )}
      </div>
    </RetrieveCard>
  );
};
