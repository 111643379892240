import { useCallback, useMemo, useState } from "react";
import { Header } from "@pages/Analytics/components/Header/Header";
import { useParams } from "react-router-dom";
import type { User } from "@femida1/schemas";
import type { Theme } from "@femida1/gui_web_core";
import {
  useGroup,
  useCurrentUserQuery,
  SettingsModalBlock,
  SettingsModal,
  GraphBlock,
  PieBlock,
} from "@femida1/gui_web_core";
import _ from "lodash";
import { StatisticsInterval } from "@femida1/schemas";
import { useAppFeatures, useAppTheme } from "@app/providers";
import { useModalMessage } from "@femida1/uikit";
import { appConfig } from "@app/providers/AppFeatures/appConfig";
import { OnboardingModalContainer } from "@components/OnboardingModalContainer";
import { availableReportTypes } from "@app/providers/AppFeatures/constants/availableReportTypes";
import s from "./Analytics.module.scss";
import type { OverrodeFilters } from "./types";

const mapThemeToYGridColor: Record<Theme, string> = {
  /**
   * --color-base-gray-225
   */
  dark: "rgb(79, 79, 79)",
  /**
   * --color-base-gray-800
   */
  light: "rgb(216, 224, 233)",
};

const headerFiltersDefaultValue: OverrodeFilters = {
  interval: StatisticsInterval.all,
  startDate: undefined,
  endDate: undefined,
};

const headerFiltersDefaultStringifyValue = JSON.stringify({
  startDate: headerFiltersDefaultValue.startDate,
  interval: headerFiltersDefaultValue.interval,
  endDate: headerFiltersDefaultValue.endDate,
});

const visibleBlocks = new Set([SettingsModalBlock.Interval]);

export const Analytics = () => {
  const [headerFilters, setHeaderFilters] = useState<OverrodeFilters>(
    headerFiltersDefaultValue,
  );

  const isHeaderFiltersActive = useMemo(
    () =>
      JSON.stringify({
        startDate: headerFilters.startDate,
        interval: headerFilters.interval,
        endDate: headerFilters.endDate,
      }) !== headerFiltersDefaultStringifyValue,
    [headerFilters],
  );

  const params = useParams<{
    group_id?: string;
    user_id?: string;
  }>();
  const group_id = Number(params.group_id);
  const user_id = Number(params.user_id);

  const { data: group } = useGroup(
    { group_id },
    { enabled: Boolean(group_id) },
  );
  const { data: user } = useCurrentUserQuery();
  const userIds = group?.users?.map((i) => i.user_id);
  const usersById: Record<User["user_id"], User> = _?.keyBy(
    group?.users,
    "user_id",
  );

  const { openModal, closeModal } = useModalMessage();

  const onClearFilters = useCallback(
    () => setHeaderFilters(headerFiltersDefaultValue),
    [],
  );

  const openModalSettings = useCallback(() => {
    openModal({
      children: (
        <SettingsModal
          reportTypes={availableReportTypes}
          visibleBlocks={visibleBlocks}
          defaultValue={headerFilters}
          allUserIds={userIds ? new Set(userIds) : undefined}
          usersById={usersById}
          onClear={onClearFilters}
          onSubmit={setHeaderFilters}
          onClose={closeModal}
        />
      ),
      destroyOnClose: true,
    });
  }, [
    openModal,
    headerFilters,
    userIds,
    usersById,
    onClearFilters,
    closeModal,
  ]);

  const onOpenHeaderSettings = useCallback(() => {
    openModalSettings();
  }, [openModalSettings]);

  const { onboardingEnabled } = useAppFeatures();
  const [theme] = useAppTheme();

  return (
    <div className={s.container}>
      <Header
        isFiltersActive={isHeaderFiltersActive}
        onOpenSettings={onOpenHeaderSettings}
      />
      <GraphBlock
        reportTypes={availableReportTypes}
        group_id={group_id}
        user_id={user_id}
        overrodeFilters={headerFilters}
        primaryColors={appConfig.pages.analytics.graphColors.target}
        secondaryColors={appConfig.pages.analytics.graphColors.all}
        yGridColor={mapThemeToYGridColor[theme]}
      />
      <PieBlock
        reportTypes={availableReportTypes}
        group_id={group_id}
        user={user}
        overrodeFilters={headerFilters}
      />
      {onboardingEnabled ? (
        <OnboardingModalContainer onboardingPage="analytics" />
      ) : null}
    </div>
  );
};
