import type { FC } from "react";
import { useCallback, useState, useRef, useMemo } from "react";
import { List } from "antd";
import { Flex, Input, Pagination, Button } from "@femida1/uikit";
import debounce from "lodash/debounce";
import type { Group } from "@femida1/schemas";
import { IoPersonAdd } from "react-icons/io5";
import { AppRoute } from "@app/routes/constants";
import { generatePath, useNavigate } from "react-router-dom";
import { usePrevious } from "react-use";
import { AccessesListCard } from "./components/AccessesListCard/AccessesListCard";
import s from "./AccessesList.module.scss";
import { AnimatedListItem } from "../AnimatedListItem/AnimatedListItem";

interface AccessesListProps {
  group?: Group;
  withPagination?: boolean;
  limit?: number;
}

export const AccessesList: FC<AccessesListProps> = ({
  group,
  withPagination,
  limit = 5,
}) => {
  const [filterUi, setFilterUi] = useState("");
  const [filter, setFilter] = useState("");
  const prevFilter = useRef<string>("");

  const [page, setPage] = useState(1);
  const offset = (page - 1) * limit;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDebouncedFilterUpdate = useCallback(
    debounce((value: string) => {
      setFilter(value);
      if (value !== prevFilter.current) {
        setPage(1);
      }
      prevFilter.current = value;
    }, 1300),
    [],
  );

  const onFilterUpdate = useCallback(
    (value: string) => {
      setFilterUi(value);
      onDebouncedFilterUpdate(value);
    },
    [onDebouncedFilterUpdate],
  );

  const { users, group_id = 0 } = group || {};

  const filteredUsers = useMemo(() => {
    const filterLc = filter.toLowerCase();

    if (!users || users.length === 0 || !filterLc) return users;

    return users.filter(
      (user) =>
        user.login.toLowerCase().includes(filterLc) ||
        String(user.user_id).toLowerCase().includes(filterLc),
    );
  }, [filter, users]);

  const firstUser = users?.[0];
  const prevFirstUser = usePrevious(firstUser);
  const prevGroupId = usePrevious(group_id);
  const isAnimated =
    prevFirstUser?.user_id !== firstUser?.user_id && prevGroupId === group_id;

  const navigate = useNavigate();
  const onCreate = useCallback(() => {
    navigate(
      generatePath(AppRoute.AccessesAdd, { group_id: String(group_id) }),
    );
  }, [navigate, group_id]);

  const pagination = useMemo(() => {
    if (withPagination && users?.length) {
      return (
        <Flex justify="center">
          <Pagination
            total={users.length}
            pageSize={limit}
            page={page}
            onUpdate={setPage}
          />
        </Flex>
      );
    }

    return null;
  }, [limit, page, users?.length, withPagination]);

  if (!group_id) {
    return null;
  }

  return (
    <Flex vertical gap={32}>
      <Input
        placeholder="Поиск по логину"
        value={filterUi}
        onUpdate={onFilterUpdate}
      />
      <Flex gap={8}>
        <Button iconRight={<IoPersonAdd />} onClick={onCreate} size="s">
          Добавить доступ
        </Button>
      </Flex>
      <Flex vertical gap={16} component="ul" className={s.list}>
        {group && filteredUsers
          ? filteredUsers.slice(offset, offset + limit).map((user) => (
              <AnimatedListItem key={user.user_id} isAnimated={isAnimated}>
                <AccessesListCard
                  key={user.user_id}
                  user={user}
                  groupId={group.group_id}
                />
              </AnimatedListItem>
            ))
          : Array.from({ length: limit }).map((_v, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <List.Item key={i}>
                <AccessesListCard groupId={group_id} isLoading />
              </List.Item>
            ))}
      </Flex>
      {pagination}
    </Flex>
  );
};
