import * as yup from "yup";
import { dropZoneFileListSchema } from "@pages/Main/components/MainTabs/constants/dropZoneFileListSchema";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import type { FC } from "react";
import { useCallback } from "react";
import { validationMessage } from "@utils/constants";
import { SubmitButton } from "@pages/Main/components/MainTabs/components/SubmitButton/SubmitButton";
import { Input, Flex } from "@femida1/uikit";
import type { IReportsTelegramFieldValues } from "../types/IReportsTelegramFieldValues";
import s from "../MainTabTelegram.module.scss";

interface ITelegramLoginFormProps {
  onSubmitInner: (
    requestData: IReportsTelegramFieldValues,
    reset: () => void,
  ) => Promise<void>;
  isSearchPending: boolean;
}

const schema = yup
  .object()
  .shape({
    telegram_login: yup
      .string()
      .min(1, validationMessage.MinLen({ min: 1 }))
      .required(validationMessage.Required),
    sopdFileList: dropZoneFileListSchema,
  })
  .required();

export const TelegramLoginForm: FC<ITelegramLoginFormProps> = ({
  onSubmitInner,
  isSearchPending,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    async (requestData: IReportsTelegramFieldValues) => {
      await onSubmitInner(requestData, reset);
    },
    [onSubmitInner, reset],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex gap={12} align="start" className={s.controllersContainer}>
        <Controller
          name="telegram_login"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              required
              placeholder="Введите логин"
              {...field}
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="sopdFileList"
          control={control}
          render={({ field }) => (
            <SubmitButton
              fileList={field.value}
              onFileListChange={field.onChange}
              disabled={!isValid || isSearchPending}
            />
          )}
        />
      </Flex>
    </form>
  );
};
