import { ReportType } from "@femida1/schemas";
import {
  useReportsEmailLightSearch,
  useReportsEmailSearch,
  useReportsGrnSearch,
  useReportsInnFlSearch,
  useReportsPassportSearch,
  useReportsPhoneLightSearch,
  useReportsPhoneSearch,
  useReportsSnilsSearch,
} from "@femida1/gui_web_core";

export interface ReportItem {
  value: string;
  type: ReportType;
}

export const useSearchByFound = () => {
  const { mutateAsync: reportsPassportSearch } = useReportsPassportSearch();
  const { mutateAsync: reportsPhoneSearch } = useReportsPhoneSearch();
  const { mutateAsync: reportsPhoneLightSearch } = useReportsPhoneLightSearch();
  const { mutateAsync: reportsGrnSearch } = useReportsGrnSearch();
  const { mutateAsync: reportsSnilsSearch } = useReportsSnilsSearch();
  const { mutateAsync: reportsInnFlSearch } = useReportsInnFlSearch();
  const { mutateAsync: reportsEmailSearch } = useReportsEmailSearch();
  const { mutateAsync: reportsEmailLightSearch } = useReportsEmailLightSearch();

  return (personInfoState: Map<string, ReportItem>) => {
    const personInfoStateValues = [...personInfoState.values()];
    personInfoStateValues.forEach((personInfoStateValue) => {
      if (personInfoStateValue.type === ReportType.SEARCH_PASSPORT) {
        reportsPassportSearch({ passport: personInfoStateValue.value });
      }

      if (personInfoStateValue.type === ReportType.SEARCH_PHONE) {
        reportsPhoneSearch({ phone: `7${personInfoStateValue.value}` });
      }

      if (personInfoStateValue.type === ReportType.SEARCH_PHONE_LIGHT) {
        reportsPhoneLightSearch({ phone: `7${personInfoStateValue.value}` });
      }

      if (personInfoStateValue.type === ReportType.SEARCH_GRN) {
        reportsGrnSearch({ grn: personInfoStateValue.value });
      }

      if (personInfoStateValue.type === ReportType.SEARCH_SNILS) {
        reportsSnilsSearch({ snils: personInfoStateValue.value });
      }

      if (personInfoStateValue.type === ReportType.SEARCH_INN_FL) {
        reportsInnFlSearch({ inn: personInfoStateValue.value });
      }

      if (personInfoStateValue.type === ReportType.SEARCH_EMAIL) {
        reportsEmailSearch({ email: personInfoStateValue.value });
      }

      if (personInfoStateValue.type === ReportType.SEARCH_EMAIL_LIGHT) {
        reportsEmailLightSearch({ email: personInfoStateValue.value });
      }
    });
  };
};
