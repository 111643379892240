import { useParams } from "react-router-dom";
import { Skeleton } from "antd";
import { useReportsFullDossierRetrieve } from "@femida1/gui_web_core";
import { RetrieveFullDossier } from "@entities/RetrieveFullDossier/RetrieveFullDossier";
import type { ReportCommonProps } from "@femida1/schemas";

export const SearchFullDossierWidget = ({
  type,
}: Omit<ReportCommonProps, "reportId">) => {
  const { reportId = "" } = useParams();

  const { data: report, isLoading } = useReportsFullDossierRetrieve({
    id: reportId,
  });

  return (
    <Skeleton loading={isLoading}>
      {report && (
        <RetrieveFullDossier reportId={reportId} type={type} report={report} />
      )}
    </Skeleton>
  );
};
