import { EStatus } from "@pages/Employees/types";
import { Alert, Cancel, Loading, Success, Text } from "@femida1/uikit";

export const renderIcon = (status?: string) => {
  if (status === EStatus.Error || status === EStatus.Invalid) return <Alert />;
  if (status === EStatus.No_data) return <Cancel />;
  if (status === EStatus.Completed) return <Success />;
  if (status === EStatus.In_progress) return <Loading />;
  return <Text color="secondary">—</Text>;
};
