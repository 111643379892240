import { validationBounds } from "@utils/constants";
import { isValidDate } from "./isValidDate";
import { joinMiddleNamePatronyms } from "./joinMiddleNamePatronyms";
import { WordType } from "./types";
import { joinPassport } from "./joinPassport";

const isValidAge = (word: string) => {
  const numWord = Number(word);
  return (
    Number.isInteger(numWord) &&
    validationBounds.Age.Min <= numWord &&
    numWord <= validationBounds.Age.Max
  );
};

const lastNamePostfixes = ["ов", "ова", "ина", "ин", "ский", "ская", "ко"];
const isLastName = (word: string) =>
  lastNamePostfixes.some((postfix) => word.endsWith(postfix));
const isPhoneNumber = (word: string) => {
  const numWord = Number(word);
  return (
    Number.isInteger(numWord) && word.length === 11 && word.startsWith("7", 0)
  );
};

interface GuessWordTypesProps {
  pastedWords: string[];
}

export const guessWordTypes = ({
  pastedWords,
}: GuessWordTypesProps): Record<WordType, string[]> => {
  const wordsWithTypeMiddleName = joinMiddleNamePatronyms(
    pastedWords.map((word) => ({ word, type: WordType.Other })),
  );
  const wordsWithType = joinPassport(wordsWithTypeMiddleName);

  const result: Record<WordType, string[]> = {
    [WordType.Age]: [],
    [WordType.Date]: [],
    [WordType.FirstName]: [],
    [WordType.LastName]: [],
    [WordType.MiddleName]: [],
    [WordType.Passport]: [],
    [WordType.Phone]: [],
    [WordType.Other]: [],
  };

  wordsWithType.forEach((wordWithType) => {
    const { word, type } = wordWithType;
    if (type !== WordType.Other) {
      result[type].push(word);
      return;
    }

    const isValidDateWord = isValidDate({ date: word, strict: true });

    if (isValidDateWord) {
      result[WordType.Date].push(word);
      return;
    }
    if (isValidAge(word)) {
      result[WordType.Age].push(word);
      return;
    }
    if (isLastName(word)) {
      result[WordType.LastName].push(word);
      return;
    }
    if (isPhoneNumber(word)) {
      result[WordType.Phone].push(word);
      return;
    }
    result[WordType.Other].push(word);
  });

  return result;
};
