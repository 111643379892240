import { appConfig } from "@app/providers/AppFeatures/appConfig";
import { MenuGroup } from "@app/layouts/MainLayout/components/constants";
import { isMainTab, mapMainTabToReportTypes } from "@pages/Main/constants";

const { items } = appConfig.navMenu;

const checkingItems = items.find(
  (menuItem) => menuItem.key === MenuGroup.Checking,
)?.children;

export const availableReportTypes =
  checkingItems
    ?.flatMap((checkingItem) => {
      if (isMainTab(checkingItem)) return mapMainTabToReportTypes[checkingItem];
      return undefined;
    })
    .filter((checkingReportType) => !!checkingReportType) || [];
