import { Skeleton } from "antd";
import { Flex, Block } from "@femida1/uikit";
import { PageHeader } from "@app/layouts/PageHeader/PageHeader";
import { AppRoute } from "@app/routes/constants";
import { useCurrentUserQuery, ActionList } from "@femida1/gui_web_core";

export const ActionLog = () => {
  const { data: user } = useCurrentUserQuery();

  return (
    <Flex vertical gap={32}>
      <PageHeader
        title="Журнал действий"
        mapPathToTitle={{ [AppRoute.Action]: "Журнал действий" }}
        paths={[AppRoute.Action]}
      />
      <Block>
        <Skeleton loading={!user}>
          {user && <ActionList user={user} />}
        </Skeleton>
      </Block>
    </Flex>
  );
};
