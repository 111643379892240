import { Skeleton } from "antd";
import { Flex, Block } from "@femida1/uikit";
import { AppRoute } from "@app/routes/constants";
import { useParams } from "react-router-dom";
import { useCurrentUserQuery, useGroup } from "@femida1/gui_web_core";
import { AccessesList } from "@components/AccessesList/AccessesList";
import { PageHeader } from "@app/layouts/PageHeader/PageHeader";
import { useAppFeatures } from "@app/providers";
import s from "./Accesses.module.scss";
import { OnboardingModalContainer } from "../../components/OnboardingModalContainer";

export const Accesses = () => {
  const { data: user } = useCurrentUserQuery();
  const { group_id } = useParams();
  const { data: group } = useGroup(
    { group_id: Number(group_id) },
    { enabled: Boolean(group_id) },
  );

  const { onboardingEnabled } = useAppFeatures();

  if (!user) {
    return null;
  }

  return (
    <Flex vertical gap={32} className={s.wrapper}>
      {onboardingEnabled ? (
        <OnboardingModalContainer onboardingPage="accesses" />
      ) : null}
      <PageHeader
        title="Доступы"
        mapPathToTitle={{ [AppRoute.Accesses]: "Доступы" }}
        paths={[AppRoute.Accesses]}
      />
      <Block>
        <Skeleton loading={!group}>
          {group && <AccessesList withPagination limit={10} group={group} />}
        </Skeleton>
      </Block>
    </Flex>
  );
};
