import type { FC } from "react";
import type { CheckboxProps } from "@femida1/uikit";
import { Checkbox } from "@femida1/uikit";

export interface IShowAllCheckboxProps
  extends Omit<CheckboxProps, "children"> {}

export const ShowAllCheckbox: FC<IShowAllCheckboxProps> = ({
  checked,
  onUpdate,
}: IShowAllCheckboxProps) => (
  <Checkbox checked={checked} onUpdate={onUpdate}>
    Показывать все
  </Checkbox>
);
