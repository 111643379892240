import type { FC } from "react";
import { MainLayout } from "@app/layouts/MainLayout/MainLayout";
import { Outlet } from "react-router-dom";

interface RootProps {}

export const Root: FC<RootProps> = () => (
  <MainLayout>
    <Outlet />
  </MainLayout>
);
