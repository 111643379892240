import type { FC } from "react";
import { useCallback } from "react";
import {
  Flex,
  Text,
  Button,
  useModalMessage,
  useNotification,
} from "@femida1/uikit";
import {
  useSessionsSearchQuery,
  useSessionsDelete,
  useSessionsDeleteOther,
} from "@femida1/gui_web_core";
import { CommonModal } from "@components/CommonModal/CommonModal";
import { IoIosWarning } from "react-icons/io";
import { handleBackendErrors } from "@utils/notifications/handleBackendErrors";
import { SessionCard } from "./components/SessionCard/SessionCard";
import s from "./UserSettingsSessions.module.scss";

interface UserSettingsSessionsProps {
  user_id?: number;
}

export const UserSettingsSessions: FC<UserSettingsSessionsProps> = ({
  user_id,
}) => {
  const { data: sessions } = useSessionsSearchQuery({ user_id });
  const { mutateAsync: deleteSessions, isPending: isSessionsDeleting } =
    useSessionsDelete();
  const { mutateAsync: deleteOther, isPending: isOtherSessionsDeleting } =
    useSessionsDeleteOther();
  const { openModal } = useModalMessage();

  const [api] = useNotification();

  const onDeleteConfirm = useCallback(() => {
    if (user_id) {
      deleteSessions({ user_id }).catch((error) => {
        handleBackendErrors({
          api,
          error,
        });
      });
    } else {
      deleteOther({}).catch((error) => {
        handleBackendErrors({
          api,
          error,
        });
      });
    }
  }, [user_id, deleteSessions, api, deleteOther]);

  const onDeleteOther = useCallback(() => {
    openModal({
      children: (
        <CommonModal
          type="normal"
          icon={<IoIosWarning size={120} />}
          iconSize={120}
          iconWrapperClassName={s.confirm_modal__icon}
          buttonsVertical
          buttonsGap={16}
          title="Вы уверены, что хотите завершить сеансы? Действие невозможно будет отменить"
          primaryButtonProps={{
            type: "primary",
            children: "Да, продолжить",
            size: "l",
            onClick: onDeleteConfirm,
          }}
          secondaryButtonProps={{
            type: "flat_secondary",
            children: "Отменить",
            size: "l",
          }}
        />
      ),
      closable: true,
    });
  }, [onDeleteConfirm, openModal]);

  return (
    <Flex vertical gap={32}>
      <Text variant="header-6">Сеансы</Text>
      <Flex vertical gap={16}>
        {sessions
          ?.filter(Boolean)
          .map((session) => <SessionCard key={session.id} session={session} />)}
      </Flex>
      <Flex justify="center">
        <div className={s.closeAllSessionsButtonContainer}>
          <Button
            width="full"
            className={s.closeAllSessionsButton}
            type="flat"
            onClick={onDeleteOther}
            disabled={isSessionsDeleting || isOtherSessionsDeleting}
          >
            <Text variant="body-4" color="accent">
              Завершить все сеансы
            </Text>
          </Button>
        </div>
      </Flex>
    </Flex>
  );
};
