import {
  calculateReportStatus,
  RetrieveScoringView,
} from "@femida1/gui_web_core";
import { RetrieveStatus } from "@entities/RetrieveStatus/RetrieveStatus";
import type {
  ReportCommonProps,
  ReportsScoringRetrieveResponse,
} from "@femida1/schemas";
import { ReportStatus } from "@femida1/schemas";
import { NoteModalFullDossier } from "@components/NoteModal/NoteModalFullDossier";
import { NoteModalMultiQueryFullDossier } from "@components/NoteModal/NoteModalMultiQueryFullDossier";
import { SearchByFound } from "@components/SearchByFound/SearchByFound";
import { useNotification } from "@femida1/uikit";
import { usePdfGenerateHandlers } from "@app/api/pdf/usePdfGenerateHandlers";

export type RetrieveScoringProps = {
  report: ReportsScoringRetrieveResponse;
} & ReportCommonProps;

export const RetrieveScoring = ({
  report,
  type,
  reportId,
}: RetrieveScoringProps) => {
  const [api] = useNotification();

  const { onReportLoad, onReportPrint, isLoadingGeneratePdf } =
    usePdfGenerateHandlers({
      api,
      type,
      reportId,
    });

  return (
    <RetrieveStatus
      status={
        calculateReportStatus({
          sourceStatuses: [report?.scoring_result?.status],
          found: report?.status === ReportStatus.Finished,
        }) || report?.status
      }
    >
      <RetrieveScoringView
        report={report}
        reportId={reportId}
        type={type}
        isLoadingGeneratePdf={isLoadingGeneratePdf}
        onReportLoad={onReportLoad}
        onReportPrint={onReportPrint}
        renderNoteModal={() => (
          <NoteModalFullDossier reportId={reportId} type={type} />
        )}
        renderMultiQueryNoteModal={({ note }) => (
          <NoteModalMultiQueryFullDossier
            reportId={reportId}
            type={type}
            note={note}
          />
        )}
        renderSearchByFound={(props) => <SearchByFound {...props} />}
      />
    </RetrieveStatus>
  );
};
