import type { FC } from "react";
import { Modal } from "antd";
import type { IEmployeeDto } from "@femida1/schemas";
import cn from "classnames";
import { Button, Text, useBooleanState } from "@femida1/uikit";
import s from "./DeleteEmployeeModal.module.scss";

interface IDeleteEmployeeModalProps {
  isRequestLoading: boolean;
  onSuccess: (data: IEmployeeDto["id"]) => void;
  employeeId: IEmployeeDto["id"];
}

export const DeleteEmployeeModal: FC<IDeleteEmployeeModalProps> = ({
  isRequestLoading,
  onSuccess,
  employeeId,
}) => {
  const {
    state: isModalVisible,
    setTrue: showModal,
    setFalse: hideModal,
  } = useBooleanState(false);

  const onSubmitInner = (data: IEmployeeDto["id"]) => {
    onSuccess(data);
    if (!isRequestLoading) hideModal();
  };

  return (
    <>
      <button type="button" onClick={showModal}>
        Удалить
      </button>
      <Modal
        destroyOnClose
        className={s.modal}
        open={isModalVisible}
        onCancel={hideModal}
        footer={[
          <Text
            key="cancel"
            onClick={hideModal}
            className={cn(s.modal_cancel, s.modal_footerButton)}
          >
            Отменить
          </Text>,
          <div key="submit" className={s.modal_footerButton}>
            <Button
              size="s"
              type="primary"
              disabled={isRequestLoading}
              onClick={() => onSubmitInner(employeeId)}
              width="full"
            >
              Да, удалить
            </Button>
          </div>,
        ]}
      >
        <Text variant="subheader-5" className={s.modal_message}>
          Вы уверены, что хотите удалить сотрудника? Это действие необратимо.
        </Text>
      </Modal>
    </>
  );
};
