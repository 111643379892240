import type { FC } from "react";
import { useCallback, useMemo } from "react";
import { Card as AntCard, Skeleton } from "antd";
import {
  Flex,
  Text,
  useModalMessage,
  Toggle,
  Button,
  Dropdown,
  useNotification,
} from "@femida1/uikit";
import { CommonModal } from "@components/CommonModal/CommonModal";
import type { User } from "@femida1/schemas";
import {
  useGroupsRemoveMember,
  useUsersSetSuspendedMutation,
  useCurrentUserQuery,
} from "@femida1/gui_web_core";
import { IoMdMore } from "react-icons/io";
import type { MenuProps } from "antd/lib";
import { UserSettingsSessions } from "@pages/UserSettings/components/UserSettingsSessions/UserSettingsSessions";
import { generatePath, useNavigate } from "react-router-dom";
import { AppRoute } from "@app/routes/constants";
import { handleBackendErrors } from "@utils/notifications/handleBackendErrors";
import s from "./AccessesListCard.module.scss";
import { Permission } from "./components/Permission/Permission";

interface AccessesListCardViewProps {
  user: User;
  groupId: number;
}

export const AccessesListCardView: FC<AccessesListCardViewProps> = ({
  user,
  groupId,
}) => {
  const { login, name, permissions, user_id, suspended } = user;
  const { openModal } = useModalMessage();
  const { mutateAsync: removeMember, isPending: isUserDeleting } =
    useGroupsRemoveMember();
  const { mutateAsync: setMemberSuspend, isPending: isUserSuspendSettings } =
    useUsersSetSuspendedMutation();
  const { data: currentUser } = useCurrentUserQuery();
  const navigate = useNavigate();
  const isCurrentUser = user_id === currentUser?.id;

  const [api] = useNotification();

  const onEdit = useCallback(() => {
    if (!user_id) return;

    navigate(
      generatePath(AppRoute.AccessesEdit, {
        group_id: String(groupId),
        user_id: String(user_id),
      }),
    );
  }, [user_id, groupId, navigate]);

  const onViewSessions = useCallback(() => {
    if (!user) return;

    openModal({
      children: <UserSettingsSessions user_id={user_id} />,
    });
  }, [openModal, user]);

  const onDelete = useCallback(() => {
    openModal({
      children: (
        <CommonModal
          type="normal"
          title={
            <>
              Вы уверены, что хотите удалить пользователя?
              <br />
              Это действие необратимо
            </>
          }
          primaryButtonProps={{
            children: "Да, удалить",
            onClick: async () => {
              if (user_id) {
                await removeMember({ user_id, group_id: groupId });
              }
            },
            disabled: isUserDeleting,
          }}
          secondaryButtonProps={{ children: "Отменить" }}
        />
      ),
    });
  }, [user_id, openModal, isUserDeleting, groupId, removeMember]);

  const onViewStats = useCallback(() => {
    navigate(
      generatePath(AppRoute.Analytics, {
        group_id: String(groupId),
        user_id: String(user.user_id),
      }),
    );
  }, [groupId, user.user_id, navigate]);

  const items: MenuProps["items"] = useMemo(
    () => [
      {
        key: "statistics",
        label: "Смотреть статистику",
        onClick: onViewStats,
      },
      // {
      //   key: "reports",
      //   label: "Скачать запросы",
      //   onClick() {},
      // },
      {
        key: "auth",
        label: "Авторизации",
        onClick: onViewSessions,
      },
      {
        key: "edit",
        label: "Редактировать",
        onClick: onEdit,
      },
      {
        key: "delete",
        label: "Удалить",
        onClick: onDelete,
      },
    ],
    [onDelete, onEdit, onViewSessions, onViewStats],
  );

  const onToggleUser = useCallback(() => {
    setMemberSuspend({ user_id, suspended: !suspended }).catch((error) => {
      handleBackendErrors({
        api,
        error,
      });
    });
  }, [user_id, suspended, setMemberSuspend, api]);

  return (
    <Flex component="section" vertical gap={20}>
      <Flex
        className={s.card__header}
        gap={20}
        align="center"
        justify="space-between"
      >
        <Flex gap={8} align="center" className={s.card__permissions}>
          {permissions?.map((permission) => (
            <Permission key={permission} permission={permission} />
          ))}
        </Flex>
        <Dropdown
          items={items}
          mouseLeaveDelay={0.6}
          placement="bottomRight"
          // eslint-disable-next-line react/no-unstable-nested-components
          body={({ onOpen }) => (
            <Button
              type="flat"
              pin="round"
              icon={<IoMdMore size={24} />}
              className={s.card__header_dropdown}
              onClick={onOpen}
            />
          )}
        />
      </Flex>
      <Flex gap={8} align="center" justify="space-between">
        <Text variant="header-1">{login || name || user_id}</Text>
        <Toggle
          value={!suspended}
          disabled={isUserSuspendSettings || isCurrentUser}
          onChange={onToggleUser}
        />
      </Flex>
      <div />
    </Flex>
  );
};

interface AccessesListCardProps {
  user?: User;
  groupId: number;
  isLoading?: boolean;
}

export const AccessesListCard: FC<AccessesListCardProps> = ({
  user,
  groupId,
  isLoading,
}) => (
  <AntCard className={s.card}>
    <Skeleton loading={isLoading || !user}>
      {user ? <AccessesListCardView groupId={groupId} user={user} /> : null}
    </Skeleton>
  </AntCard>
);
