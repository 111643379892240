import type { FC, PropsWithChildren } from "react";
import { appConfig } from "@app/providers/AppFeatures/appConfig";
import { AppFeaturesContext } from "./AppFeaturesContext";

const { features } = appConfig;

export const AppFeaturesProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <AppFeaturesContext.Provider value={features}>
      {children}
    </AppFeaturesContext.Provider>
  );
};
