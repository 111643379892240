import type { EHistoryListSetting, EHistoryListSubSetting } from "../types";

interface HistoryListSettings {
  [EHistoryListSetting.FILTERS]: {
    [key in EHistoryListSubSetting]?: unknown;
  };
  [EHistoryListSetting.SORTING]: string;
}

export class HistoryListSettingsStorage {
  static get<T>(
    setting: EHistoryListSetting,
    subSetting?: EHistoryListSubSetting,
  ): T | null {
    try {
      const gotSetting = localStorage.getItem(setting);

      if (!gotSetting) return null;

      const parsedSetting: HistoryListSettings[typeof setting] =
        JSON.parse(gotSetting);

      if (
        subSetting &&
        typeof parsedSetting === "object" &&
        parsedSetting !== null
      ) {
        return (JSON.parse(parsedSetting[subSetting] as string) as T) ?? null;
      }

      return parsedSetting as T;
    } catch (e) {
      return null;
    }
  }

  static set<T>(
    setting: EHistoryListSetting,
    value: T,
    subSetting?: EHistoryListSubSetting,
  ): void {
    try {
      if (subSetting) {
        const existingSettings: HistoryListSettings[typeof setting] =
          this.get<HistoryListSettings[typeof setting]>(setting) || {};

        if (typeof existingSettings === "object" && existingSettings !== null) {
          existingSettings[subSetting] = value;
          localStorage.setItem(setting, JSON.stringify(existingSettings));
        }
      } else {
        localStorage.setItem(setting, JSON.stringify(value));
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("Error setting value:", e);
    }
  }
}
