import { Flex, Button, Text } from "@femida1/uikit";
import type { FC, ReactNode } from "react";
import { useMemo } from "react";
import { LuChevronLeft } from "react-icons/lu";
import { Link } from "react-router-dom";
import { AppRoute } from "@app/routes/constants";
import { BreadCrumps } from "@components/BreadCrumps/BreadCrumps";
import s from "./PageHeader.module.scss";

interface PageHeaderProps {
  hasBreadCrumbs?: boolean;
  mapPathToTitle: Record<string, string>;
  onClick?: () => void;
  paths?: string[];
  prev?: string;
  rightElement?: ReactNode;
  title: string;
}

export const PageHeader: FC<PageHeaderProps> = ({
  title,
  mapPathToTitle,
  rightElement,
  paths,
  prev = AppRoute.Lk,
  onClick,
  hasBreadCrumbs = true,
}) => {
  const link = useMemo(
    () => (
      <Link to={prev} className={s.container__link}>
        <Flex gap={4} align="center">
          <Button onClick={onClick} type="flat" pin="round">
            <LuChevronLeft size={24} />
          </Button>
          <Text variant="header-8">{title}</Text>
        </Flex>
      </Link>
    ),
    [onClick, prev, title],
  );

  return (
    <Flex vertical gap={8} className={s.container}>
      {rightElement ? (
        <Flex justify="space-between">
          {link}
          {rightElement}
        </Flex>
      ) : (
        link
      )}
      {hasBreadCrumbs && (
        <BreadCrumps mapPathToTitle={mapPathToTitle} paths={paths} />
      )}
    </Flex>
  );
};
