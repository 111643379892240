import type { FC } from "react";
import { Fragment, useCallback, useMemo, useState } from "react";
import { Expand, Text } from "@femida1/uikit";
import { mapMultiQueryKeyToTitle } from "@femida1/schemas";
import s from "./MultiQueryReportTypes.module.scss";

import { MultiQuerySubReports } from "./MultiQuerySubReports";
import type { IReportWithDataId } from "../../../types";

interface IMultiQueryReportTypeProps {
  collapsed: boolean;
  scrollToBlock: (block?: string, dataId?: string) => void;
}
const multiQueryElementsTitles = Object.values(mapMultiQueryKeyToTitle);

export const MultiQueryReportTypes: FC<IMultiQueryReportTypeProps> = ({
  scrollToBlock,
  collapsed,
}) => {
  const multiQueryReportsTypes = useMemo(() => {
    const elements = document.querySelectorAll(
      "div[data-name='multi-query-block-report']",
    );
    const result: string[] = [];
    elements.forEach((value) => {
      if (multiQueryElementsTitles.includes(value.id)) {
        result.push(value.id);
      }
    });

    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsed]);
  const multiQuerySubReportsByType = useCallback(
    (multiQueryReportType: string) => {
      const selector = CSS.escape(multiQueryReportType);
      if (!selector) return [];

      const subReportsContainer = document.querySelectorAll(
        `div[id=${selector}]`,
      );
      return Array.from(subReportsContainer).flatMap((container) =>
        Array.from(
          container.querySelectorAll('[data-name="block-sub-report"]'),
        ).map((element) => element.id),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collapsed],
  );
  const multiQuerySubReportItems = useCallback(
    (subReport: string): IReportWithDataId[] => {
      const selector = CSS.escape(subReport);
      if (!selector) return [];

      const subReportItemsContainer = document.querySelectorAll(
        `div[id=${selector}]`,
      );
      const subReportItems = Array.from(subReportItemsContainer).flatMap(
        (container) =>
          Array.from(
            container.querySelectorAll(
              '[data-name="multi-query-block-report"]',
            ),
          ),
      );

      return subReportItems.map((subReportItem) => ({
        dataId: subReportItem.getAttribute("data-id") || undefined,
        title: subReportItem.id,
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collapsed],
  );

  const [expandedTabs, setExpandedTabs] = useState({});

  const toggleTab = (type: string) => {
    setExpandedTabs((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  return (
    <>
      {multiQueryReportsTypes?.map((multiQueryReportType) => (
        <Fragment key={multiQueryReportType}>
          <Expand
            className={s.items}
            state={!!expandedTabs[multiQueryReportType]}
            toggle={() => toggleTab(multiQueryReportType)}
          >
            <Text variant="subheader-3">{multiQueryReportType}</Text>
          </Expand>
          {expandedTabs[multiQueryReportType] &&
            multiQuerySubReportsByType(multiQueryReportType).map(
              (subReport, index) => (
                <MultiQuerySubReports
                  title={subReport}
                  subReportItems={multiQuerySubReportItems(subReport)}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${subReport}${index}`}
                  scrollToBlock={scrollToBlock}
                />
              ),
            )}
        </Fragment>
      ))}
    </>
  );
};
