import { appConfig } from "@app/providers/AppFeatures/appConfig";
import { mapNavMenuItems } from "@app/providers/AppFeatures/constants/mapNavMenuItems";
import type { MenuItem } from "@app/layouts/MainLayout/components/types";
import type { ENavMenuItem, MainTab } from "@pages/Main/constants";
import { isMainTab } from "@pages/Main/constants";
import { mapMenuGroupItems } from "@app/providers/AppFeatures/constants/mapMenuGroupItems";
import { mapMainTabItems } from "@app/providers/AppFeatures/constants/mapMainTabItems";
import { isNavMenuItem } from "@app/layouts/MainLayout/components/utils/isNavMenuItem";

interface GetNavItemsProps {
  group_id?: number;
  user_id?: number;
}

export const getNavItems = ({ group_id, user_id }: GetNavItemsProps) => {
  const { items } = appConfig.navMenu;
  const resultMenuItems: MenuItem[] = [];

  items?.forEach((item) => {
    const menuItem = mapMenuGroupItems[item.key];

    if (item.children) {
      const menuSubItems = item.children
        .map((childKey: ENavMenuItem | MainTab) => {
          if (isMainTab(childKey)) {
            return mapMainTabItems[childKey];
          }
          if (isNavMenuItem(childKey)) {
            return mapNavMenuItems[childKey]({ group_id, user_id });
          }
          return null;
        })
        .filter((subItem) => !!subItem);

      if (menuSubItems.length > 0) {
        resultMenuItems.push({
          ...menuItem,
          children: menuSubItems,
          href: undefined,
        });
      }
    } else if (menuItem.href) {
      resultMenuItems.push({ ...menuItem, href: menuItem.href });
    }
  });

  return resultMenuItems || [];
};
