import {
  AddressesCard,
  calculateReportStatus,
  DataCard,
  FoundPersonsCard,
  FullDossier,
  IncomesCard,
  mapReportTypeToTitle,
  OpenedDataCard,
  RelatedPersonsCard,
  ScoringCard,
  ScoringType,
  TagsCard,
} from "@femida1/gui_web_core";
import type {
  EMultiQueryKeys,
  ReportCommonProps,
  ReportsFullRetrieve,
} from "@femida1/schemas";
import {
  FoundStatus,
  isReportsOpenSourcesIoSphere,
  ReportStatus,
} from "@femida1/schemas";
import { RetrieveStatus } from "@entities/RetrieveStatus/RetrieveStatus";
import { useCallback, useEffect, useMemo } from "react";
import cn from "classnames";

import {
  Expand,
  Flex,
  Text,
  useModalMessage,
  useNotification,
} from "@femida1/uikit";
import { ReportHeader } from "@components/ReportHeader/ReportHeader";
// eslint-disable-next-line import/no-cycle
import { MultiQueryReportsByType } from "@entities/MultiQueryReportsByType/MultiQueryReportsByType";
import { useSubReportsVisibility } from "@pages/Root/components/SubReportsVisibilityContextProps/useSubReportsVisibility";
import { NoteModalFullDossier } from "@components/NoteModal/NoteModalFullDossier";
import { NoteModalMultiQueryFullDossier } from "@components/NoteModal/NoteModalMultiQueryFullDossier";
import { SearchByFound } from "@components/SearchByFound/SearchByFound";
import { usePdfGenerateHandlers } from "@app/api/pdf/usePdfGenerateHandlers";
import { RelatedPersonsModal } from "@components/RelatedPersonsModal/RelatedPersonsModal";
import { DownloadCsvButton } from "@components/DownloadCsvButton/DownloadCsvButton";
import { renderFoundPerson } from "@components/FoundPersons/FoundPersons";
import { appConfig } from "@app/providers/AppFeatures/appConfig";
import { CrossingBordersSection } from "./components/CrossingBorders/CrossingBordersSection";
import s from "./SearchFull.module.scss";

export interface SearchFullProps extends ReportCommonProps {
  report: ReportsFullRetrieve;
  isFioSearch?: boolean;
  isSubReport?: boolean;
  isFullDossierReport?: boolean;
}

export const SearchFull = ({
  report,
  type,
  reportId,
  isFioSearch,
  isSubReport,
  isFullDossierReport,
}: SearchFullProps) => {
  const { openModal, closeModal } = useModalMessage();

  const { visibilityState, setVisibilityState } = useSubReportsVisibility();
  const isMultiQueryReport = useMemo(
    () => report.multi_query?.result !== null,
    [report.multi_query?.result],
  );
  const expanded: boolean = useMemo(
    () => !!report.request_describe && visibilityState[report.request_describe],
    [report.request_describe, visibilityState],
  );
  const toggleExpanded = useCallback(() => {
    if (report.request_describe)
      setVisibilityState(report.request_describe, !expanded);
  }, [expanded, report.request_describe, setVisibilityState]);

  useEffect(() => {
    if (report.request_describe)
      setVisibilityState(report.request_describe, !isSubReport);
  }, []);

  const [api] = useNotification();

  const { onReportLoad, onReportPrint, isLoadingGeneratePdf } =
    usePdfGenerateHandlers({
      api,
      type,
      reportId,
    });

  return (
    <RetrieveStatus
      isSubReport={isSubReport}
      status={
        calculateReportStatus({
          sourceStatuses: [
            report?.person?.status,
            report?.sub_reports?.status,
            report?.crossing_borders?.status,
            report?.other_last_names?.status,
            report?.possible_networks?.status,
            report?.photo?.status,
            report.multi_query?.status,
          ],
          person: report?.person?.status,
          found: report?.person?.found === FoundStatus.found,
        }) || report?.status
      }
    >
      {report.multi_query && isMultiQueryReport && (
        <ReportHeader
          multiQueryReport={report.multi_query}
          type={type}
          status={report.multi_query.status}
          reportId={reportId}
          onReportLoad={onReportLoad}
          onReportPrint={onReportPrint}
          isLoadingGeneratePdf={isLoadingGeneratePdf}
          requestDescribe={report?.request_describe}
          note={report.note}
        />
      )}
      <div
        id={isSubReport ? report?.request_describe : undefined}
        data-name={isSubReport ? "block-sub-report" : undefined}
        className={cn(
          s.container,
          isMultiQueryReport && s.containerWithMultiSearch,
        )}
      >
        {report?.person?.status && (
          <FullDossier
            isSubReport={isSubReport}
            renderHeaderDescribe={
              isFullDossierReport
                ? () => `${mapReportTypeToTitle[type]}: `
                : undefined
            }
            phones={report?.person?.person_info?.phones}
            snilses={report?.person?.person_info?.snilses}
            inns={report?.person?.person_info?.inns}
            passports={report?.person?.person_info?.passports}
            citizens={report?.person?.person_info?.citizens}
            otherLastNames={report?.other_last_names}
            emails={report?.person?.person_info?.emails}
            requestDescribe={report?.request_describe}
            persons={report?.person?.person_info?.persons}
            autos={report?.person?.person_info?.autos}
            photo={report?.photo}
            type={type}
            note={report.note}
            status={report?.person?.status}
            onReportLoad={onReportLoad}
            onReportPrint={onReportPrint}
            renderNoteModal={() => (
              <NoteModalFullDossier reportId={reportId} type={type} />
            )}
            renderMultiQueryNoteModal={({ note }) => (
              <NoteModalMultiQueryFullDossier
                reportId={reportId}
                type={type}
                note={note}
              />
            )}
            renderSearchByFound={(props) => <SearchByFound {...props} />}
            isLoadingGeneratePdf={isLoadingGeneratePdf}
            isMultiQueryReport={isMultiQueryReport}
            expanded={expanded}
          />
        )}
        {(!isSubReport || (isSubReport && expanded)) && (
          <>
            {report?.sub_reports?.status &&
              report?.sub_reports?.report_list && (
                <FoundPersonsCard
                  isSubReport={isSubReport}
                  report_list={report?.sub_reports?.report_list}
                  status={report?.sub_reports?.status}
                  reportFioAsGift={appConfig.features.reportFioAsGift}
                  renderFoundPerson={renderFoundPerson}
                />
              )}
            {report?.open_sources && (
              <OpenedDataCard
                isSubReport={isSubReport}
                isFioSearch={isFioSearch}
                openSources={report?.open_sources}
                status={
                  (report?.open_sources &&
                    isReportsOpenSourcesIoSphere(report?.open_sources) &&
                    report?.open_sources?.iosphere?.status) ||
                  ReportStatus.Finished
                }
              />
            )}
            {report?.crossing_borders?.status && (
              <CrossingBordersSection
                isSubReport={isSubReport}
                crossingBorders={report.crossing_borders}
                status={report.crossing_borders?.status}
              />
            )}
            {report?.possible_networks?.status && (
              <RelatedPersonsCard
                isSubReport={isSubReport}
                possibleNetworks={report?.possible_networks?.result}
                frequentNetworks={report?.frequent_networks?.result}
                status={report?.possible_networks?.status}
                subtitle={<DownloadCsvButton reportId={reportId} />}
                onItemShow={(possibleNetworks) =>
                  openModal({
                    children: (
                      <RelatedPersonsModal
                        possibleNetworks={possibleNetworks}
                        onClose={closeModal}
                      />
                    ),
                    maskClosable: true,
                  })
                }
              />
            )}

            {report?.person?.status &&
              report?.person?.person_info?.addresses && (
                <AddressesCard
                  isSubReport={isSubReport}
                  addresses={report?.person?.person_info?.addresses}
                  status={report?.person?.status}
                />
              )}
            {report?.person?.status && report?.person?.person_info?.incomes && (
              <IncomesCard
                isSubReport={isSubReport}
                incomes={report?.person?.person_info?.incomes}
                status={report?.person?.status}
              />
            )}
            {report?.person?.status &&
              report?.person?.person_info?.scoring_result && (
                <ScoringCard
                  isSubReport={isSubReport}
                  title="Результаты скоринга"
                  scoringResult={report?.person?.person_info?.scoring_result}
                  status={report?.person?.status}
                  scoringType={
                    report?.person?.person_info?.scoring_result.over_all === 1
                      ? ScoringType.Good
                      : ScoringType.Bad
                  }
                />
              )}
            {report?.tags?.status && (
              <TagsCard
                isSubReport={isSubReport}
                tags={report?.tags?.result}
                status={report?.tags?.status}
              />
            )}
            {report?.person?.person_info?.data?.map(
              (reportsPersonInfoData, index) => (
                <DataCard
                  dataId={index}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  reportsPersonInfoData={reportsPersonInfoData}
                  status={report?.person?.status}
                  isSubReport={isSubReport}
                />
              ),
            )}
          </>
        )}
        {isSubReport && (
          <Flex justify="center">
            <Expand
              state={expanded}
              toggle={toggleExpanded}
              iconSize={20}
              className={s.container__expand}
            >
              <Text color="inherit">{`${expanded ? "Скрыть" : "Показать"} отчет`}</Text>
            </Expand>
          </Flex>
        )}
      </div>
      {report.multi_query &&
        isMultiQueryReport &&
        Object.entries(report.multi_query.result).map(([key, value]) => {
          const multiQueryRequests = Object.values(value);
          if (!multiQueryRequests.length) return null;

          return (
            <MultiQueryReportsByType
              key={key}
              multiQueryRequests={value}
              multiQueryKey={key as EMultiQueryKeys}
            />
          );
        })}
    </RetrieveStatus>
  );
};
