import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { Flex, Skeleton, Table } from "antd";
import {
  DeleteEmployeeModal,
  EmployeeDataModal,
  EmployeeSummaryModal,
} from "@pages/Employees/components";
import { IoPersonAdd } from "react-icons/io5";
import {
  useEmployeeCreate,
  useEmployeeDelete,
  useEmployeeRefresh,
  useEmployees,
  useEmployeeUpdate,
} from "@femida1/gui_web_core";
import type { IEmployeeDto, IEmployeeRequest } from "@femida1/schemas";
import type { ColumnsType } from "antd/es/table";
import type { MenuProps } from "antd/lib";
import { IoIosHelpCircle, IoMdMore } from "react-icons/io";
import {
  Block,
  Button,
  Dropdown,
  FiltersModal,
  Input,
  Pagination,
  Radio,
  Text,
  Tooltip,
} from "@femida1/uikit";
import { renderIcon } from "@pages/Employees/utils";
import { sortingRules, sortingRulesOrder } from "@pages/Employees/constants";
import { ESortType } from "@pages/Employees/types";
import { MdFilterList, MdOutlineUpdate } from "react-icons/md";
import { useAppFeatures } from "@app/providers";
import dayjs from "dayjs";
import { OnboardingModalContainer } from "../../components/OnboardingModalContainer";
import { AppRoute } from "../../app/routes/constants";
import { PageHeader } from "../../app/layouts/PageHeader/PageHeader";
import s from "./Employees.module.scss";

const limit = 10;

const sortDates = (a?: string, b?: string) => {
  if (!a && !b) {
    return 0;
  }
  if (!a) {
    return 1;
  }
  if (!b) {
    return -1;
  }

  const dateA = dayjs(a, "DD.MM.YYYY");
  const dateB = dayjs(b, "DD.MM.YYYY");

  return dateA.valueOf() - dateB.valueOf();
};

const mapSortTypeToSortFn: Record<
  ESortType,
  (a: IEmployeeDto, b: IEmployeeDto) => number
> = {
  [ESortType.ascend]: (a: IEmployeeDto, b: IEmployeeDto) =>
    a.last_name.localeCompare(b.last_name),
  [ESortType.descend]: (a: IEmployeeDto, b: IEmployeeDto) =>
    b.last_name.localeCompare(a.last_name),
  [ESortType.updateDateAscend]: (a: IEmployeeDto, b: IEmployeeDto) =>
    sortDates(a.updated_at, b.updated_at),
  [ESortType.updateDateDescend]: (a: IEmployeeDto, b: IEmployeeDto) =>
    sortDates(a.updated_at, b.updated_at),
};

/**
 * Page is temporary hidden
 */
export const Employees = () => {
  const [page, setPage] = useState(1);
  const offset = useMemo(() => (page - 1) * limit, [page]);

  const {
    data: employees,
    isPending: waitEmployees,
    refetch: refetchEmployees,
  } = useEmployees({
    limit,
    offset: Number.isInteger(offset) ? offset : undefined,
  });

  const [currentEmployees, setCurrentEmployees] = useState<IEmployeeDto[]>(
    employees || [],
  );

  useLayoutEffect(() => {
    if (employees) {
      setCurrentEmployees(employees);
    }
  }, [employees]);

  const { mutateAsync: createEmployee, isPending: isEmployeeCreating } =
    useEmployeeCreate();
  const { mutateAsync: updateEmployee, isPending: isEmployeeUpdating } =
    useEmployeeUpdate();
  const { mutateAsync: deleteEmployee, isPending: isEmployeeDeleting } =
    useEmployeeDelete();
  const { mutateAsync: refreshEmployee, isPending: isEmployeeRefreshing } =
    useEmployeeRefresh();

  const [searchText, setSearchText] = useState("");
  const [selectedSortingRule, setSelectedSortingRule] = useState<ESortType>(
    ESortType.ascend,
  );
  const [activeSortingRule, setActiveSortingRule] = useState<ESortType>(
    ESortType.ascend,
  );

  const sortedData = useMemo(() => {
    if (!currentEmployees) return [];

    const sortFn = mapSortTypeToSortFn[activeSortingRule];

    return [...currentEmployees].sort(sortFn);
  }, [activeSortingRule, currentEmployees]);

  const filteredData = useMemo(() => {
    if (!searchText) return sortedData;
    return sortedData.filter((employee) =>
      `${employee.last_name} ${employee.first_name} ${employee.middle_name}`
        .toLowerCase()
        .includes(searchText.toLowerCase()),
    );
  }, [sortedData, searchText]);

  const onCreate = useCallback(
    (data: IEmployeeRequest) => {
      createEmployee(data).then(() => refetchEmployees());
    },
    [createEmployee, refetchEmployees],
  );
  const onUpdate = useCallback(
    (data: IEmployeeRequest) => {
      updateEmployee(data).then(() => refetchEmployees());
    },
    [refetchEmployees, updateEmployee],
  );
  const onDelete = useCallback(
    (data: IEmployeeDto["id"]) => {
      deleteEmployee(data).then(() => refetchEmployees());
    },
    [refetchEmployees, deleteEmployee],
  );
  const onRefresh = useCallback(
    (data: IEmployeeDto["id"]) => {
      refreshEmployee(data).then((res) =>
        setCurrentEmployees((prev) =>
          prev.map((employee) => (employee.id === res.id ? res : employee)),
        ),
      );
    },
    [refreshEmployee],
  );

  const columns: ColumnsType<IEmployeeDto> = [
    {
      title: (
        <Text variant="body-1" color="aqua">
          Сотрудник
        </Text>
      ),
      className: s.fioColumn,
      dataIndex: "last_name",
      key: "name",
      fixed: "left",
      render: (_, record) => (
        <Text variant="label-3">
          {record.last_name} {record.first_name} {record.middle_name}
        </Text>
      ),
    },
    {
      title: (
        <Text variant="body-1" color="aqua">
          Паспорт
        </Text>
      ),
      dataIndex: "passport_status",
      key: "passport",
      render: (status: string) => renderIcon(status),
    },
    {
      title: (
        <Text variant="body-1" color="aqua">
          ИНН
        </Text>
      ),
      dataIndex: "inn_status",
      key: "inn",
      render: (status: string) => renderIcon(status),
    },
    {
      title: (
        <Text variant="body-1" color="aqua">
          ФССП
        </Text>
      ),
      dataIndex: "fssp_check_status",
      key: "fssp",
      render: (status: string) => renderIcon(status),
    },
    {
      title: (
        <Text variant="body-1" color="aqua">
          ВУ
        </Text>
      ),
      dataIndex: "driver_license_status",
      key: "driver_license",
      render: (status: string) => renderIcon(status),
    },
    {
      title: (
        <Text variant="body-1" color="aqua">
          Залоги
        </Text>
      ),
      key: "pledge",
      render: () => (
        <Text variant="body-5" color="secondary">
          —
        </Text>
      ),
    },
    {
      title: (
        <Text variant="body-1" color="aqua">
          ЧС
        </Text>
      ),
      dataIndex: "black_list_check_status",
      key: "black_list",
      render: (status: string) => renderIcon(status),
    },
    {
      title: (
        <Text variant="body-1" color="aqua">
          Розыск
        </Text>
      ),
      dataIndex: "wanted_check_status",
      key: "wanted",
      render: (status: string) => renderIcon(status),
    },
    {
      title: (
        <Text variant="body-1" color="aqua">
          Скоринг
        </Text>
      ),
      dataIndex: "scoring_status",
      key: "scoring",
      render: (status: string) => renderIcon(status),
    },
    {
      title: (
        <Text variant="body-1" color="aqua">
          Обновлен
          <Tooltip
            title="Обновление данных происходит каждые 14 дней"
            className={s.updatedDateColumnTooltip}
          >
            <IoIosHelpCircle />
          </Tooltip>
        </Text>
      ),
      dataIndex: "updated_date",
      key: "updated_date",
      width: 228,
      render: (_, record) => (
        <Flex align="center" gap={12}>
          <Text color="secondary">{record.updated_at || renderIcon()}</Text>
          <Button
            size="s"
            type="secondary"
            disabled={isEmployeeRefreshing}
            onClick={() => onRefresh(record.id)}
          >
            <MdOutlineUpdate /> Обновить
          </Button>
        </Flex>
      ),
    },
    {
      title: "",
      key: "action",
      render: (_, record) => {
        const dropdownItems: MenuProps["items"] = [
          {
            key: "summary",
            label: <EmployeeSummaryModal employee={record} />,
          },
          {
            key: "update",
            label: (
              <EmployeeDataModal
                modalTitle="Изменить данные сотрудника"
                buttonTitle={<Text variant="body-2">Редактировать</Text>}
                employee={record}
                onSuccess={onUpdate}
                submitButtonText="Сохранить"
                isRequestLoading={isEmployeeUpdating}
              />
            ),
          },
          {
            key: "delete",
            label: (
              <DeleteEmployeeModal
                isRequestLoading={isEmployeeDeleting}
                onSuccess={onDelete}
                employeeId={record.id}
              />
            ),
          },
        ];

        return (
          <Dropdown
            items={dropdownItems}
            mouseLeaveDelay={0.6}
            placement="bottomRight"
            // eslint-disable-next-line react/no-unstable-nested-components
            body={() => (
              <Text variant="subheader-5" className={s.dropdownOpenButton}>
                <IoMdMore />
              </Text>
            )}
          />
        );
      },
    },
  ];

  const pagination = useMemo(() => {
    if (!currentEmployees || currentEmployees.length === 0) return null;

    const totalItems = currentEmployees.length;

    return (
      <Flex justify="center">
        <Pagination
          total={totalItems}
          pageSize={limit}
          page={page}
          onUpdate={setPage}
        />
      </Flex>
    );
  }, [currentEmployees, page]);

  const { onboardingEnabled } = useAppFeatures();

  return (
    <Flex vertical gap={32} className={s.wrapper}>
      {onboardingEnabled ? (
        <OnboardingModalContainer onboardingPage="employees" />
      ) : null}
      <Flex className={s.header} gap={24}>
        <PageHeader
          title="Мониторинг"
          mapPathToTitle={{ [AppRoute.Employees]: "Мониторинг" }}
          paths={[AppRoute.Employees]}
        />
        <EmployeeDataModal
          modalTitle="Добавить сотрудника"
          buttonTitle={
            <Button size="s" width="full">
              Добавить сотрудника <IoPersonAdd />
            </Button>
          }
          onSuccess={onCreate}
          submitButtonText="Добавить"
          isRequestLoading={isEmployeeCreating}
        />
      </Flex>

      <Block>
        <Flex vertical className={s.searchBar} gap={12}>
          <Input
            placeholder="Поиск по сотруднику"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Flex>
            <FiltersModal
              modalTitle="Сортировка"
              title="Сортировка"
              icon={<MdFilterList />}
              // TODO: false temporarily
              isActive={false}
              onApply={() => setActiveSortingRule(selectedSortingRule)}
              onCancel={() => setSelectedSortingRule(activeSortingRule)}
              onClearFilters={() => setActiveSortingRule(ESortType.ascend)}
            >
              {sortingRulesOrder.map((sortingRule: ESortType) => (
                <Radio
                  key={sortingRule}
                  checked={selectedSortingRule === sortingRule}
                  onUpdate={(checked) => {
                    if (checked) setSelectedSortingRule(sortingRule);
                  }}
                >
                  <Text>{sortingRules[sortingRule]}</Text>
                </Radio>
              ))}
            </FiltersModal>
          </Flex>
        </Flex>

        <Skeleton loading={waitEmployees}>
          <Table
            rowClassName={s.table}
            columns={columns}
            dataSource={filteredData}
            rowKey="id"
            pagination={false}
            scroll={{ x: 1000 }}
          />
        </Skeleton>

        <Flex className={s.pagination}>{pagination}</Flex>
      </Block>
    </Flex>
  );
};
