import { useSearchParams } from "react-router-dom";
import { ReportType } from "@femida1/schemas";
import { Flex } from "@femida1/uikit";
import { SearchFioWidget } from "@widgets/SearchFioWidget/SearchFioWidget";
import { SearchPhoneWidget } from "@widgets/SearchPhoneWidget/SearchPhoneWidget";
import { SearchAddressWidget } from "@widgets/SearchAddressWidget/SearchAddressWidget";
import { SearchSnilsWidget } from "@widgets/SearchSnilsWidget/SearchSnilsWidget";
import { SearchPassportWidget } from "@widgets/SearchPassportWidget/SearchPassportWidget";
import { SearchGrnWidget } from "@widgets/SearchGrnWidget/SearchGrnWidget";
import { SearchVinWidget } from "@widgets/SearchVinWidget/SearchVinWidget";
import { SearchDriverLicenseWidget } from "@widgets/SearchDriverLicenseWidget/SearchDriverLicenseWidget";
import { SearchEmailWidget } from "@widgets/SearchEmailWidget/SearchEmailWidget";
import { SearchOgrnWidget } from "@widgets/SearchOgrnWidget/SearchOgrnWidget";
import { SearchCreditRatingWidget } from "@widgets/SearchCreditRatingWidget/SearchCreditRatingWidget";
import { SearchScoringWidget } from "@widgets/SearchScoringWidget/SearchScoringWidget";
import { SearchInfoWidget } from "@widgets/SearchInfoWidget/SearchInfoWidget";
import { SearchInnFlWidget } from "@widgets/SearchInnFlWidget/SearchInnFlWidget";
import { SearchInnUlWidget } from "@widgets/SearchInnUlWidget/SearchInnUlWidget";
import { SearchPhotoWidget } from "@widgets/SearchPhotoWidget/SearchPhotoWidget";
import { PageHeader } from "@app/layouts/PageHeader/PageHeader";
import { SearchKadNumberWidget } from "@widgets/SearchKadNumberWidget/SearchKadNumberWidget";
import { SearchFullCreditRatingWidget } from "@widgets/SearchFullCreditRatingWidget/SearchFullCreditRatingWidget";
import { SearchTelegramWidget } from "@widgets/SearchTelegramWidget/SearchTelegramWidget";
import { SearchVkWidget } from "@widgets/SearchVkWidget/SearchVkWidget";
import { AppRoute } from "@app/routes/constants";
import { SearchFullDossierWidget } from "@widgets/SearchFullDossierWidget/SearchFullDossierWidget";
import { SearchEmailLightWidget } from "@widgets/SearchEmailLightWidget/SearchEmailLightWidget";
import { SearchPhoneLightWidget } from "@widgets/SearchPhoneLightWidget/SearchPhoneLightWidget";
import { SearchFullDossierLightWidget } from "@widgets/SearchFullDossierLightWidget/SearchFullDossierLightWidget";
import { SearchPhotoLightWidget } from "@widgets/SearchPhotoLightWidget /SearchPhotoLightWidget ";
import s from "./Report.module.scss";

const getReport = (type: string) => {
  switch (type) {
    case ReportType.SEARCH_FULL_DOSSIER: {
      return <SearchFullDossierWidget type={type} />;
    }

    case ReportType.SEARCH_FULL_DOSSIER_LIGHT: {
      return <SearchFullDossierLightWidget type={type} />;
    }

    case ReportType.SEARCH_FIO: {
      return <SearchFioWidget type={type} />;
    }

    case ReportType.SEARCH_PHONE: {
      return <SearchPhoneWidget type={type} />;
    }

    case ReportType.SEARCH_PHONE_LIGHT: {
      return <SearchPhoneLightWidget type={type} />;
    }

    case ReportType.SEARCH_CREDIT_REPORT: {
      return <SearchCreditRatingWidget type={type} />;
    }

    case ReportType.SEARCH_FULL_CREDIT_REPORT: {
      return <SearchFullCreditRatingWidget type={type} />;
    }

    case ReportType.SEARCH_INN_UL: {
      return <SearchInnUlWidget type={type} />;
    }

    case ReportType.SEARCH_INN_FL: {
      return <SearchInnFlWidget type={type} />;
    }

    case ReportType.SEARCH_EMAIL: {
      return <SearchEmailWidget type={type} />;
    }

    case ReportType.SEARCH_EMAIL_LIGHT: {
      return <SearchEmailLightWidget type={type} />;
    }

    case ReportType.SEARCH_VIN: {
      return <SearchVinWidget type={type} />;
    }

    case ReportType.SEARCH_OGRN: {
      return <SearchOgrnWidget type={type} />;
    }

    case ReportType.SEARCH_SNILS: {
      return <SearchSnilsWidget type={type} />;
    }

    case ReportType.SEARCH_PASSPORT: {
      return <SearchPassportWidget type={type} />;
    }

    case ReportType.SEARCH_PHOTO_BY_IMAGE: {
      return <SearchPhotoWidget type={type} />;
    }

    case ReportType.SEARCH_PHOTO_BY_FACE_LIGHT: {
      return <SearchPhotoLightWidget type={type} />;
    }

    case ReportType.SEARCH_ADDRESS: {
      return <SearchAddressWidget type={type} />;
    }

    case ReportType.SEARCH_KAD_NUMBER: {
      return <SearchKadNumberWidget type={type} />;
    }

    case ReportType.SEARCH_GRN: {
      return <SearchGrnWidget type={type} />;
    }

    case ReportType.SEARCH_DRIVER_LICENSE: {
      return <SearchDriverLicenseWidget type={type} />;
    }

    case ReportType.SEARCH_INFO: {
      return <SearchInfoWidget type={type} />;
    }

    case ReportType.SEARCH_SCORING: {
      return <SearchScoringWidget type={type} />;
    }

    case ReportType.SEARCH_TELEGRAM: {
      return <SearchTelegramWidget type={type} />;
    }

    case ReportType.SEARCH_VK: {
      return <SearchVkWidget type={type} />;
    }

    default: {
      return null;
    }
  }
};

export const Report = () => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type") as ReportType;

  return (
    <Flex vertical gap={32} className={s.reportContainer}>
      <PageHeader
        title="Отчёт"
        mapPathToTitle={{
          [AppRoute.Report]: "Отчёт",
        }}
        paths={[AppRoute.Report]}
      />
      {getReport(type)}
    </Flex>
  );
};
