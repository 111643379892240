import dayjs from "dayjs";
import "dayjs/locale/ru";
import customParseFormat from "dayjs/plugin/customParseFormat";

import "@fontsource/montserrat/cyrillic.css";
import "@fontsource/montserrat/latin.css";
import "@fontsource/noto-sans/cyrillic.css";
import "@fontsource/noto-sans/latin.css";

dayjs.extend(customParseFormat);
dayjs.locale("ru");

/**
 * For debug
 */
window.dayjs = dayjs;
