import type { MainTabProps } from "@pages/Main/components/MainTabs/types";
import type { FC } from "react";
import { useCallback, useState } from "react";
import { Tabs } from "antd";
import { Text } from "@femida1/uikit";
import cn from "classnames";
import { useReportsTelegramSearch } from "@femida1/gui_web_core";
import { TelegramPhoneForm } from "./components/TelegramPhoneForm";
import { TelegramLoginForm } from "./components/TelegramLoginForm";
import { TelegramIdForm } from "./components/TelegramIdForm";
import s from "./MainTabTelegram.module.scss";
import type { IReportsTelegramFieldValues } from "./types/IReportsTelegramFieldValues";

type MainTabTelegramProps = MainTabProps;

export const MainTabTelegram: FC<MainTabTelegramProps> = ({ onSubmit }) => {
  const [activeInput, setActiveInput] = useState("phone");
  const { mutateAsync: search, isPending: isSearchPending } =
    useReportsTelegramSearch();

  const onSubmitInner = useCallback(
    (requestData: IReportsTelegramFieldValues, reset: () => void) =>
      onSubmit({ requestData, search, withSopd: true, onSuccess: reset }),
    [search, onSubmit],
  );

  const telegramReportInputTabs = [
    {
      key: "phone",
      label: (
        <Text
          variant="subheader-4"
          color="inherit"
          className={cn(
            s.tabs__tab,
            activeInput === "phone" && s.tabs__tab_active,
          )}
        >
          По номеру телефона
        </Text>
      ),
      children: (
        <TelegramPhoneForm
          onSubmitInner={onSubmitInner}
          isSearchPending={isSearchPending}
        />
      ),
    },
    {
      key: "telegram_login",
      label: (
        <Text
          variant="subheader-4"
          color="inherit"
          className={cn(
            s.tabs__tab,
            activeInput === "telegram_login" && s.tabs__tab_active,
          )}
        >
          По логину
        </Text>
      ),
      children: (
        <TelegramLoginForm
          onSubmitInner={onSubmitInner}
          isSearchPending={isSearchPending}
        />
      ),
    },
    {
      key: "telegram_id",
      label: (
        <Text
          variant="subheader-4"
          color="inherit"
          className={cn(
            s.tabs__tab,
            activeInput === "telegram_id" && s.tabs__tab_active,
          )}
        >
          По ID
        </Text>
      ),
      children: (
        <TelegramIdForm
          onSubmitInner={onSubmitInner}
          isSearchPending={isSearchPending}
        />
      ),
    },
  ];

  return (
    <Tabs
      defaultActiveKey="1"
      items={telegramReportInputTabs}
      className={s.tabs}
      onChange={(activeKey) => setActiveInput(activeKey)}
    />
  );
};
