import type { SetStateAction } from "react";

export const handleCheckboxCheck = <T>(
  checked: boolean,
  value: T,
  setSelectedCheckbox: (value: SetStateAction<T[]>) => void,
  setIsShowAllCheckboxChecked: (value: boolean) => void,
): void => {
  setSelectedCheckbox((prev) => {
    const updatedCheckboxes = checked
      ? [...prev, value]
      : prev.filter((rule) => rule !== value);

    setIsShowAllCheckboxChecked(updatedCheckboxes.length === 0);
    return updatedCheckboxes;
  });
};
