import type { ClipboardEventHandler, FC } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Flex,
  Input,
  useSessionStorage,
  useNotification,
} from "@femida1/uikit";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Alert from "antd/lib/alert";
import {
  validationBounds,
  validationMessage,
  validationRegex,
} from "@utils/constants";
import dayjs from "dayjs";
import { correctLettersSpacesHyphenDashRegexCallback } from "@utils/validation";
import { SOPDModal } from "@pages/Main/components/MainTabs/components/SOPDModal/SOPDModal";
import type {
  ReportsFioMultiParseResponse,
  ReportsFioSearchRequest,
  PossibleNetworkResults,
} from "@femida1/schemas";
import {
  useReportsFioMultiParse,
  useReportsFioMultiSearch,
  useReportsFioSearch,
} from "@femida1/gui_web_core";
import { handleBackendErrors } from "@utils/notifications/handleBackendErrors";
import { transformEmptyString } from "@utils/transformers/transformEmptyString";
import { useAppFeatures } from "@app/providers";
import { mainTabFioFormKey } from "@pages/Main/components/MainTabs/constants/mainTabFioFormKey";
import { dropZoneFileListSchema } from "@pages/Main/components/MainTabs/constants/dropZoneFileListSchema";
import { SubmitButton } from "../../components/SubmitButton/SubmitButton";
import s from "./MainTabFio.module.scss";
import type { MainTabFieldValues, MainTabProps } from "../../types";
import { MultiFioModal } from "./components/MultiFioModal/MultiFioModal";
import { POSSIBLE_DATE_FORMATS } from "../../utils/isValidDate";
import { guessWordTypes } from "../../utils/guessWordTypes";
import { WordType } from "../../utils/types";
import { getOtherWords } from "../../utils/getOtherWords";

interface ReportsFioFieldValues
  extends ReportsFioSearchRequest,
    MainTabFieldValues {}

const schema = yup
  .object<ReportsFioFieldValues>()
  .shape({
    last_name: yup
      .string()
      .test(
        "test-symbols",
        validationMessage.WrongLastName,
        correctLettersSpacesHyphenDashRegexCallback,
      ),
    first_name: yup
      .string()
      .test(
        "test-symbols",
        validationMessage.WrongFirstName,
        correctLettersSpacesHyphenDashRegexCallback,
      ),
    middle_name: yup
      .string()
      .test(
        "test-symbols",
        validationMessage.WrongMiddleName,
        correctLettersSpacesHyphenDashRegexCallback,
      ),
    birth_day: yup
      .number()
      .transform(transformEmptyString)
      .integer(validationMessage.BirthDay)
      .min(validationBounds.Day.Min, validationMessage.BirthDay)
      .max(validationBounds.Day.Max, validationMessage.BirthDay),
    birth_month: yup
      .number()
      .transform(transformEmptyString)
      .integer(validationMessage.BirthMonth)
      .min(validationBounds.Month.Min, validationMessage.BirthMonth)
      .max(validationBounds.Month.Max, validationMessage.BirthMonth),
    birth_year: yup
      .number()
      .transform(transformEmptyString)
      .integer(validationMessage.BirthYear)
      .min(validationBounds.Year.Min, validationMessage.BirthYear)
      .max(validationBounds.Year.Max, validationMessage.BirthYear),
    age_from: yup
      .number()
      .transform(transformEmptyString)
      .integer(validationMessage.Age)
      .min(validationBounds.Age.Min, validationMessage.Age)
      .max(validationBounds.Age.Max, validationMessage.Age),
    age_to: yup
      .number()
      .transform(transformEmptyString)
      .integer(validationMessage.Age)
      .min(validationBounds.Age.Min, validationMessage.Age)
      .max(validationBounds.Age.Max, validationMessage.Age),
    sopdFileList: dropZoneFileListSchema,
  })
  .required()
  .test(
    "age_from-less-than-age_to",
    '"Возраст от" должен быть не больше, чем "Возраст до"',
    ({ age_from, age_to }) => {
      if (typeof age_from !== "number" || typeof age_to !== "number") {
        return true;
      }
      return age_from <= age_to;
    },
  )
  .test(
    "at-least-one-required-field",
    'Должно быть заполнено ОДНО из полей "Фамилия" или "Имя" или "Отчество"',
    (value) => {
      const { last_name, first_name, middle_name } = value;
      return [last_name, first_name, middle_name].filter(Boolean).length >= 1;
    },
  )
  .test(
    "at-least-three-required-fields",
    "Должно быть заполнено хотя бы три поля",
    (value) => Object.values(value).filter(Boolean).length >= 3,
  );

type MainTabFioProps = MainTabProps;

export const MainTabFio: FC<MainTabFioProps> = ({
  onSubmit,
  enableMultiQuery,
}) => {
  const [possibleNetwork, setPossibleNetwork] =
    useSessionStorage<PossibleNetworkResults | null>(mainTabFioFormKey, null);
  const [defaultBirthDay, defaultBirthMonth, defaultBirthYear] =
    possibleNetwork?.birth_date?.split(".")?.map(Number) || [];
  const [multiFioRowList, setMultiFioRowList] =
    useState<ReportsFioMultiParseResponse>([]);

  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
    reset,
    setValue,
    getValues,
    trigger,
  } = useForm<ReportsFioFieldValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      age_from: undefined,
      age_to: undefined,
      birth_day: defaultBirthDay,
      birth_month: defaultBirthMonth,
      birth_year: defaultBirthYear,
      first_name: possibleNetwork?.first_name || "",
      last_name: possibleNetwork?.last_name || "",
      middle_name: possibleNetwork?.middle_name || "",
    },
  });
  const isSubmitButtonValid = useMemo(
    () =>
      enableMultiQuery
        ? isValid &&
          Boolean(
            getValues("first_name") &&
              getValues("last_name") &&
              getValues("middle_name"),
          )
        : isValid,
    [enableMultiQuery, getValues, isValid],
  );

  useEffect(
    () => () => {
      setPossibleNetwork(null);
    },
    [setPossibleNetwork],
  );

  const { mutateAsync: search, isPending: isSearchPending } =
    useReportsFioSearch();
  const { mutateAsync: validateMultiFioFile, isPending: isValidatePending } =
    useReportsFioMultiParse();
  const { mutateAsync: sendFios, isPending: isSendFiosPending } =
    useReportsFioMultiSearch();

  const [api] = useNotification();

  const onSubmitInner = useCallback(
    (requestData: ReportsFioFieldValues) =>
      onSubmit({ requestData, search, withSopd: true, onSuccess: reset }),
    [search, onSubmit, reset],
  );

  const pasteDate = useCallback(
    (dateWord: string): boolean => {
      const date = dayjs(dateWord, POSSIBLE_DATE_FORMATS, true);
      if (date.isValid()) {
        const year = date.year();
        setValue("birth_year", year);

        const month = date.month();
        setValue("birth_month", month + 1);

        const day = date.date();
        setValue("birth_day", day);

        return true;
      }

      return false;
    },
    [setValue],
  );

  const onDayPaste = useCallback<ClipboardEventHandler<HTMLInputElement>>(
    async (e) => {
      const pastedDate = e.clipboardData.getData("text");
      if (pasteDate(pastedDate)) {
        e.preventDefault();
      }

      await trigger().catch(() => {});
    },
    [pasteDate, trigger],
  );

  const onLastNamePaste = useCallback<ClipboardEventHandler<HTMLInputElement>>(
    async (e) => {
      const pastedText = e.clipboardData.getData("text");
      const pastedWords = pastedText.split(" ");
      if (!pastedWords.length) return;

      e.preventDefault();

      const wordsByType = guessWordTypes({ pastedWords });

      const dateWord = wordsByType[WordType.Date].shift();
      if (dateWord) {
        pasteDate(dateWord);
      }

      const probableOrderOfPastedWords: (keyof ReportsFioFieldValues)[] = [];

      const lastName = wordsByType[WordType.LastName].shift();
      if (lastName) {
        setValue("last_name", lastName);
      } else {
        probableOrderOfPastedWords.push("last_name");
      }
      const firstName = wordsByType[WordType.FirstName].shift();
      if (firstName) {
        setValue("first_name", firstName);
      } else {
        probableOrderOfPastedWords.push("first_name");
      }
      const middleName = wordsByType[WordType.MiddleName].shift();
      if (middleName) {
        setValue("middle_name", middleName);
      } else {
        probableOrderOfPastedWords.push("middle_name");
      }

      const ages = wordsByType[WordType.Age];
      if (ages.length >= 2) {
        let isFound = false;
        for (let i = 1; i < ages.length; i += 1) {
          const ageFrom = +ages[i - 1];
          const ageTo = +ages[i];
          if (ageFrom <= ageTo) {
            setValue("age_from", ageFrom);
            setValue("age_to", ageTo);
            ages.splice(i - 1, 2);
            isFound = true;
            break;
          }
        }
        if (!isFound) {
          const age = +ages.pop()!;
          setValue("age_from", age);
          setValue("age_to", age);
        }
      } else if (ages.length === 1) {
        const age = +ages.pop()!;
        setValue("age_from", age);
        setValue("age_to", age);
      }

      const otherWords = getOtherWords(wordsByType);
      const maxLen = probableOrderOfPastedWords.length;
      if (otherWords.length > maxLen) {
        const restPastedWords = otherWords.slice(maxLen - 1);
        otherWords[maxLen - 1] = restPastedWords.join(" ");
        otherWords.length = maxLen;
      }

      otherWords.forEach((pastedWord, index) => {
        setValue(probableOrderOfPastedWords[index], pastedWord);
      });

      await trigger().catch(() => {});
    },
    [setValue, pasteDate, trigger],
  );

  const onValidateMultiFioFile = useCallback(
    async (file: File) => {
      if (!file) return;

      const formData = new FormData();
      formData.append("file", file);
      await validateMultiFioFile(formData)
        .then((res) => {
          const updatedList = res.map((item, index) => ({
            ...item,
            id: index,
          }));
          setMultiFioRowList(updatedList);
        })
        .catch((error) => {
          handleBackendErrors({
            api,
            error,
          });
        });
    },
    [api, validateMultiFioFile],
  );
  const onSendFios = useCallback(
    async (validRowList: ReportsFioMultiParseResponse) => {
      const rows = validRowList.map((row) => row.result);
      await sendFios(rows);
    },
    [sendFios],
  );

  const { tabFioSopdEnabled } = useAppFeatures();

  return (
    <form className={s.form} onSubmit={handleSubmit(onSubmitInner)}>
      <Flex vertical gap={12}>
        <div className={s.inputsContainer}>
          <Controller
            name="last_name"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                className={s.input}
                placeholder="Фамилия"
                onPaste={onLastNamePaste}
                {...field}
                value={field.value?.replace(
                  validationRegex.lettersSpacesHyphenDashExcept,
                  "",
                )}
                onChange={(e) => {
                  e.target.value = e.target.value.replace(
                    validationRegex.lettersSpacesHyphenDashExcept,
                    "",
                  );
                  field.onChange(e);
                }}
                validate={fieldState}
              />
            )}
          />
          <Controller
            name="first_name"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                className={s.input}
                placeholder="Имя"
                {...field}
                value={field.value?.replace(
                  validationRegex.lettersSpacesHyphenDashExcept,
                  "",
                )}
                onChange={(e) => {
                  e.target.value = e.target.value.replace(
                    validationRegex.lettersSpacesHyphenDashExcept,
                    "",
                  );
                  field.onChange(e);
                }}
                validate={fieldState}
              />
            )}
          />
          <Controller
            name="middle_name"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                className={s.input}
                placeholder="Отчество"
                {...field}
                value={field.value?.replace(
                  validationRegex.lettersSpacesHyphenDashExcept,
                  "",
                )}
                onChange={(e) => {
                  e.target.value = e.target.value.replace(
                    validationRegex.lettersSpacesHyphenDashExcept,
                    "",
                  );
                  field.onChange(e);
                }}
                validate={fieldState}
              />
            )}
          />
          <Controller
            name="birth_day"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                className={s.input}
                tabIndex={0}
                type="number"
                min={validationBounds.Day.Min}
                max={validationBounds.Day.Max}
                step="1"
                placeholder="День"
                onPaste={onDayPaste}
                {...field}
                validate={fieldState}
              />
            )}
          />
          <Controller
            name="birth_month"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                className={s.input}
                tabIndex={0}
                type="number"
                min={validationBounds.Month.Min}
                max={validationBounds.Month.Max}
                step="1"
                placeholder="Месяц"
                {...field}
                validate={fieldState}
              />
            )}
          />
          <Controller
            name="birth_year"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                className={s.input}
                tabIndex={0}
                type="number"
                min={validationBounds.Year.Min}
                max={validationBounds.Year.Max}
                step="1"
                placeholder="Год"
                {...field}
                validate={fieldState}
              />
            )}
          />
          <Controller
            name="age_from"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                className={s.input}
                type="number"
                min={validationBounds.Age.Min}
                max={validationBounds.Age.Max}
                step="1"
                placeholder="Возраст от"
                {...field}
                validate={fieldState}
              />
            )}
          />
          <Controller
            name="age_to"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                className={s.input}
                type="number"
                min={validationBounds.Age.Min}
                max={validationBounds.Age.Max}
                step="1"
                placeholder="Возраст до"
                {...field}
                validate={fieldState}
              />
            )}
          />
          <SubmitButton disabled={!isSubmitButtonValid || isSearchPending} />
        </div>
        {Object.entries(errors)
          .filter(([key]) => !key)
          .map(([, value], index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Alert key={index} type="error" message={value.message} />
          ))}
        <MultiFioModal
          onSendFios={onSendFios}
          onValidateMultiFioFile={onValidateMultiFioFile}
          multiFioRowList={multiFioRowList}
          setMultiFioRowList={setMultiFioRowList}
          isValidatePending={isValidatePending}
          isSendFiosPending={isSendFiosPending}
        />
        {tabFioSopdEnabled ? (
          <div className={s.inputsContainer}>
            <Controller
              name="sopdFileList"
              control={control}
              render={({ field }) => (
                <SOPDModal
                  fileList={field.value || []}
                  onFileListChange={field.onChange}
                />
              )}
            />
          </div>
        ) : null}
      </Flex>
    </form>
  );
};
