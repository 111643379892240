import type { FC } from "react";
import { Flex, Text } from "@femida1/uikit";
import { formatPrice } from "@utils/currency";
import s from "./TariffBlock.module.scss";

interface ITariffBlockProps {
  title: string;
  label: string;
  requestPrice: number;
  tariffPrice: number;
}

export const TariffBlock: FC<ITariffBlockProps> = ({
  title,
  label,
  requestPrice,
  tariffPrice,
}) => (
  <div className={s.tariffBlock}>
    <Flex vertical gap={4}>
      <Text variant="subheader-5">{title}</Text>
      <Text variant="subheader-1">{label}</Text>
    </Flex>
    <Flex gap={4} vertical>
      <Flex className={s.tariffBlock_requestPriceBlock}>
        <Text variant="body-2" color="secondary">
          Стоимость запроса
        </Text>
        <Text variant="label-3">
          {formatPrice({ amount: requestPrice, currency: "RUB" })}
        </Text>
      </Flex>
      <Flex className={s.tariffBlock_requestPriceBlock}>
        <Text variant="body-2" color="secondary">
          Стоимость тарифа
        </Text>
        <Text variant="label-3">
          {formatPrice({ amount: tariffPrice, currency: "RUB" })}
        </Text>
      </Flex>
    </Flex>
  </div>
);
