import type { ReportCommonProps } from "@femida1/schemas";
import { useParams } from "react-router-dom";
import { useReportsTelegramRetrieve } from "@femida1/gui_web_core";
import { Skeleton } from "antd";
import { RetrieveTelegram } from "@entities/RetrieveTelegram/RetrieveTelegram";

export const SearchTelegramWidget = ({
  type,
}: Omit<ReportCommonProps, "reportId">) => {
  const { reportId = "" } = useParams();

  const { data: telegramReport, isLoading } = useReportsTelegramRetrieve({
    id: reportId,
  });

  return (
    <Skeleton loading={isLoading}>
      {telegramReport && (
        <RetrieveTelegram
          report={telegramReport}
          type={type}
          reportId={reportId}
        />
      )}
    </Skeleton>
  );
};
