import type { FC } from "react";
import React, { useState, useCallback, useMemo, useLayoutEffect } from "react";
import { Modal } from "antd";
import {
  Flex,
  Button,
  Text,
  DropZone,
  Pagination,
  useBooleanState,
  Close,
} from "@femida1/uikit";
import type { DropZoneFile } from "@femida1/uikit";
import { MdDeleteForever, MdPortrait, MdTextSnippet } from "react-icons/md";
import type { ReportsFioMultiParseResponse } from "@femida1/schemas";
import styles from "./MultiFioModal.module.scss";
import { MultiFioRow } from "./components/MultiFioRow/MultiFioRow";

const limit = 10;

interface MultiFioModalProps {
  onSendFios: (validRowList: ReportsFioMultiParseResponse) => Promise<void>;
  onValidateMultiFioFile: (file: File) => Promise<void>;
  multiFioRowList: ReportsFioMultiParseResponse;
  setMultiFioRowList: (value: ReportsFioMultiParseResponse) => void;
  isValidatePending: boolean;
  isSendFiosPending: boolean;
}

export const MultiFioModal: FC<MultiFioModalProps> = ({
  onSendFios,
  onValidateMultiFioFile,
  isValidatePending,
  isSendFiosPending,
  multiFioRowList,
  setMultiFioRowList,
}) => {
  const [page, setPage] = useState(1);
  const [fileList, setFileList] = useState<DropZoneFile[]>([]);
  const file = fileList?.[0]?.originFileObj;

  const {
    state: isModalVisible,
    setTrue: showModal,
    setFalse: hideModal,
  } = useBooleanState(false);

  const {
    state: isFileValidate,
    setTrue: validateFile,
    setFalse: resetFileValidation,
  } = useBooleanState(false);

  const {
    state: isShowValidRowList,
    setTrue: showValidRowsList,
    setFalse: hideValidRowsList,
  } = useBooleanState(false);

  const { validRowList, invalidRowList } = useMemo(
    () => ({
      validRowList: multiFioRowList.filter((row) => !row.error_message),
      invalidRowList: multiFioRowList.filter((row) => row.error_message),
    }),
    [multiFioRowList],
  );

  useLayoutEffect(() => {
    if (invalidRowList.length === 0) showValidRowsList();
  }, [invalidRowList, showValidRowsList]);

  const offset = (page - 1) * limit;

  const submitButtonText = useMemo(() => {
    if (isFileValidate) return isShowValidRowList ? "Сохранить" : "Продолжить";
    return "Применить";
  }, [isFileValidate, isShowValidRowList]);

  const handleOk = useCallback(() => {
    if (!isFileValidate) {
      setMultiFioRowList([]);
      onValidateMultiFioFile(file).then(() => validateFile());
    } else if (!isShowValidRowList) {
      showValidRowsList();
      setPage(1);
    } else {
      onSendFios(validRowList).then(() => hideModal());
    }
  }, [
    file,
    hideModal,
    isFileValidate,
    isShowValidRowList,
    onSendFios,
    onValidateMultiFioFile,
    setMultiFioRowList,
    showValidRowsList,
    validRowList,
    validateFile,
  ]);

  useLayoutEffect(() => {
    if (!isModalVisible) {
      resetFileValidation();
      hideValidRowsList();
      setPage(1);
      setFileList([]);
      setMultiFioRowList([]);
    }
  }, [
    isModalVisible,
    resetFileValidation,
    hideValidRowsList,
    setMultiFioRowList,
  ]);

  const handleClear = () => {
    if (isFileValidate) {
      resetFileValidation();
      hideValidRowsList();
    } else {
      setFileList([]);
    }
  };
  const allRows = useMemo(
    () => (isShowValidRowList ? validRowList : invalidRowList),
    [invalidRowList, isShowValidRowList, validRowList],
  );

  const currentRows = useMemo(
    () => allRows.slice(offset, offset + limit),
    [allRows, offset],
  );

  const handleDeleteRow = useCallback(
    (id: number) => {
      const filteredRowList = multiFioRowList.filter((row) => row.id !== id);
      setMultiFioRowList(filteredRowList);
    },
    [multiFioRowList, setMultiFioRowList],
  );

  const rowList = (
    <Flex vertical gap={12}>
      {isShowValidRowList ? (
        <Text variant="subheader-3">Данные для проверки</Text>
      ) : (
        <>
          <Text variant="subheader-3">Ошибка заполнения данных</Text>
          <Text variant="body-5" color="secondary">
            Данные заполнены неправильно, в случае продолжения они не будут
            включены в список для проверки. Исправьте некорректные строки в
            файле или продолжите проверку без них.
          </Text>
        </>
      )}
      <Text variant="body-5" color="secondary">
        Найдено строк: {allRows.length}
      </Text>
      <Flex vertical gap={12}>
        {currentRows.map((row) => (
          <MultiFioRow key={row.id} row={row} onRowDelete={handleDeleteRow} />
        ))}
        <Flex justify="center">
          <Pagination
            total={allRows.length}
            pageSize={limit}
            page={page}
            onUpdate={setPage}
          />
        </Flex>
      </Flex>
    </Flex>
  );

  return (
    <>
      <Button
        type="secondary"
        onClick={showModal}
        iconRight={<MdTextSnippet size={20} />}
      >
        Массовый запрос из файла
      </Button>
      <Modal
        title={<Text variant="subheader-5">Массовый запрос из файла</Text>}
        open={isModalVisible}
        destroyOnClose
        wrapClassName={styles.modalWrap}
        closeIcon={<Close />}
        onOk={handleOk}
        onCancel={hideModal}
        footer={[
          <Button key="clear" type="flat" size="s" onClick={handleClear}>
            <Text variant="subheader-2" color="secondary">
              {isFileValidate ? "Назад" : "Очистить"}
            </Text>
          </Button>,
          <Button
            key="submit"
            type="primary"
            size="s"
            onClick={handleOk}
            disabled={isSendFiosPending || isValidatePending || !file}
          >
            {submitButtonText}
          </Button>,
        ]}
      >
        {isFileValidate ? (
          rowList
        ) : (
          <Flex vertical gap={12}>
            <Text color="secondary">
              Загрузите файл в формате CSV для массовой отправки запросов по ФИО
              (стандартный), доступно до 1000 запросов одновременно. CSV
              выгружается из EXCEL, одно поле равняется одной колонке в таблице,
              6 полей по очереди как в примере.
            </Text>
            <Text variant="subheader-3">Пример содержимого</Text>
            <Flex vertical gap={4} className={styles.example}>
              <Text>Фамилия;Имя;Отчество;01;01;1990</Text>
              <Text>LastName;FirstName;MiddleName;01;01;1990</Text>
              <Text>Иванов;Иван;Иванович;01;01;1990</Text>
            </Flex>
            <DropZone
              onChange={(value) => setFileList([value[value.length - 1]])}
              accept=".csv"
              multiple={false}
              selectFileButtonTitle={file ? "Выбрать другой" : "Выбрать файл"}
            >
              <Flex align="center" vertical gap={8} className={styles.dropZone}>
                <Flex align="center" vertical gap={16}>
                  {file ? (
                    <>
                      <MdTextSnippet size={48} className={styles.icon} />
                      <Flex align="center" gap={10}>
                        <Text variant="body-6" color="aqua">
                          {file.name}
                        </Text>
                        <MdDeleteForever
                          size={24}
                          cursor="pointer"
                          onClick={() => setFileList([])}
                        />
                      </Flex>
                    </>
                  ) : (
                    <>
                      <MdPortrait size={48} className={styles.icon} />
                      <Text color="aqua" variant="body-6">
                        Загрузите CSV файл
                      </Text>
                    </>
                  )}
                </Flex>
              </Flex>
            </DropZone>
          </Flex>
        )}
      </Modal>
    </>
  );
};
