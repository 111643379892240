import type { FC } from "react";
import { useCallback, useMemo, useState } from "react";
import { Text, FiltersModal, Flex } from "@femida1/uikit";
import type { ReportType, CurrentUser, User } from "@femida1/schemas";
import { isUserAdministrator } from "@utils/permissions/isUserAdministrator";
import { MdFilterList } from "react-icons/md";
import { HistoryListSettingsStorage } from "../HistoryListFiltersStorage";
import { EHistoryListSetting, EHistoryListSubSetting } from "../../types";
import { FilterRulesList } from "./components/FilterRulesList";
import { UserIdsList } from "./components/UserIdsList";

interface IHistoryListFiltersModalProps {
  setActiveFilterRules: (selectedFilterRules: ReportType[]) => void;
  activeFilterRules: ReportType[];
  activeUserIds: number[];
  setActiveUserIds: (selectedUserIds: number[]) => void;
  users?: User[];
  user?: CurrentUser;
}

export const HistoryListFiltersModal: FC<IHistoryListFiltersModalProps> = ({
  setActiveFilterRules,
  activeFilterRules,
  activeUserIds,
  setActiveUserIds,
  users,
  user,
}) => {
  const isCurrentUserAdministrator = isUserAdministrator(user);
  const isFiltersActive = useMemo(
    () => activeFilterRules.length !== 0 || activeUserIds.length !== 0,
    [activeFilterRules.length, activeUserIds.length],
  );

  const [selectedFilterRules, setSelectedFilterRules] = useState<ReportType[]>(
    [],
  );
  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);

  const [
    isShowAllFilterRulesCheckboxChecked,
    setIsShowAllFilterRulesCheckboxChecked,
  ] = useState(true);
  const [isShowAllUserIdsCheckboxChecked, setIsShowAllUserIdsCheckboxChecked] =
    useState(true);

  useMemo(() => {
    setSelectedFilterRules(activeFilterRules);
    setSelectedUserIds(activeUserIds);
    setIsShowAllFilterRulesCheckboxChecked(activeFilterRules.length === 0);
    setIsShowAllUserIdsCheckboxChecked(activeUserIds.length === 0);
  }, [activeFilterRules, activeUserIds]);

  const handleShowAllFilterRulesCheckboxCheck = useCallback(
    (checked: boolean) => {
      setIsShowAllFilterRulesCheckboxChecked(checked);
      if (checked) setSelectedFilterRules([]);
    },
    [],
  );
  const handleShowAllUserIdsCheckboxCheck = useCallback((checked: boolean) => {
    setIsShowAllUserIdsCheckboxChecked(checked);
    if (checked) setSelectedUserIds([]);
  }, []);

  const handleApplyFilters = useCallback(() => {
    setActiveFilterRules(selectedFilterRules);
    HistoryListSettingsStorage.set(
      EHistoryListSetting.FILTERS,
      JSON.stringify(selectedFilterRules),
      EHistoryListSubSetting.REPORT_TYPE,
    );

    setActiveUserIds(selectedUserIds);
    HistoryListSettingsStorage.set(
      EHistoryListSetting.FILTERS,
      JSON.stringify(selectedUserIds),
      EHistoryListSubSetting.USER_IDS,
    );
  }, [
    selectedFilterRules,
    selectedUserIds,
    setActiveFilterRules,
    setActiveUserIds,
  ]);

  const handleClearFilters = useCallback(() => {
    setActiveFilterRules([]);
    setActiveUserIds([]);
    HistoryListSettingsStorage.set(EHistoryListSetting.FILTERS, {});
  }, [setActiveFilterRules, setActiveUserIds]);

  return (
    <FiltersModal
      modalTitle="Фильтр"
      title="Фильтры"
      icon={<MdFilterList />}
      isActive={isFiltersActive}
      onApply={handleApplyFilters}
      onCancel={() => {
        setSelectedFilterRules(activeFilterRules);
        setSelectedUserIds(activeUserIds);
      }}
      onClearFilters={handleClearFilters}
    >
      <Flex vertical gap={16}>
        <Flex vertical gap={12}>
          <Text variant="subheader-4">Показывать типы проверки:</Text>
          <FilterRulesList
            selectedFilterRules={selectedFilterRules}
            setIsShowAllFilterRulesCheckboxChecked={
              setIsShowAllFilterRulesCheckboxChecked
            }
            setSelectedFilterRules={setSelectedFilterRules}
            checked={isShowAllFilterRulesCheckboxChecked}
            onUpdate={handleShowAllFilterRulesCheckboxCheck}
          />
        </Flex>
        {user && isCurrentUserAdministrator && users && (
          <Flex vertical gap={12}>
            <Text variant="subheader-4">Показывать проверки сотрудника:</Text>
            <UserIdsList
              users={users}
              selectedUserIds={selectedUserIds}
              setIsShowAllUserIdsCheckboxChecked={
                setIsShowAllUserIdsCheckboxChecked
              }
              setSelectedUserIds={setSelectedUserIds}
              checked={isShowAllUserIdsCheckboxChecked}
              onUpdate={handleShowAllUserIdsCheckboxCheck}
            />
          </Flex>
        )}
      </Flex>
    </FiltersModal>
  );
};
