import { useQuery } from "@tanstack/react-query";
import { axiosClient } from "@femida1/schemas";
import { appConfig } from "@app/providers/AppFeatures/appConfig";
import {
  NodeApiPdfGenerateMode,
  type NodeApiPdfGenerateRequest,
  type NodeApiPdfGenerateResponse,
} from "./types";

export const usePdfGenerateQuery = (
  props: Pick<NodeApiPdfGenerateRequest, "report_id" | "report_type">,
) =>
  useQuery<NodeApiPdfGenerateResponse>({
    enabled: false,
    queryFn: () =>
      axiosClient.post<
        undefined,
        NodeApiPdfGenerateResponse,
        NodeApiPdfGenerateRequest
      >(
        "./node-api/pdf/generate",
        {
          ...props,
          filename: `${props.report_id}.pdf`,
          footer: {
            ...(appConfig.features.reportFooterDescriptionEnabled
              ? { description: window.location.href }
              : {}),
          },
          mode: NodeApiPdfGenerateMode.Pdf,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "arraybuffer",
        },
      ),
    queryKey: [
      "pdf-generate",
      window.location.href,
      props.report_id,
      props.report_type,
    ],
  });
