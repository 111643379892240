import type { FC } from "react";
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import { List } from "antd";
import { Flex, Input, Pagination } from "@femida1/uikit";
import {
  useCurrentUserQuery,
  useGroup,
  useReportsRetrieveAll,
  useReportsTotalCount,
} from "@femida1/gui_web_core";
import debounce from "lodash/debounce";
import type { ENavMenuItem, MainTab } from "@pages/Main/constants";
import { mapMainTabToReportTypes } from "@pages/Main/constants";
import { usePrevious } from "react-use";
import type { ReportType } from "@femida1/schemas";
import { OrderType } from "@femida1/schemas";
import { MdSearch } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { HistoryListCard } from "./components/HistoryListCard/HistoryListCard";
import s from "./HistoryList.module.scss";
import { AnimatedListItem } from "../AnimatedListItem/AnimatedListItem";
import { HistoryListSortingModal } from "./components/HistoryListSortingModal/HistoryListSortingModal";
import { HistoryListFiltersModal } from "./components/HistoryListFiltersModal/HistoryListFiltersModal";
import { HistoryListSettingsStorage } from "./components/HistoryListFiltersStorage";
import { EHistoryListSetting, EHistoryListSubSetting } from "./types";

interface HistoryListProps {
  withPagination?: boolean;
  limit?: number;
  tab: MainTab | ENavMenuItem | null;
  showFilters?: boolean;
}

export const HistoryList: FC<HistoryListProps> = ({
  withPagination = false,
  limit = 5,
  tab,
  showFilters = true,
}) => {
  const {
    data: user,
    isSuccess: isUserSuccess,
    isPending: isUserPending,
  } = useCurrentUserQuery();

  const { data: group } = useGroup(
    { group_id: Number(user?.groups?.[0].group_id) },
    { enabled: Boolean(user?.groups?.[0].group_id) },
  );

  const [activeSortingRule, setActiveSortingRule] = useState(OrderType.DESCEND);
  const [activeFilterRules, setActiveFilterRules] = useState<ReportType[]>([]);
  const [activeUserIds, setActiveUserIds] = useState<number[]>([]);

  useLayoutEffect(() => {
    if (isUserSuccess && !isUserPending) {
      const storedSortingRule =
        HistoryListSettingsStorage.get<OrderType>(
          EHistoryListSetting.SORTING,
        ) || OrderType.DESCEND;
      const storedFilterRules =
        HistoryListSettingsStorage.get<ReportType[]>(
          EHistoryListSetting.FILTERS,
          EHistoryListSubSetting.REPORT_TYPE,
        ) || [];
      const storedUserIds =
        HistoryListSettingsStorage.get<number[]>(
          EHistoryListSetting.FILTERS,
          EHistoryListSubSetting.USER_IDS,
        ) || [];

      setActiveSortingRule(storedSortingRule);
      setActiveFilterRules(storedFilterRules);
      setActiveUserIds(storedUserIds);
    }
    if (!isUserSuccess && !isUserPending) {
      HistoryListSettingsStorage.set(
        EHistoryListSetting.SORTING,
        OrderType.DESCEND,
      );
      HistoryListSettingsStorage.set(EHistoryListSetting.FILTERS, {});
      setActiveSortingRule(OrderType.DESCEND);
      setActiveFilterRules([]);
      setActiveUserIds([]);
    }
  }, [isUserPending, isUserSuccess]);

  const [filterUi, setFilterUi] = useState("");
  const [filter, setFilter] = useState("");
  const prevFilter = useRef<string>("");
  const request_contains = filter || undefined;

  const [page, setPage] = useState(1);
  const offset = (page - 1) * limit;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDebouncedFilterUpdate = useCallback(
    debounce((value: string) => {
      setFilter(value);
      if (value !== prevFilter.current) {
        setPage(1);
      }
      prevFilter.current = value;
    }, 1300),
    [],
  );

  const onFilterUpdate = useCallback(
    (value: string) => {
      setFilterUi(value);
      onDebouncedFilterUpdate(value);
    },
    [onDebouncedFilterUpdate],
  );

  const { data: countReports, isSuccess: isReportsTotalCountSuccess } =
    useReportsTotalCount(
      {
        request_contains,
        report_task_types: tab
          ? mapMainTabToReportTypes[tab] || activeFilterRules
          : undefined,
      },
      {
        enabled: withPagination,
      },
    );
  const { data: reports, isSuccess: isReportsSuccess } = useReportsRetrieveAll({
    limit,
    offset: Number.isInteger(offset) ? offset : undefined,
    request_contains,
    report_task_types: tab
      ? mapMainTabToReportTypes[tab] || activeFilterRules
      : undefined,
    user_ids: activeUserIds,
    order_type:
      tab && mapMainTabToReportTypes[tab]
        ? OrderType.DESCEND
        : activeSortingRule,
  });

  const firstReport = reports?.[0];
  const prevFirstReport = usePrevious(firstReport);
  const isAnimated =
    prevFirstReport?.id !== firstReport?.id &&
    prevFirstReport?.type === firstReport?.type;

  const pagination = useMemo(() => {
    if (
      withPagination &&
      isReportsTotalCountSuccess &&
      countReports.total_count
    ) {
      return (
        <Flex justify="center">
          <Pagination
            total={countReports.total_count}
            pageSize={limit}
            page={page}
            onUpdate={setPage}
          />
        </Flex>
      );
    }

    return null;
  }, [
    withPagination,
    isReportsTotalCountSuccess,
    countReports?.total_count,
    limit,
    page,
  ]);

  return (
    <Flex vertical gap={32}>
      {showFilters && (
        <Flex vertical gap={12}>
          <Input
            placeholder="Поиск по истории"
            placeholderIcon={<MdSearch size={20} />}
            value={filterUi}
            clearIcon={
              filterUi && (
                <IoClose
                  cursor="pointer"
                  size={20}
                  onClick={() => onFilterUpdate("")}
                />
              )
            }
            onUpdate={onFilterUpdate}
          />
          <Flex gap={16}>
            <HistoryListFiltersModal
              activeFilterRules={activeFilterRules}
              setActiveFilterRules={setActiveFilterRules}
              activeUserIds={activeUserIds}
              setActiveUserIds={setActiveUserIds}
              users={group?.users}
              user={user}
            />
            <HistoryListSortingModal
              activeSortingRule={activeSortingRule}
              setActiveSortingRule={setActiveSortingRule}
            />
          </Flex>
        </Flex>
      )}
      <Flex vertical gap={16} component="ul" className={s.list}>
        {isReportsSuccess && reports
          ? reports.map((report) => (
              <AnimatedListItem key={report.id} isAnimated={isAnimated}>
                <HistoryListCard key={report.id} report={report} />
              </AnimatedListItem>
            ))
          : Array.from({ length: limit }).map((_v, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <List.Item key={i}>
                <HistoryListCard isLoading />
              </List.Item>
            ))}
      </Flex>
      {pagination}
    </Flex>
  );
};
