import type { FC } from "react";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

interface SentinelProps {
  onHidden: (value: boolean) => void;
}

export const Sentinel: FC<SentinelProps> = ({ onHidden }) => {
  const [ref, inView] = useInView();

  useEffect(() => {
    onHidden(inView);
  }, [inView]);

  return <div ref={ref} style={{ width: "100%", height: "1px" }} />;
};
