import type { DropZoneFile } from "@femida1/uikit";
import { fileSchema } from "@utils/validation/fileSchema";
import * as yup from "yup";

export const dropZoneFileListSchema = yup.array<DropZoneFile>().of(
  yup.object<DropZoneFile>().shape({
    uid: yup.string().required(),
    name: yup.string().required(),
    lastModifiedDate: yup.date().required(),
    originFileObj: fileSchema,
  }),
);
