import { RetrieveStatus } from "@entities/RetrieveStatus/RetrieveStatus";
import { useNotification } from "@femida1/uikit";
import {
  RetrieveCardPrint,
  RetrieveCreditRatingView,
  calculateReportStatus,
} from "@femida1/gui_web_core";
import type {
  ReportCommonProps,
  ReportsCreditRatingRetrieveResponse,
} from "@femida1/schemas";
import { ReportStatus } from "@femida1/schemas";
import { usePdfGenerateHandlers } from "@app/api/pdf/usePdfGenerateHandlers";
import { SearchByFound } from "@components/SearchByFound/SearchByFound";
import { NoteModalMultiQueryFullDossier } from "@components/NoteModal/NoteModalMultiQueryFullDossier";
import { NoteModalFullDossier } from "@components/NoteModal/NoteModalFullDossier";

export type RetrieveCreditRatingProps = {
  report: ReportsCreditRatingRetrieveResponse;
} & ReportCommonProps;

export const RetrieveCreditRating = ({
  report,
  type,
  reportId,
}: RetrieveCreditRatingProps) => {
  const [api] = useNotification();

  const { onReportLoad, onReportPrint, isLoadingGeneratePdf } =
    usePdfGenerateHandlers({
      api,
      type,
      reportId,
    });

  return (
    <RetrieveStatus
      status={
        calculateReportStatus({
          sourceStatuses: [report?.credit_rating?.status],
          found: report?.status === ReportStatus.Finished,
        }) || report?.status
      }
    >
      <RetrieveCreditRatingView
        print={RetrieveCardPrint.device}
        report={report}
        reportId={reportId}
        type={type}
        isLoadingGeneratePdf={isLoadingGeneratePdf}
        onReportLoad={onReportLoad}
        onReportPrint={onReportPrint}
        renderNoteModal={() => (
          <NoteModalFullDossier reportId={reportId} type={type} />
        )}
        renderMultiQueryNoteModal={({ note }) => (
          <NoteModalMultiQueryFullDossier
            reportId={reportId}
            type={type}
            note={note}
          />
        )}
        renderSearchByFound={(props) => <SearchByFound {...props} />}
      />
    </RetrieveStatus>
  );
};
