import {
  CrossingBorderItemEnum,
  CrossingBordersSummaryKeys,
} from "@femida1/schemas";
import type { FC, SVGProps } from "react";

import {
  Event,
  DateRange,
  FlightLand,
  FlightTakeoff,
  MenuBook,
  Flag,
} from "@femida1/uikit";
import {
  MdNavigation,
  MdCompareArrows,
  MdInfo,
  MdOutlineConfirmationNumber,
  MdLocationCity,
} from "react-icons/md";
import { appConfig } from "@app/providers/AppFeatures/appConfig";

export const CROSSING_BORDER_DATE_FORMAT = "DD.MM.YYYY";

export enum CrossingBordersDateOrder {
  Desc = "Desc",
  Asc = "Asc",
}

export const mapCrossingBorderItemEnumToIcon: Record<
  CrossingBorderItemEnum,
  FC<SVGProps<SVGSVGElement>>
> = {
  [CrossingBorderItemEnum.birthDate]: Event,
  [CrossingBorderItemEnum.crossingDate]: DateRange,
  [CrossingBorderItemEnum.destinationCountry]: FlightLand,
  [CrossingBorderItemEnum.documentCountry]: Flag,
  [CrossingBorderItemEnum.documentIssue_Date]: Event,
  [CrossingBorderItemEnum.documentNumber]: MenuBook,
  [CrossingBorderItemEnum.dstCity]: FlightLand,
  [CrossingBorderItemEnum.grn]: MenuBook,
  [CrossingBorderItemEnum.info]: MdInfo,
  [CrossingBorderItemEnum.operation]: MdCompareArrows,
  [CrossingBorderItemEnum.punkt]: MdLocationCity,
  [CrossingBorderItemEnum.sourceCountry]: FlightTakeoff,
  [CrossingBorderItemEnum.srcCity]: FlightTakeoff,
  [CrossingBorderItemEnum.transportType]: MdNavigation,
  [CrossingBorderItemEnum.tripNum]: MdOutlineConfirmationNumber,
};

export const crossingBorderItemEnumOrder: CrossingBorderItemEnum[] = [
  CrossingBorderItemEnum.crossingDate,
  CrossingBorderItemEnum.operation,
  CrossingBorderItemEnum.transportType,
  CrossingBorderItemEnum.sourceCountry,
  CrossingBorderItemEnum.destinationCountry,
  CrossingBorderItemEnum.documentCountry,

  ...(appConfig.features.reportCrossingBordersExtended
    ? [
        CrossingBorderItemEnum.documentNumber,
        CrossingBorderItemEnum.documentIssue_Date,
        CrossingBorderItemEnum.birthDate,
        CrossingBorderItemEnum.grn,
        CrossingBorderItemEnum.punkt,
        CrossingBorderItemEnum.tripNum,
        CrossingBorderItemEnum.srcCity,
        CrossingBorderItemEnum.dstCity,
        CrossingBorderItemEnum.info,
      ]
    : []),
];

export const crossingBorderItemTitles: Record<CrossingBorderItemEnum, string> =
  {
    [CrossingBorderItemEnum.crossingDate]: "Дата пересечения",
    [CrossingBorderItemEnum.operation]: "Тип пересечения",
    [CrossingBorderItemEnum.transportType]: "Транспорт",
    [CrossingBorderItemEnum.sourceCountry]: "Страна откуда",
    [CrossingBorderItemEnum.destinationCountry]: "Страна куда",
    [CrossingBorderItemEnum.documentCountry]: "Страна выдачи документа",

    [CrossingBorderItemEnum.documentNumber]: "Номер документа",
    [CrossingBorderItemEnum.documentIssue_Date]: "Дата выдачи документа",
    [CrossingBorderItemEnum.birthDate]: "Дата рождения",
    [CrossingBorderItemEnum.grn]: "ГРН",
    [CrossingBorderItemEnum.punkt]: "Пункт",
    [CrossingBorderItemEnum.tripNum]: "Номер транспорта",
    [CrossingBorderItemEnum.srcCity]: "Город откуда",
    [CrossingBorderItemEnum.dstCity]: "Город куда",
    [CrossingBorderItemEnum.info]: "Информация",
  };

export const crossingBordersSummaryTexts: Record<
  CrossingBordersSummaryKeys,
  string
> = {
  [CrossingBordersSummaryKeys.count]: "Количество пересечений",
  [CrossingBordersSummaryKeys.countriesVisited]: "Посещенные страны",
  [CrossingBordersSummaryKeys.countryDocuments]: "Страна выдачи документов",
  [CrossingBordersSummaryKeys.transportList]: "Тип транспорта",
};

export const summaryKeys: CrossingBordersSummaryKeys[] = [
  CrossingBordersSummaryKeys.count,
  CrossingBordersSummaryKeys.countriesVisited,
  CrossingBordersSummaryKeys.countryDocuments,
  CrossingBordersSummaryKeys.transportList,
];
