import { RetrieveStatus } from "@entities/RetrieveStatus/RetrieveStatus";
import type { FC, ReactNode } from "react";
import { useMemo } from "react";
import type {
  FullDossierReport,
  ReportsFullDossierRetrieveResponse,
  ReportType,
} from "@femida1/schemas";
import { ReportHeader } from "@components/ReportHeader/ReportHeader";
import { mapReportTypeToReport } from "@entities/RetrieveFullDossier/constants/mapReportTypeToReport";
import { mapReportTypeToOrder } from "@entities/RetrieveFullDossier/constants/mapReportTypeToOrder";
import { calculateReportStatus } from "@femida1/gui_web_core";

interface RetrieveFullDossierProps {
  report: ReportsFullDossierRetrieveResponse;
  reportId: string;
  type: ReportType;
}

export const RetrieveFullDossier: FC<RetrieveFullDossierProps> = ({
  report,
  reportId,
  type,
}) => {
  const resultingFound = useMemo(
    () => report.report_list?.some((subReport) => subReport.found),
    [report.report_list],
  );

  const sortedReports = useMemo(
    () =>
      [...report.report_list]?.sort(
        (a, b) => mapReportTypeToOrder[a.type] - mapReportTypeToOrder[b.type],
      ),
    [report.report_list],
  );

  return (
    <RetrieveStatus
      status={
        calculateReportStatus({
          sourceStatuses: report.report_list?.map(
            (subReport) => subReport.status,
          ),
          found: resultingFound,
        }) || report.status
      }
    >
      <ReportHeader
        reportId={reportId}
        title="Полное досье"
        type={type}
        requestDescribe={report.request_describe}
        status={report.status}
        note={report.note}
      />
      {sortedReports.map((currReport) => {
        const subReport: (currReport: FullDossierReport) => ReactNode =
          mapReportTypeToReport[currReport.type];

        if (!subReport) return null;

        return subReport(currReport);
      })}
    </RetrieveStatus>
  );
};
