import { useParams } from "react-router-dom";
import { Skeleton } from "antd";
import type { ReportCommonProps } from "@femida1/schemas";
import { RetrieveVk } from "@entities/RetrieveVk/RetrieveVk";
import { useReportsVkRetrieve } from "@femida1/gui_web_core";

export const SearchVkWidget = ({
  type,
}: Omit<ReportCommonProps, "reportId">) => {
  const { reportId = "" } = useParams();

  const { data: vkReport, isLoading } = useReportsVkRetrieve({
    id: reportId,
  });

  return (
    <Skeleton loading={isLoading}>
      {vkReport && (
        <RetrieveVk report={vkReport} type={type} reportId={reportId} />
      )}
    </Skeleton>
  );
};
