import type { FC } from "react";
import { useCallback, useMemo, useState } from "react";
import { Flex, Toggle } from "@femida1/uikit";
import { useCurrentUserQuery } from "@femida1/gui_web_core";
import { Permission } from "@femida1/schemas";
import { getMainTabToggleSettings } from "@pages/Main/components/MainTabs/utils/getMainTabToggleSettings";
import type { MainTabProps } from "../../types";
import { MainTabInn } from "./components/MainTabInn";
import { MainTabSnils } from "./components/MainTabSnils";

type MainTabInnOrSnilsProps = MainTabProps;

export const MainTabInnOrSnils: FC<MainTabInnOrSnilsProps> = ({
  onSubmit,
  setMultiQueryCheckboxVisible,
}) => {
  const { data: user } = useCurrentUserQuery();
  const userPermissions = user?.groups?.[0].permissions;

  const { shouldShowToggle, initialType } = useMemo(
    () =>
      getMainTabToggleSettings<"inn", "snils">({
        userPermissions,
        firstPermission: Permission.ReportInnFlPermission,
        secondPermission: Permission.ReportSnilsPermission,
        firstType: "inn",
        secondType: "snils",
      }),
    [userPermissions],
  );

  const [type, setType] = useState<"snils" | "inn">(initialType);

  const onTypeChange = useCallback((checked: boolean) => {
    if (checked) {
      setType("snils");
    } else {
      setType("inn");
    }
  }, []);

  return (
    <Flex vertical gap={24}>
      {type === "inn" ? (
        <MainTabInn
          onSubmit={onSubmit}
          setMultiQueryCheckboxVisible={setMultiQueryCheckboxVisible}
        />
      ) : (
        <MainTabSnils
          onSubmit={onSubmit}
          setMultiQueryCheckboxVisible={setMultiQueryCheckboxVisible}
        />
      )}
      {shouldShowToggle && (
        <Toggle
          value={type === "snils"}
          onChange={onTypeChange}
          title="Проверить по СНИЛС"
        />
      )}
    </Flex>
  );
};
