import { Flex, Block, Text } from "@femida1/uikit";
import { useAppFeatures } from "@app/providers";
import { OnboardingModalContainer } from "@components/OnboardingModalContainer";
import s from "./Main.module.scss";
import { MainTabs } from "./components/MainTabs/MainTabs";
import { MainHistory } from "./components/MainHistory/MainHistory";
import { useMainTabSearchParam } from "./hooks/useMainTabSearchParam";

export const Main = () => {
  const [tab, setTab] = useMainTabSearchParam();
  const { onboardingEnabled } = useAppFeatures();

  return (
    <Flex vertical gap={32} className={s.wrapper}>
      <Block>
        <Flex vertical gap={24}>
          <Text variant="header-8" className={s.wrapper__label}>
            Находим данные
            <br /> и собираем их в единый отчет
          </Text>
          <MainTabs tab={tab} setTab={setTab} />
        </Flex>
      </Block>
      <Block>
        <MainHistory tab={tab} />
      </Block>
      {onboardingEnabled ? (
        <OnboardingModalContainer onboardingPage="main" />
      ) : null}
    </Flex>
  );
};
