import { AppRoute } from "@app/routes/constants";
import { ENavMenuItem, MainTab } from "@pages/Main/constants";
import { MenuGroup } from "@app/layouts/MainLayout/components/constants";
import { ReactComponent as Odyssey } from "@assets/logo_odyssey.svg";
import { ReactComponent as OdysseyDesktopLight } from "@assets/logo_odyssey_desktop_light.svg";
import { ReactComponent as OdysseyDesktopDark } from "@assets/logo_odyssey_desktop_dark.svg";
import type { AppConfig } from "../../types";

const telegramBotEnabled = false;

export const odysseyProdConfig: AppConfig = {
  app: {
    icon: {
      desktop: {
        dark: {
          Icon: OdysseyDesktopDark,
          path: "src/assets/logo_odyssey_desktop_dark.svg",
        },
        light: {
          Icon: OdysseyDesktopLight,
          path: "src/assets/logo_odyssey_desktop_light.svg",
        },
      },
      mobile: {
        dark: {
          Icon: Odyssey,
          path: "src/assets/logo_odyssey.svg",
        },
        light: {
          Icon: Odyssey,
          path: "src/assets/logo_odyssey.svg",
        },
      },
    },
  },
  features: {
    appEnabled: true,

    authResetPasswordEnabled: false,
    authUserSelfRegistrationEnabled: false,
    chatsAvailable: true,
    documentsPageEnabled: true,

    mainIncludeOpenSourcesEnabled: false,
    mainMultiQueryEnabled: false,

    onboardingEnabled: false,

    reportCrossingBordersExtended: true,
    reportFioAsGift: true,
    reportFooterDescriptionEnabled: true,
    reportPdfFooterLinkEnabled: true,
    reportPossibleNetworksCreateReportEnabled: false,
    reportStatusInverted: true,

    tabEmailLightSearch: true,
    tabFioSopdEnabled: true,
    tabFullDossierLightDriverLicenseEnabled: false,
    tabMainSopdEnabled: true,
    tabPhoneLightSearch: true,
    tabVkSopdEnabled: true,

    telegramBotEnabled,
    themeSwitchEnabled: true,

    userSettingsGroupType: true,
  },
  hosts: {
    apiDocsHref: "https://docs.odyssey-search.ru",
  },
  navMenu: {
    items: [
      {
        children: [
          MainTab.FullDossierLight,
          MainTab.PhoneLight,
          MainTab.EmailLight,
          MainTab.PhotoLight,
          MainTab.CreditRating,
          MainTab.Vk,
          MainTab.Telegram,
        ],
        key: MenuGroup.Checking,
      },
      {
        children: [ENavMenuItem.Analytics, ENavMenuItem.History],
        key: MenuGroup.Cabinet,
      },
      {
        children: [
          ENavMenuItem.Action,
          ENavMenuItem.Accesses,
          ENavMenuItem.Support,
        ],
        key: MenuGroup.Organization,
      },
      {
        children: [ENavMenuItem.Contacts],
        key: MenuGroup.Info,
      },
      ...(telegramBotEnabled ? [{ key: MenuGroup.Telegram }] : []),
    ],
  },
  pages: {
    analytics: {
      graphColors: {
        all: {
          /**
           * --color-main-0
           */
          bgGradientBottom: "rgba(0, 204, 105, 0)",
          /**
           * --color-main-300
           */
          bgGradientTop: "rgba(0, 204, 105, 0.3)",
          /**
           * --color-main-1000
           */
          borderColor: "rgb(0, 204, 105)",
        },
        target: {
          /**
           * --color-base-accident-0
           */
          bgGradientBottom: "rgba(251, 139, 91, 0)",
          /**
           * --color-base-accident-300
           */
          bgGradientTop: "rgba(251, 139, 91, 0.3)",
          /**
           * --color-base-accident-1000
           */
          borderColor: "rgb(251, 139, 91)",
        },
      },
    },
  },
  routes: [
    { key: AppRoute.Analytics },
    { key: AppRoute.Employees },
    { key: AppRoute.Accesses },
    { key: AppRoute.Telegram },
    { key: AppRoute.History },
    { key: AppRoute.Contacts },
    { key: AppRoute.Support },
    { key: AppRoute.Action },
    { key: AppRoute.Documents },
    { key: AppRoute.User },
    { key: AppRoute.AccessesAdd },
    { key: AppRoute.AccessesEdit },
    { key: AppRoute.Search },
    { key: AppRoute.Alerts },
  ],
  title: "Odyssey",
};
