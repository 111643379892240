import type { FC } from "react";
import type { Permission as PermissionType } from "@femida1/schemas";
import classNames from "classnames";
import { Text, Flex } from "@femida1/uikit";
import { mapPermissionToLabel } from "@utils/permissions/constants";
import { useAppTheme } from "@app/providers";
import s from "./Permission.module.scss";
import { mapPermissionToType } from "./constants";

interface PermissionProps {
  permission: PermissionType;
}

export const Permission: FC<PermissionProps> = ({ permission }) => {
  const label = mapPermissionToLabel[permission];
  const type = mapPermissionToType[permission];
  const [theme] = useAppTheme();

  return (
    <Flex
      className={classNames(
        s.permission,
        s[`permission_type_${type}`],
        s[`permission_theme_${theme}`],
      )}
      justify="center"
      align="center"
    >
      <Text variant="body-2" className={s.permission__text}>
        {label}
      </Text>
    </Flex>
  );
};
