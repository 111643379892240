interface DownloadFileProps {
  data: BlobPart;
  filename: string;
  type?: string;
}

export const downloadFile = ({ data, filename, type }: DownloadFileProps) => {
  const file = new Blob([data], { type });

  const url = window.URL.createObjectURL(file);
  const element = document.createElement("a");
  element.href = url;
  element.download = filename;
  element.style.display = "none";

  document.body.appendChild(element);
  element.click();
  element.remove();
  window.URL.revokeObjectURL(url);
};
