import type { FC } from "react";
import { Button } from "antd";
import { Flex, Text } from "@femida1/uikit";

import s from "./DocumentCard.module.scss";

interface DocumentCardProps {
  title: string;
  size: number;
  onDownload: () => void;
}

export const DocumentCard: FC<DocumentCardProps> = ({
  title,
  size,
  onDownload,
}) => (
  <div className={s.card}>
    <Flex justify="space-between">
      <Flex vertical gap={16}>
        <Text variant="header-6">{title}</Text>
        <Text color="secondary">{`${size} КБ`}</Text>
      </Flex>
      <Button onClick={onDownload}>Скачать</Button>
    </Flex>
  </div>
);
