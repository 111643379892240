import type { FC, SetStateAction } from "react";
import { useCallback, useMemo } from "react";
import type { ReportType } from "@femida1/schemas";
import { Flex, Checkbox, type CheckboxChangeEvent } from "@femida1/uikit";
import { mapReportTypeToLabel } from "@femida1/gui_web_core";
import { availableReportTypes } from "@app/providers/AppFeatures/constants/availableReportTypes";
import type { IShowAllCheckboxProps } from "./ShowAllCheckbox";
import { ShowAllCheckbox } from "./ShowAllCheckbox";
import { handleCheckboxCheck } from "./utils/handleCheckboxCheck";

interface IFilterRulesListProps extends IShowAllCheckboxProps {
  selectedFilterRules: ReportType[];
  setSelectedFilterRules: (value: SetStateAction<ReportType[]>) => void;
  setIsShowAllFilterRulesCheckboxChecked: (value: boolean) => void;
}

export const FilterRulesList: FC<IFilterRulesListProps> = ({
  selectedFilterRules,
  setSelectedFilterRules,
  setIsShowAllFilterRulesCheckboxChecked,
  checked: showAllCheckboxChecked,
  onUpdate,
}) => {
  const selectedFilterRulesSet = useMemo(
    () => new Set(selectedFilterRules),
    [selectedFilterRules],
  );

  const handleChangeCheckbox = useCallback(
    ({ e, item }: { e: CheckboxChangeEvent; item: ReportType }) => {
      const { checked } = e.target;
      handleCheckboxCheck(
        checked,
        item,
        setSelectedFilterRules,
        setIsShowAllFilterRulesCheckboxChecked,
      );
    },
    [setIsShowAllFilterRulesCheckboxChecked, setSelectedFilterRules],
  );

  return (
    <Flex vertical gap={9.5}>
      <ShowAllCheckbox checked={showAllCheckboxChecked} onUpdate={onUpdate} />
      {availableReportTypes?.map((item) => (
        <Checkbox
          key={item}
          checked={selectedFilterRulesSet.has(item)}
          onChange={(e) => handleChangeCheckbox({ e, item })}
        >
          {mapReportTypeToLabel[item]}
        </Checkbox>
      ))}
    </Flex>
  );
};
