import type { ReportsFioMultiParseResponse } from "@femida1/schemas";
import cn from "classnames";
import { Block, Text, Button, Flex } from "@femida1/uikit";
import { MdDeleteForever } from "react-icons/md";
import { formatCompositeDate } from "@femida1/gui_web_core";
import s from "./MultiFioRow.module.scss";

export const MultiFioRow = ({
  row,
  onRowDelete,
}: {
  row: ReportsFioMultiParseResponse[number];
  onRowDelete: (id: number) => void;
}) => {
  const { original_value, result, error_message, id } = row;

  return (
    <Flex align="center">
      <div className={s.row}>
        <Block className={cn(s.block, error_message && s.error)}>
          {error_message ? (
            <Text>{original_value}</Text>
          ) : (
            <Text>
              {result.last_name} {result.first_name} {result.middle_name}{" "}
              {formatCompositeDate(
                result.birth_day,
                result.birth_month,
                result.birth_year,
              )}
            </Text>
          )}
        </Block>
        {error_message && (
          <Text variant="label-1" color="red">
            {error_message.charAt(0).toUpperCase() + error_message.slice(1)}
          </Text>
        )}
      </div>
      {!error_message && (
        <Button
          type="flat"
          size="s"
          className={s.delete_button}
          onClick={() => onRowDelete(id)}
        >
          <MdDeleteForever size={24} />
        </Button>
      )}
    </Flex>
  );
};
