import type { WordWithGuessedType } from "./types";
import { WordType } from "./types";

const prefixes = new Set([
  "ибн",
  "ibn",
  "бинт",
  "bint",
  "бен",
  "ben",
  "бар",
  "bar",
  "fils de",
]);

const postfixes = new Set(["оглы", "оглу", "oğlu", "oglu", "кызы", "kyzy"]);

export const joinMiddleNamePatronyms = (
  pastedWords: WordWithGuessedType[],
): WordWithGuessedType[] => {
  const words = [...pastedWords];
  const prefixIdx = pastedWords.findIndex((pastedWord) =>
    prefixes.has(pastedWord.word),
  );
  const postfixIdx = pastedWords.findIndex((pastedWord) =>
    postfixes.has(pastedWord.word),
  );

  if (prefixIdx === -1 && postfixIdx === -1) {
    return words;
  }

  if (postfixIdx !== -1) {
    const targetIdx = postfixIdx - 1;
    if (targetIdx >= 0 && targetIdx < words.length) {
      words.splice(
        targetIdx,
        2,
        (words[targetIdx] = {
          word: `${words[targetIdx].word} ${words[postfixIdx].word}`,
          type: WordType.MiddleName,
        }),
      );
    }
    return words;
  }

  if (prefixIdx !== -1) {
    const targetIdx = prefixIdx + 1;
    if (targetIdx >= 0 && targetIdx < words.length) {
      words.splice(prefixIdx, 2, {
        word: `${words[prefixIdx].word} ${words[targetIdx].word}`,
        type: WordType.MiddleName,
      });
    }
    return words;
  }

  return words;
};
