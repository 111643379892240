import type { FC } from "react";
import { Fragment } from "react";
import { Flex } from "@femida1/uikit";
import {
  generatePath,
  useLocation,
  useParams,
  Link,
  useSearchParams,
} from "react-router-dom";
import { LuChevronRight } from "react-icons/lu";
import classNames from "classnames";
import s from "./BreadCrumps.module.scss";

type MapPathToTitle = Record<string, string>;

const mapPathToTitleDefault: MapPathToTitle = {
  articles: "Блог",
};

interface BreadCrumpsProps {
  mapPathToTitle: MapPathToTitle;
  paths?: string[];
}

export const BreadCrumps: FC<BreadCrumpsProps> = ({
  mapPathToTitle,
  paths,
}) => {
  const { pathname } = useLocation();
  const pathnames = pathname.split("/").filter(Boolean);
  const params = useParams();
  const [searchParams] = useSearchParams();

  const mapPathToTitleCommon: MapPathToTitle = {
    ...mapPathToTitleDefault,
    ...mapPathToTitle,
  };

  return (
    <Flex gap={12} align="center" className={s.breadcrumps}>
      <Link to="/" className={s.breadcrumps__link}>
        Главная страница
      </Link>
      {(paths || pathnames).map((path, index, self) => {
        const to = `${generatePath(self.slice(0, index + 1).join("/"), params)}?${searchParams.toString()}`;

        return (
          <Fragment key={to}>
            <LuChevronRight strokeWidth={3} />
            <Link
              to={to}
              className={classNames(s.breadcrumps__link, {
                [s.breadcrumps__link_active]: index === self.length - 1,
              })}
            >
              {mapPathToTitleCommon[path] || path}
            </Link>
          </Fragment>
        );
      })}
    </Flex>
  );
};
