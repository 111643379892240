import type { PropsWithChildren } from "react";
import { StatusPaper, type StatusPaperProps } from "@femida1/gui_web_core";
import s from "./RetrieveStatus.module.scss";

export type RetrieveStatusProps = {
  isSubReport?: boolean;
} & StatusPaperProps;

export const RetrieveStatus = ({
  status,
  children,
  isSubReport,
}: PropsWithChildren<RetrieveStatusProps>) => (
  <div className={s.container}>
    {!isSubReport && <StatusPaper status={status} />}
    {children}
  </div>
);
