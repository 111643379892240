import { Flex, Block, Text } from "@femida1/uikit";
import { AlertsList } from "@components/AlertsList/AlertsList";
import s from "./Alerts.module.scss";
import { PageHeader } from "../../app/layouts/PageHeader/PageHeader";
import { AppRoute } from "../../app/routes/constants";

export const Alerts = () => (
  <Flex vertical gap={32} className={s.wrapper}>
    <PageHeader
      title="Системные оповещения"
      mapPathToTitle={{ [AppRoute.Alerts]: "Системные оповещения" }}
      paths={[AppRoute.Alerts]}
    />
    <Block>
      <Flex vertical gap={12}>
        <Text variant="label-4" color="accent" className={s.title}>
          Системные оповещения
        </Text>
        <AlertsList />
      </Flex>
    </Block>
  </Flex>
);
