import type { FC } from "react";
import { RetrieveStatus } from "@entities/RetrieveStatus/RetrieveStatus";
import type {
  ReportsTelegramRetrieveResponse,
  ReportCommonProps,
} from "@femida1/schemas";
import { NoteModalFullDossier } from "@components/NoteModal/NoteModalFullDossier";
import { NoteModalMultiQueryFullDossier } from "@components/NoteModal/NoteModalMultiQueryFullDossier";
import { SearchByFound } from "@components/SearchByFound/SearchByFound";
import { RetrieveTelegramView } from "@femida1/gui_web_core";
import { useNotification } from "@femida1/uikit";
import { usePdfGenerateHandlers } from "@app/api/pdf/usePdfGenerateHandlers";

type TRetrieveTelegramProps = {
  report: ReportsTelegramRetrieveResponse;
} & ReportCommonProps;

export const RetrieveTelegram: FC<TRetrieveTelegramProps> = ({
  report,
  reportId,
  type,
}) => {
  const [api] = useNotification();

  const { onReportLoad, onReportPrint, isLoadingGeneratePdf } =
    usePdfGenerateHandlers({
      api,
      type,
      reportId,
    });

  return (
    <RetrieveStatus status={report?.status}>
      <RetrieveTelegramView
        report={report}
        reportId={reportId}
        type={type}
        isLoadingGeneratePdf={isLoadingGeneratePdf}
        onReportLoad={onReportLoad}
        onReportPrint={onReportPrint}
        renderNoteModal={() => (
          <NoteModalFullDossier reportId={reportId} type={type} />
        )}
        renderMultiQueryNoteModal={({ note }) => (
          <NoteModalMultiQueryFullDossier
            reportId={reportId}
            type={type}
            note={note}
          />
        )}
        renderSearchByFound={(props) => <SearchByFound {...props} />}
      />
    </RetrieveStatus>
  );
};
