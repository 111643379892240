interface FormatPriceProps {
  amount: number;
  currency: "RUB";
}

export const formatPrice = ({ amount, currency }: FormatPriceProps) => {
  const formatter = new Intl.NumberFormat("ru-RU", {
    style: "currency",
    currency,
  });

  return formatter.format(amount);
};
