import { lazy } from "react";

export const Report = lazy(() =>
  import("./Report/Report").then((module) => ({ default: module.Report })),
);
export const Main = lazy(() =>
  import("./Main/Main").then((module) => ({ default: module.Main })),
);
export const UserSettings = lazy(() =>
  import("./UserSettings/UserSettings").then((module) => ({
    default: module.UserSettings,
  })),
);
export const Analytics = lazy(() =>
  import("./Analytics/Analytics").then((module) => ({
    default: module.Analytics,
  })),
);
export const Employees = lazy(() =>
  import("./Employees/Employees").then((module) => ({
    default: module.Employees,
  })),
);
export const Accesses = lazy(() =>
  import("./Accesses/Accesses").then((module) => ({
    default: module.Accesses,
  })),
);
export const AccessesAdd = lazy(() =>
  import("./AccessesAdd/AccessesAdd").then((module) => ({
    default: module.AccessesAdd,
  })),
);
export const AccessesEdit = lazy(() =>
  import("./AccessesEdit/AccessesEdit").then((module) => ({
    default: module.AccessesEdit,
  })),
);
export const Search = lazy(() =>
  import("./Search/Search").then((module) => ({ default: module.Search })),
);
export const Telegram = lazy(() =>
  import("./Telegram/Telegram").then((module) => ({
    default: module.Telegram,
  })),
);
export const History = lazy(() =>
  import("./History/History").then((module) => ({ default: module.History })),
);
export const Contacts = lazy(() =>
  import("./Contacts/Contacts").then((module) => ({
    default: module.Contacts,
  })),
);
export const Alerts = lazy(() =>
  import("./Alerts/Alerts").then((module) => ({ default: module.Alerts })),
);
export const Documents = lazy(() =>
  import("./Documents/Documents").then((module) => ({
    default: module.Documents,
  })),
);
export const SupportChat = lazy(() =>
  import("./SupportChat/SupportChat").then((module) => ({
    default: module.SupportChat,
  })),
);
export const ActionLog = lazy(() =>
  import("./ActionLog/ActionLog").then((module) => ({
    default: module.ActionLog,
  })),
);
export const Tariffs = lazy(() =>
  import("./Tariffs/Tariffs").then((module) => ({ default: module.Tariffs })),
);
export const TemporaryShutdown = lazy(() =>
  import("./TemporaryShutdown/TemporaryShutdown").then((module) => ({
    default: module.TemporaryShutdown,
  })),
);
