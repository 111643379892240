import { ENavMenuItem } from "@pages/Main/constants";
import type { MenuSubItem } from "@app/layouts/MainLayout/components/types";
import { generatePath } from "react-router-dom";
import { AppRoute } from "@app/routes/constants";
import { appConfig } from "../appConfig";

const { apiDocsHref } = appConfig.hosts;

export const mapNavMenuItems: Record<
  ENavMenuItem,
  <T extends object>(props: T) => MenuSubItem
> = {
  [ENavMenuItem.Analytics]: ({
    group_id,
    user_id,
  }: {
    group_id?: number;
    user_id?: number;
  }) => ({
    disabled: !group_id || !user_id,
    href: generatePath(AppRoute.Analytics, {
      group_id: String(group_id),
      user_id: String(user_id),
    }),
    key: ENavMenuItem.Analytics,
    meta: "аналитика,analytics",
    title: "Аналитика",
  }),
  [ENavMenuItem.History]: () => ({
    href: AppRoute.History,
    key: ENavMenuItem.History,
    meta: "история проверок,history",
    title: "История проверок",
  }),
  [ENavMenuItem.Employees]: () => ({
    href: AppRoute.Employees,
    key: ENavMenuItem.Employees,
    meta: "сотрудники,employees",
    title: "Мониторинг",
  }),
  [ENavMenuItem.Action]: () => ({
    href: AppRoute.Action,
    key: ENavMenuItem.Action,
    meta: "действия,actions",
    title: "Журнал действий",
  }),
  [ENavMenuItem.Accesses]: ({ group_id }: { group_id?: number }) => ({
    disabled: !group_id,
    href: generatePath(AppRoute.Accesses, {
      group_id: String(group_id),
    }),
    key: ENavMenuItem.Accesses,
    meta: "доступы,accesses",
    title: "Доступы",
  }),
  [ENavMenuItem.Support]: () => ({
    href: AppRoute.Support,
    key: ENavMenuItem.Support,
    meta: "поддержка,support",
    title: "Поддержка",
  }),
  [ENavMenuItem.ApiDocs]: () => ({
    href: apiDocsHref,
    key: "apiDocs",
    meta: "документация,api",
    title: "API-документация",
  }),
  [ENavMenuItem.Contacts]: () => ({
    href: AppRoute.Contacts,
    key: ENavMenuItem.Contacts,
    meta: "контакты,contacts",
    title: "Контакты",
  }),
};
