import type { FC } from "react";
import { useCallback } from "react";
import { Alert } from "antd";
import {
  Text,
  Input,
  Button,
  useNotification,
  useBooleanState,
  Flex,
} from "@femida1/uikit";
import type { SubmitHandler } from "react-hook-form";
import { Controller, useForm } from "react-hook-form";
import { useUsersChangePasswordMutation } from "@femida1/gui_web_core";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { validationMessage, validationRegex } from "@utils/constants";
import { PiWarningBold } from "react-icons/pi";
import { handleBackendErrors } from "@utils/notifications/handleBackendErrors";
import { generatePassword } from "@utils/generate/password";
import type { AxiosError } from "axios";
import { isAxiosError } from "axios";
import type { ChangeGroupsPasswordRequest } from "@femida1/schemas";

interface UserPasswordViewProps {
  onEdit: () => void;
}

export const UserPasswordView: FC<UserPasswordViewProps> = ({ onEdit }) => (
  <Flex vertical gap={8}>
    <Text variant="body-5" color="secondary">
      Текущий пароль
    </Text>
    <Input disabled placeholder="********" />
    <Button width="fit" size="s" onClick={onEdit}>
      Сменить пароль
    </Button>
  </Flex>
);

interface ChangePasswordFormValues extends ChangeGroupsPasswordRequest {
  new_password_confirm: string;
}

const schema = yup
  .object<ChangePasswordFormValues>()
  .shape({
    old_password: yup.string().required(validationMessage.Required),
    new_password: yup
      .string()
      .min(8, validationMessage.MinLen({ min: 8 }))
      .matches(validationRegex.password, validationMessage.WrongPassword)
      .required(validationMessage.Required),
    new_password_confirm: yup
      .string()
      .oneOf([yup.ref("new_password"), ""], " ")
      .required(validationMessage.Required),
  })
  .required();

interface UserPasswordEditProps {
  onView: () => void;
}

export const UserPasswordEdit: FC<UserPasswordEditProps> = ({ onView }) => {
  const { control, handleSubmit, trigger, setValue, formState } =
    useForm<ChangePasswordFormValues>({
      defaultValues: {
        old_password: "",
        new_password: "",
        new_password_confirm: "",
      },
      mode: "onChange",
      resolver: yupResolver(schema),
    });
  const { mutateAsync: changePassword, error: changePasswordError } =
    useUsersChangePasswordMutation();

  const [api] = useNotification();
  const { isValid } = formState;

  const onGeneratePassword = useCallback(async () => {
    const generatedPassword = await generatePassword();

    setValue("new_password", generatedPassword);
    setValue("new_password_confirm", generatedPassword);

    await trigger().catch(() => {});
  }, [trigger, setValue]);

  const onSubmit = useCallback<SubmitHandler<ChangePasswordFormValues>>(
    (data) => {
      changePassword(data)
        .then(() => {
          api.success({
            message: "Пароль успешно изменён",
          });

          onView();
        })
        .catch((error: AxiosError) => {
          if (isAxiosError(error) && error.response?.status === 403) {
            return "";
          }

          return handleBackendErrors({
            api,
            error,
          });
        });
    },
    [changePassword, onView, api],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex vertical gap={16}>
        <Flex gap={8} vertical>
          <Text variant="body-5" color="secondary">
            Текущий пароль
          </Text>
          <Controller
            name="old_password"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                placeholder="Текущий пароль"
                required
                type="password"
                validate={fieldState}
                isError={!!changePasswordError}
              />
            )}
          />
        </Flex>
        <Flex gap={8} vertical>
          <Text variant="body-5" color="secondary">
            Новый пароль
          </Text>
          <Controller
            name="new_password"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                placeholder="Новый пароль"
                required
                type="password"
                validate={fieldState}
              />
            )}
          />
          <Button size="s" onClick={onGeneratePassword} type="secondary">
            Сгенерировать случайный пароль
          </Button>
        </Flex>
        <Flex gap={8} vertical>
          <Text variant="body-5" color="secondary">
            Повторите пароль
          </Text>
          <Controller
            name="new_password_confirm"
            control={control}
            render={({ field, fieldState }) => (
              <>
                <Input
                  {...field}
                  placeholder="Повторите пароль"
                  required
                  type="password"
                  validate={fieldState}
                />
                {field.value.length > 0 && fieldState.invalid && (
                  <Alert
                    icon={<PiWarningBold size={16} />}
                    showIcon
                    type="error"
                    message={
                      <Text color="inherit" variant="subheader-3">
                        Новый пароль не совпадает
                      </Text>
                    }
                  />
                )}
              </>
            )}
          />
        </Flex>
        {changePasswordError?.response?.status === 403 ? (
          <Alert
            icon={<PiWarningBold size={16} />}
            showIcon
            type="error"
            message="Текущий пароль не совпадает"
          />
        ) : null}
        <Flex gap={16}>
          <Button size="s" htmlType="submit" disabled={!isValid}>
            Сохранить новый пароль
          </Button>
          <Button size="s" onClick={onView} type="flat_secondary">
            Отменить
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};

interface UserPasswordProps {}

export const UserPassword: FC<UserPasswordProps> = () => {
  const { state: isEdit, toggle } = useBooleanState(false);

  return (
    <Flex vertical gap={16}>
      <Text variant="subheader-4">Пароль</Text>
      {isEdit ? (
        <UserPasswordEdit onView={toggle} />
      ) : (
        <UserPasswordView onEdit={toggle} />
      )}
    </Flex>
  );
};
