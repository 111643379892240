import type { FC } from "react";
import { useCallback, useState } from "react";
import type { DropZoneFile } from "@femida1/uikit";
import { DropZone, Text, Button, usePageSize, Flex } from "@femida1/uikit";
import { Image } from "antd";
import { MdPortrait } from "react-icons/md";
import classNames from "classnames";
import { SubmitButton } from "@pages/Main/components/MainTabs/components/SubmitButton/SubmitButton";
import { useReportsPhotoByFaceSearch } from "@femida1/gui_web_core";
import s from "./MainTabPhoto.module.scss";
import type { MainTabProps } from "../../types";

type MainTabPhotoProps = MainTabProps;

export const MainTabPhoto: FC<MainTabPhotoProps> = ({ onSubmit }) => {
  const [fileList, setFileList] = useState<DropZoneFile[]>([]);
  const file = fileList?.[0]?.originFileObj;
  const { isFromTabletVertical } = usePageSize();
  const { mutateAsync: search, isPending: isSearchPending } =
    useReportsPhotoByFaceSearch();

  const handleSubmit = useCallback(() => {
    if (!file) return;

    const requestData = { image: file };
    const reset = () => {
      setFileList([]);
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    onSubmit({ requestData, search, onSuccess: reset });
  }, [file, onSubmit, search]);

  return (
    <Flex vertical gap={24}>
      <DropZone
        accept=".jpg,.jpeg,.png"
        multiple={false}
        withAbilityToPaste
        onChange={(value) => setFileList([value[value.length - 1]])}
        selectFileButtonTitle={file ? "Заменить" : "Выбрать файл"}
        // eslint-disable-next-line react/no-unstable-nested-components
        clearFileButton={(onClear) =>
          file && (
            <Button type="flat" size="s" onClick={onClear}>
              <Text variant="body-4" color="secondary">
                Очистить
              </Text>
            </Button>
          )
        }
      >
        {file ? (
          <Flex
            align="center"
            vertical
            gap={8}
            className={classNames(s.zone, s.photoZone)}
          >
            <Flex align="center" vertical gap={4}>
              <Image
                rootClassName={s.photo}
                preview={false}
                src={URL.createObjectURL(file)}
                height={180}
                width={180}
              />
              <Text color="aqua" variant="body-1">
                {file.name}
              </Text>
            </Flex>
          </Flex>
        ) : (
          <Flex
            align="center"
            vertical
            gap={32}
            className={classNames(s.zone, s.dropZone)}
          >
            <Flex align="center" vertical gap={24}>
              <MdPortrait size={48} className={s.icon} />
              <Text color="aqua" variant="body-6">
                Загрузите
                {isFromTabletVertical ? " или перетащите " : ""}
                файл формата: jpg, png
              </Text>
            </Flex>
          </Flex>
        )}
      </DropZone>
      <Flex justify="flex-end">
        <SubmitButton
          onSubmit={handleSubmit}
          disabled={!file || isSearchPending}
        />
      </Flex>
    </Flex>
  );
};
