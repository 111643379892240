import type { FC } from "react";
import type { IEmployeeDto } from "@femida1/schemas";
import { renderIcon } from "@pages/Employees/utils";
import { Modal } from "antd";
import { EStatus } from "@pages/Employees/types";
import { Text, useBooleanState } from "@femida1/uikit";
import s from "./EmployeeSummaryModal.module.scss";

interface IEmployeeSummaryModalProps {
  employee: IEmployeeDto;
}

const renderResult = (check?: number, status?: string) => {
  if (status === EStatus.In_progress) return "В процессе";
  if (check === 0 && status === EStatus.Completed) return "Не найдено";
  if (check === 1 && status === EStatus.Completed) return "Найдено";

  return "Что-то пошло не так";
};

export const EmployeeSummaryModal: FC<IEmployeeSummaryModalProps> = ({
  employee,
}) => {
  const {
    state: isModalVisible,
    setTrue: showModal,
    setFalse: hideModal,
  } = useBooleanState(false);

  const data: { label: string; status?: string; result?: number }[] = [
    { label: "Арбитраж" },
    { label: "Залоги" },
    {
      label: "Принадлежность к ИП",
      status: employee.ip_check_status,
      result: employee.ip_check,
    },
    {
      label: "Принадлежность к ЮЛ",
      status: employee.ul_check_status,
      result: employee.ul_check,
    },
    {
      label: "Действительность паспорта",
      status: employee.passport_status,
      result: employee.passport_status === EStatus.Completed ? 1 : 0,
    },
    { label: "Страницы VK" },
    {
      label: "Самозанятость",
      status: employee.self_employment_check_status,
      result: employee.self_employment_check,
    },
    { label: "ЧС каршеринга" },
    {
      label: "ЧС банков",
      status: employee.black_list_check_status,
      result: employee.black_list_check,
    },
    {
      label: "ФССП",
      status: employee.fssp_check_status,
      result: employee.fssp_check,
    },
    {
      label: "Факт экстремизма или терроризма",
    },
    {
      label: "Исполнительные производства",
      status: employee.enforcement_proceedings_check_status,
      result: employee.enforcement_proceedings_check,
    },
    {
      label: "Ограничение в участие ЮЛ",
      status: employee.ul_restriction_check_status,
      result: employee.ul_restriction_check,
    },
    {
      label: "Дисквалификация",
      status: employee.disqualified_check_status,
      result: employee.disqualified_check,
    },
    {
      label: "Бакронтство",
      status: employee.bank_ruptcy_check_status,
      result: employee.bank_ruptcy_check,
    },
    {
      label: "Розыск МВД",
      status: employee.wanted_check_status,
      result: employee.wanted_check,
    },
  ];

  return (
    <>
      <button type="button" onClick={showModal}>
        Смотреть сводку
      </button>
      <Modal
        destroyOnClose
        title="Сводка данных о сотруднике"
        open={isModalVisible}
        onCancel={hideModal}
        footer={null}
        width={620}
      >
        {data.map((item) => (
          <div className={s.summaryContainer} key={item.label}>
            <Text>{renderIcon(item.status)}</Text>
            <Text variant="body-5" color="secondary">
              {item.label}
            </Text>
            <Text variant="body-5">
              {renderResult(item.result, item.status)}
            </Text>
          </div>
        ))}
      </Modal>
    </>
  );
};
