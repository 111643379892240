import { RetrieveStatus } from "@entities/RetrieveStatus/RetrieveStatus";
import {
  calculateReportStatus,
  FullDossier,
  SearchCard,
} from "@femida1/gui_web_core";
import type { FC } from "react";
import { useMemo } from "react";
import { Flex, useNotification } from "@femida1/uikit";
import type {
  ReportCommonProps,
  ReportsPhotoByFaceRetrieveResponse,
  EMultiQueryKeys,
} from "@femida1/schemas";
import { ReportStatus } from "@femida1/schemas";
import { MultiQueryReportsByType } from "@entities/MultiQueryReportsByType/MultiQueryReportsByType";
import { NoteModalFullDossier } from "@components/NoteModal/NoteModalFullDossier";
import { NoteModalMultiQueryFullDossier } from "@components/NoteModal/NoteModalMultiQueryFullDossier";
import { SearchByFound } from "@components/SearchByFound/SearchByFound";
import { usePdfGenerateHandlers } from "@app/api/pdf/usePdfGenerateHandlers";
import s from "./RetrievePhotoByFace.module.scss";

export type RetrievePhotoByFaceProps = {
  report: ReportsPhotoByFaceRetrieveResponse;
} & ReportCommonProps;

export const RetrievePhotoByFace: FC<RetrievePhotoByFaceProps> = ({
  report,
  type,
  reportId,
}) => {
  const isMultiQueryReport = useMemo(
    () => report.multi_query?.result !== null,
    [report.multi_query?.result],
  );

  const [api] = useNotification();

  const { onReportLoad, onReportPrint, isLoadingGeneratePdf } =
    usePdfGenerateHandlers({
      api,
      type,
      reportId,
    });

  const faces = report?.photo_response?.result?.faces;

  return (
    <RetrieveStatus
      status={
        calculateReportStatus({
          sourceStatuses: [report?.photo_response?.status],
          found: report?.status === ReportStatus.Finished,
        }) || report?.status
      }
    >
      <Flex className={s.container} vertical gap={32}>
        <FullDossier
          isMultiQueryReport={isMultiQueryReport}
          phones={report?.person?.person_info?.phones}
          snilses={report?.person?.person_info?.snilses}
          requestDescribe={report.request_describe}
          inns={report?.person?.person_info?.inns}
          passports={report?.person?.person_info?.passports}
          citizens={report?.person?.person_info?.citizens}
          emails={report?.person?.person_info?.emails}
          persons={report?.person?.person_info?.persons}
          autos={report?.person?.person_info?.autos}
          note={report.note}
          type={type}
          status={report?.person?.status}
          isLoadingGeneratePdf={isLoadingGeneratePdf}
          isShowIncompleteData={report.person?.status !== ReportStatus.Finished}
          onReportLoad={onReportLoad}
          onReportPrint={onReportPrint}
          renderNoteModal={() => (
            <NoteModalFullDossier reportId={reportId} type={type} />
          )}
          renderMultiQueryNoteModal={({ note }) => (
            <NoteModalMultiQueryFullDossier
              reportId={reportId}
              type={type}
              note={note}
            />
          )}
          renderSearchByFound={(props) => <SearchByFound {...props} />}
        />
        <SearchCard
          status={report?.photo_response?.status}
          faces={faces}
          sourcePhoto={report?.request_describe}
        />
      </Flex>
      {report.multi_query &&
        isMultiQueryReport &&
        Object.entries(report.multi_query.result).map(([key, value]) => {
          const multiQueryRequests = Object.values(value);
          if (!multiQueryRequests.length) return null;

          return (
            <MultiQueryReportsByType
              key={key}
              multiQueryRequests={value}
              multiQueryKey={key as EMultiQueryKeys}
            />
          );
        })}
    </RetrieveStatus>
  );
};
