import type { ReportType } from "@femida1/schemas";

/**
 * TODO move to schemas
 */
export enum NodeApiPdfGenerateMode {
  Html = "html",
  Pdf = "pdf",
}

export interface NodeApiPdfGenerateRequest {
  filename: string;
  footer: {
    description?: string;
  };
  mode: NodeApiPdfGenerateMode;
  report_id: string;
  report_type: ReportType;
}

export type NodeApiPdfGenerateResponse = Uint8Array<ArrayBufferLike>;
