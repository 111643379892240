import { BackTop, Layout } from "antd";
import {
  Flex,
  ModalMessageProvider,
  useClickOutside,
  usePageSize,
} from "@femida1/uikit";
import type { FC, ReactNode } from "react";
import { useLayoutEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { IoIosArrowUp } from "react-icons/io";
import { SubReportsVisibilityProvider } from "@pages/Root/components/SubReportsVisibilityContextProps/SubReportsVisibilityContextProps";
import s from "./MainLayout.module.scss";
import { MainLayoutHeader } from "./components/MainLayoutHeader/MainLayoutHeader";
import { MainLayoutAside } from "./components/MainLayoutAside/MainLayoutAside";
import { MainLayoutUser } from "./components/MainLayoutUser/MainLayoutUser";
import { ReportLayoutAside } from "./components/ReportLayoutAside/ReportLayoutAside";
import { MainLayoutAlerts } from "./components/MainLayoutAlerts/MainLayoutAlerts";

const { Content } = Layout;

interface MainLayoutProps {
  children: ReactNode;
}

export const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const { isMobile, isUntilTabletVertical } = usePageSize();

  const [leftCollapsed, setLeftCollapsed] = useState(false);
  const [rightCollapsed, setRightCollapsed] = useState(true);
  const [alertsCollapsed, setAlertsCollapsed] = useState(true);

  const userButtonRef = useRef<HTMLButtonElement>(null);
  const alertsButtonRef = useRef<HTMLButtonElement>(null);

  const contentRef = useRef<HTMLDivElement>(null);

  const { pathname } = useLocation();

  const { setWrapperRef: setRightWrapperRef } = useClickOutside({
    clickOutsideFn: (event: MouseEvent) => {
      if (!userButtonRef.current?.contains(event.target as Node)) {
        setRightCollapsed(true);
      }
    },
  });

  const { setWrapperRef: setAlertsWrapperRef } = useClickOutside({
    clickOutsideFn: (event) => {
      if (!alertsButtonRef.current?.contains(event.target as Node)) {
        setAlertsCollapsed(true);
      }
    },
  });

  useLayoutEffect(() => {
    contentRef.current?.scrollTo(0, 0);
  }, [pathname]);

  const Aside = pathname.includes("report")
    ? ReportLayoutAside
    : MainLayoutAside;

  return (
    <ModalMessageProvider>
      <Layout>
        <MainLayoutHeader
          userButtonRef={userButtonRef}
          alertsButtonRef={alertsButtonRef}
          leftCollapsed={leftCollapsed}
          onLeftCollapse={setLeftCollapsed}
          rightCollapsed={rightCollapsed}
          onRightCollapse={setRightCollapsed}
          alertsCollapsed={alertsCollapsed}
          onAlertsCollapsed={setAlertsCollapsed}
          isTablet={isUntilTabletVertical}
          isMobile={isMobile}
        />
        <SubReportsVisibilityProvider>
          <Layout className={s.mainLayout}>
            <Aside
              collapsed={leftCollapsed}
              onCollapse={setLeftCollapsed}
              isTablet={isUntilTabletVertical}
              siderClassName={s.aside}
            />
            <Content className={s.content} ref={contentRef}>
              {pathname.includes("report") && (
                <BackTop target={() => contentRef.current as HTMLElement}>
                  <Flex
                    justify="center"
                    align="center"
                    className={s.backTopIcon}
                  >
                    <IoIosArrowUp size={30} />
                  </Flex>
                </BackTop>
              )}
              {children}
            </Content>
            <MainLayoutAlerts
              collapsed={alertsCollapsed}
              onCollapse={setAlertsCollapsed}
              setWrapperRef={setAlertsWrapperRef}
              siderClassName={s.aside}
              isTablet={isUntilTabletVertical}
            />
            <MainLayoutUser
              collapsed={rightCollapsed}
              onCollapse={setRightCollapsed}
              setWrapperRef={setRightWrapperRef}
              siderClassName={s.aside}
              isTablet={isUntilTabletVertical}
            />
          </Layout>
        </SubReportsVisibilityProvider>
      </Layout>
    </ModalMessageProvider>
  );
};
