import type { FC, PropsWithChildren } from "react";
import { useLayoutEffect, useMemo, useState } from "react";
import type { Theme } from "@femida1/gui_web_core";
import { AppThemeContext } from "./AppThemeContext";
import type { UseAppThemeReturn } from "./types";
import { getUserAppTheme } from "./utils/getUserAppTheme";
import { saveUserAppTheme } from "./utils/saveUserAppTheme";

export const AppThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [theme, setTheme] = useState<Theme>(() => getUserAppTheme());

  useLayoutEffect(() => {
    document.documentElement.setAttribute("data-theme-mode", theme);

    saveUserAppTheme(theme);
  }, [theme]);

  const value = useMemo<UseAppThemeReturn>(
    () => [theme, setTheme],
    [theme, setTheme],
  );

  return (
    <AppThemeContext.Provider value={value}>
      {children}
    </AppThemeContext.Provider>
  );
};
