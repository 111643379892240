import { useParams } from "react-router-dom";
import { useReportsFullCreditRatingRetrieve } from "@femida1/gui_web_core";
import { Skeleton } from "antd";
import type { ReportCommonProps } from "@femida1/schemas";
import { RetrieveFullCreditRating } from "@entities/RetrieveFullCreditRating/RetrieveFullCreditRating";

export const SearchFullCreditRatingWidget = ({
  type,
}: Omit<ReportCommonProps, "reportId">) => {
  const { reportId = "" } = useParams();

  const { data: creditRatingReport, isLoading } =
    useReportsFullCreditRatingRetrieve({
      id: reportId,
    });

  return (
    <Skeleton loading={isLoading}>
      {creditRatingReport && (
        <RetrieveFullCreditRating
          report={creditRatingReport}
          type={type}
          reportId={reportId}
        />
      )}
    </Skeleton>
  );
};
