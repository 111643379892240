import { VscSettings } from "react-icons/vsc";
import { PageHeader } from "@app/layouts/PageHeader/PageHeader";
import { usePageSize, Button, Text } from "@femida1/uikit";
import classNames from "classnames";
import type { FC } from "react";
import { AppRoute } from "@app/routes/constants";
import s from "./Header.module.scss";

interface HeaderProps {
  onOpenSettings: () => void;
  isFiltersActive: boolean;
}

export const Header: FC<HeaderProps> = ({
  onOpenSettings,
  isFiltersActive,
}) => {
  const { isMobile } = usePageSize();

  return (
    <PageHeader
      title="Аналитика"
      mapPathToTitle={{ [AppRoute.Analytics]: "Аналитика" }}
      paths={[AppRoute.Analytics]}
      rightElement={
        <Button
          type={isFiltersActive ? "outlined" : "secondary"}
          size="m"
          className={classNames(s.container__button, {
            [s.container__button_only_icon]: isMobile,
          })}
          onClick={onOpenSettings}
        >
          <Text
            className={s.container__button}
            color={isFiltersActive ? "accent" : "secondary"}
          >
            <VscSettings className={s.container__button_icon} />{" "}
            {isMobile ? undefined : "Настроить временной отрезок"}
          </Text>
        </Button>
      }
    />
  );
};
