import { Skeleton } from "antd";
import { useParams } from "react-router-dom";
import { useReportsPhoneRetrieve } from "@femida1/gui_web_core";
import { SearchPhone } from "@entities/SearchPhone/SearchPhone";
import type { ReportCommonProps } from "@femida1/schemas";

interface ISearchPhoneWidgetProps extends Omit<ReportCommonProps, "reportId"> {
  subReportId?: string;
}

export const SearchPhoneWidget = ({
  type,
  subReportId,
}: ISearchPhoneWidgetProps) => {
  const { reportId: paramsReportId = "" } = useParams();

  const resultReportId = subReportId ?? paramsReportId;

  const { data: phoneReport, isLoading } = useReportsPhoneRetrieve({
    id: resultReportId,
  });

  return (
    <Skeleton loading={isLoading}>
      {phoneReport && (
        <SearchPhone
          isFullDossierReport={!!subReportId}
          isSubReport={!!subReportId}
          report={phoneReport}
          type={type}
          reportId={resultReportId}
        />
      )}
    </Skeleton>
  );
};
