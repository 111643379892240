import type { FC } from "react";
import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { FiltersModal, Flex, Radio, Text } from "@femida1/uikit";
import { OrderType } from "@femida1/schemas";
import { MdFilterList } from "react-icons/md";
import { historyListSortingRules, historyListSortingRulesOrder } from "./types";
import { HistoryListSettingsStorage } from "../HistoryListFiltersStorage";
import { EHistoryListSetting } from "../../types";

interface IHistoryListSortingModalProps {
  setActiveSortingRule: (selectedSortingRule: OrderType) => void;
  activeSortingRule: OrderType;
}

export const HistoryListSortingModal: FC<IHistoryListSortingModalProps> = ({
  setActiveSortingRule,
  activeSortingRule,
}) => {
  const [selectedSortingRule, setSelectedSortingRule] =
    useState<OrderType>(activeSortingRule);
  const isSortingActive = useMemo(
    () => activeSortingRule !== OrderType.DESCEND,
    [activeSortingRule],
  );

  useLayoutEffect(() => {
    setSelectedSortingRule(activeSortingRule);
  }, [activeSortingRule]);

  const handleApplySorting = useCallback(() => {
    setActiveSortingRule(selectedSortingRule);
    HistoryListSettingsStorage.set(
      EHistoryListSetting.SORTING,
      selectedSortingRule,
    );
  }, [selectedSortingRule, setActiveSortingRule]);

  const handleClearSorting = useCallback(() => {
    setActiveSortingRule(OrderType.DESCEND);
    HistoryListSettingsStorage.set(
      EHistoryListSetting.SORTING,
      OrderType.DESCEND,
    );
  }, [setActiveSortingRule]);

  return (
    <FiltersModal
      modalTitle="Сортировка"
      title="Сортировка"
      icon={<MdFilterList />}
      isActive={isSortingActive}
      onApply={handleApplySorting}
      onCancel={() => setSelectedSortingRule(activeSortingRule)}
      onClearFilters={handleClearSorting}
    >
      <Flex vertical gap={12}>
        <Text variant="subheader-4">Показывать:</Text>
        {historyListSortingRulesOrder.map((sortingRule: OrderType) => (
          <Radio
            key={sortingRule}
            checked={selectedSortingRule === sortingRule}
            onUpdate={(checked) => {
              if (checked) setSelectedSortingRule(sortingRule);
            }}
          >
            <Text variant="body-4">{historyListSortingRules[sortingRule]}</Text>
          </Radio>
        ))}
      </Flex>
    </FiltersModal>
  );
};
