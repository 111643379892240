import { useParams } from "react-router-dom";
import { useReportsSnilsRetrieve } from "@femida1/gui_web_core";
import { SearchFull } from "@entities/SearchFull/SearchFull";
import { Skeleton } from "antd";
import type { ReportCommonProps } from "@femida1/schemas";

export const SearchSnilsWidget = ({
  type,
}: Omit<ReportCommonProps, "reportId">) => {
  const { reportId = "" } = useParams();

  const { data: snilsReport, isLoading } = useReportsSnilsRetrieve({
    id: reportId,
  });

  return (
    <Skeleton loading={isLoading}>
      {snilsReport && (
        <SearchFull report={snilsReport} type={type} reportId={reportId} />
      )}
    </Skeleton>
  );
};
