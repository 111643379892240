import { ReportStatus } from "@femida1/schemas";
import {
  Text,
  Loading,
  Tooltip,
  Cross,
  Finished,
  NotFound,
} from "@femida1/uikit";
import s from "./Status.module.scss";

interface StatusProps {
  reportStatus?: ReportStatus | null;
}

export const Status = ({ reportStatus }: StatusProps) => (
  <div className={s.container}>
    {reportStatus === ReportStatus.InProgress && (
      <Tooltip
        title={
          <Text color="white">Отчет готов, некоторые данные догружаются</Text>
        }
      >
        <span>
          <Loading />
        </span>
      </Tooltip>
    )}
    {reportStatus === ReportStatus.Canceled && (
      <Tooltip title={<Text color="white">Отчет не сформирован</Text>}>
        <Cross className={s.container__icon} />
      </Tooltip>
    )}
    {reportStatus === ReportStatus.Finished && (
      <Tooltip title={<Text color="white">Отчет готов</Text>}>
        <Finished className={s.container__icon} />
      </Tooltip>
    )}
    {reportStatus === ReportStatus.NoData && (
      <Tooltip title={<Text color="white">Информация не найдена</Text>}>
        <NotFound className={s.container__icon} />
      </Tooltip>
    )}
  </div>
);
