import type { FC } from "react";
import type React from "react";
import { ModalView, Drawer, usePageSize } from "@femida1/uikit";
import s from "./AddTicketWrapper.module.scss";

interface AddTicketWrapperProps {
  isModalOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export const AddTicketWrapper: FC<AddTicketWrapperProps> = ({
  isModalOpen,
  onClose,
  children,
}) => {
  const { isMobile } = usePageSize();
  return isMobile ? (
    <Drawer
      placement="bottom"
      visible={isModalOpen}
      title="Создание тикета"
      closable
      onClose={onClose}
      height="fit-content"
    >
      <div className={s.drawer}>{children}</div>
    </Drawer>
  ) : (
    <ModalView
      isModalOpen={isModalOpen}
      onClose={onClose}
      closable
      title="Создание тикета"
    >
      <div className={s.body}>{children}</div>
    </ModalView>
  );
};
