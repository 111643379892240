import type { FC } from "react";
import { useCallback } from "react";
import type { CheckboxChangeEvent } from "@femida1/uikit";
import { Checkbox } from "@femida1/uikit";
import type { Group } from "@femida1/schemas";
import { useGroupsSetNonRussianApiAllowed } from "@femida1/gui_web_core";

interface UserNonRussianIpAllowedProps {
  group: Group;
}

export const UserNonRussianIpAllowed: FC<UserNonRussianIpAllowedProps> = ({
  group,
}) => {
  const { group_id, non_russian_ip_allowed } = group;

  const {
    mutateAsync: setNonRussianApiAllowed,
    isPending: isGroupsSetNonRussianApiAllowedPending,
  } = useGroupsSetNonRussianApiAllowed();

  const onChange = useCallback(
    async (e: CheckboxChangeEvent) => {
      if (e.target.checked !== non_russian_ip_allowed) {
        await setNonRussianApiAllowed({
          group_id,
          non_russian_ip_allowed: e.target.checked,
        });
      }
    },
    [setNonRussianApiAllowed, non_russian_ip_allowed, group_id],
  );

  return (
    <Checkbox
      checked={non_russian_ip_allowed}
      onChange={onChange}
      disabled={isGroupsSetNonRussianApiAllowedPending}
    >
      Разрешить вход с любой страны
    </Checkbox>
  );
};
