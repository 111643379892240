import { MAIN_TAB_QUERY, MainTab } from "@pages/Main/constants";
import type { MenuSubItem } from "@app/layouts/MainLayout/components/types";
import { AppRoute } from "@app/routes/constants";
import { mapMainTabToLabel } from "@utils/tabs/mapMainTabToLabel";

export const mapMainTabItems: Record<MainTab, MenuSubItem> = {
  [MainTab.FullDossier]: {
    href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.FullDossier}`,
    key: MainTab.FullDossier,
    meta: "полное досье,dossier,full dossier",
    title: mapMainTabToLabel[MainTab.FullDossier],
  },
  [MainTab.FullDossierLight]: {
    href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.FullDossierLight}`,
    key: MainTab.FullDossierLight,
    meta: "полное досье,dossier,full dossier",
    title: mapMainTabToLabel[MainTab.FullDossierLight],
  },
  [MainTab.Fio]: {
    href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Fio}`,
    key: MainTab.Fio,
    meta: "по фио,by fio",
    title: mapMainTabToLabel[MainTab.Fio],
  },
  [MainTab.Phone]: {
    href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Phone}`,
    key: MainTab.Phone,
    meta: "по телефону,by phone",
    title: mapMainTabToLabel[MainTab.Phone],
  },
  [MainTab.PhoneLight]: {
    href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.PhoneLight}`,
    key: MainTab.PhoneLight,
    meta: "по телефону,by phone",
    title: mapMainTabToLabel[MainTab.PhoneLight],
  },
  [MainTab.Address]: {
    href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Address}`,
    key: MainTab.Address,
    meta: "по адресу,by address",
    title: mapMainTabToLabel[MainTab.Address],
  },
  [MainTab.InnOrSnils]: {
    href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.InnOrSnils}`,
    key: MainTab.InnOrSnils,
    meta: "по ИНН или СНИЛС,по СНИЛС или ИНН,by inn or snils,by snils or inn",
    title: mapMainTabToLabel[MainTab.InnOrSnils],
  },
  [MainTab.Passport]: {
    href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Passport}`,
    key: MainTab.Passport,
    meta: "по паспорту,by passport",
    title: mapMainTabToLabel[MainTab.Passport],
  },
  [MainTab.Auto]: {
    href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Auto}`,
    key: MainTab.Auto,
    meta: "по номеру авто,by auto number",
    title: mapMainTabToLabel[MainTab.Auto],
  },
  [MainTab.DriverLicense]: {
    href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.DriverLicense}`,
    key: MainTab.DriverLicense,
    meta: "по ВУ,by driver license",
    title: mapMainTabToLabel[MainTab.DriverLicense],
  },
  [MainTab.Email]: {
    href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Email}`,
    key: MainTab.Email,
    meta: "по email,by email",
    title: mapMainTabToLabel[MainTab.Email],
  },
  [MainTab.EmailLight]: {
    href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.EmailLight}`,
    key: MainTab.EmailLight,
    meta: "по email,by email",
    title: mapMainTabToLabel[MainTab.EmailLight],
  },
  [MainTab.Company]: {
    href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Company}`,
    key: MainTab.Company,
    meta: "по юр. лицам,by company",
    title: mapMainTabToLabel[MainTab.Company],
  },
  [MainTab.Photo]: {
    href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Photo}`,
    key: MainTab.Photo,
    meta: "по фото,by photo",
    title: mapMainTabToLabel[MainTab.Photo],
  },
  [MainTab.PhotoLight]: {
    href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.PhotoLight}`,
    key: MainTab.PhotoLight,
    meta: "по фото,by photo",
    title: mapMainTabToLabel[MainTab.PhotoLight],
  },
  [MainTab.CreditRating]: {
    href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.CreditRating}`,
    key: MainTab.CreditRating,
    meta: "кредитный рейтинг,credit rating",
    title: mapMainTabToLabel[MainTab.CreditRating],
  },
  [MainTab.Scoring]: {
    href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Scoring}`,
    key: MainTab.Scoring,
    meta: "скоринг,scoring",
    title: mapMainTabToLabel[MainTab.Scoring],
  },
  [MainTab.Vk]: {
    href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Vk}`,
    key: MainTab.Vk,
    meta: "вк,vk",
    title: mapMainTabToLabel[MainTab.Vk],
  },
  [MainTab.Info]: {
    href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Info}`,
    key: MainTab.Info,
    meta: "по ключевому слову,by keyword",
    title: mapMainTabToLabel[MainTab.Info],
  },
  [MainTab.Telegram]: {
    href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Telegram}`,
    key: MainTab.Telegram,
    meta: "по telegram,by telegram",
    title: mapMainTabToLabel[MainTab.Telegram],
  },
};
