import { useRef } from "react";
import { Flex, Button } from "@femida1/uikit";
import type { DropZoneFile } from "@femida1/uikit";
import { useAppFeatures } from "@app/providers";
import { SOPDModal } from "../SOPDModal/SOPDModal";
import s from "./SubmitButton.module.scss";

interface SubmitButtonProps {
  disabled?: boolean;
  fileList?: DropZoneFile[];
  onFileListChange?: (fileList: DropZoneFile[]) => void;
  onSubmit?: () => void;
}

export const SubmitButton = ({
  disabled,
  fileList,
  onFileListChange,
  onSubmit,
}: SubmitButtonProps) => {
  const buttonRef = useRef<HTMLElement>(null);

  const { tabMainSopdEnabled } = useAppFeatures();

  return (
    <Flex
      vertical
      gap={12}
      ref={buttonRef}
      className={s.button_submit_container}
    >
      <Button
        onClick={onSubmit}
        className={s.button_submit}
        disabled={disabled}
        htmlType="submit"
      >
        Проверить
      </Button>
      {tabMainSopdEnabled && onFileListChange ? (
        <SOPDModal
          fileList={fileList || []}
          onFileListChange={onFileListChange}
        />
      ) : null}
    </Flex>
  );
};
