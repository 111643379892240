import { useParams } from "react-router-dom";
import { useReportsPassportRetrieve } from "@femida1/gui_web_core";
import { SearchFull } from "@entities/SearchFull/SearchFull";
import { Skeleton } from "antd";
import type { ReportCommonProps } from "@femida1/schemas";

interface ISearchPassportWidgetProps
  extends Omit<ReportCommonProps, "reportId"> {
  subReportId?: string;
}

export const SearchPassportWidget = ({
  type,
  subReportId,
}: ISearchPassportWidgetProps) => {
  const { reportId: paramsReportId = "" } = useParams();

  const resultReportId = subReportId ?? paramsReportId;

  const { data: snilsReport, isLoading } = useReportsPassportRetrieve({
    id: resultReportId,
  });

  return (
    <Skeleton loading={isLoading}>
      {snilsReport && (
        <SearchFull
          isFullDossierReport={!!subReportId}
          isSubReport={!!subReportId}
          report={snilsReport}
          type={type}
          reportId={resultReportId}
        />
      )}
    </Skeleton>
  );
};
