import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Flex, Skeleton } from "antd";
import type { FC } from "react";
import { useCallback, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { PiWarningBold } from "react-icons/pi";
import {
  Button,
  Input,
  Text,
  Drawer,
  useModalMessage,
  Toggle,
  useNotification,
  usePageSize,
  useBooleanState,
} from "@femida1/uikit";
import { validationMessage, validationRegex } from "@utils/constants";
import * as yup from "yup";
import type {
  Group,
  GroupsAddMemberRequest,
  GroupsGrantUserPermissionsRequest,
} from "@femida1/schemas";
import { Permission } from "@femida1/schemas";
import {
  useGroupsAddMember,
  useUsersEdit,
  useUserChangePasswordMutation,
  useUsersSetQuotaMutation,
  femidaQueryClient,
  useGroupsGetPermissions,
  useGroupsGrantUserPermissions,
} from "@femida1/gui_web_core";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { AppRoute } from "@app/routes/constants";
import { IoIosWarning } from "react-icons/io";
import { generatePassword } from "@utils/generate/password";
import { transformEmptyString } from "@utils/transformers/transformEmptyString";
import { handleBackendErrors } from "@utils/notifications/handleBackendErrors";
import s from "./AccessesSettings.module.scss";
import { AccessesAddInvite } from "./components/AccessesAddInvite/AccessesAddInvite";
import { AccessesSettingsPermissionReports } from "./components/AccessesSettingsPermissionReports/AccessesSettingsPermissionReports";
import type { AccessesSettingsFormValues } from "./types";
import { CommonModal } from "../CommonModal/CommonModal";

const schema = yup
  .object<AccessesSettingsFormValues>()
  .shape({
    group_id: yup
      .number()
      .transform(transformEmptyString)
      .required(validationMessage.Required),
    name: yup.string().required(validationMessage.Required),
    login: yup.string().required(validationMessage.Required),
    password: yup.string().when("$user", {
      is: (user: boolean) => !user,
      then: (createPasswordSchema) =>
        createPasswordSchema
          .min(8, validationMessage.MinLen({ min: 8 }))
          .matches(validationRegex.password, validationMessage.WrongPassword)
          .required(validationMessage.Required),
      otherwise: (editPasswordSchema) =>
        editPasswordSchema.when("password", {
          is: (value: string) => Boolean(value),
          then: (passwordSchema) =>
            passwordSchema
              .min(8, validationMessage.MinLen({ min: 8 }))
              .matches(
                validationRegex.password,
                validationMessage.WrongPassword,
              ),
        }),
    }),
    passwordConfirm: yup.string().when("$user", {
      is: (user: boolean) => !user,
      then: (passwordConfirmSchema) =>
        passwordConfirmSchema
          .oneOf([yup.ref("password"), ""], "Пароль должен совпасть")
          .required(validationMessage.Required),
      otherwise: (editPasswordConfirmSchema) =>
        editPasswordConfirmSchema.when("password", {
          is: (value: string) => Boolean(value),
          then: (passwordConfirmSchema) =>
            passwordConfirmSchema.oneOf(
              [yup.ref("password"), ""],
              "Пароль должен совпасть",
            ),
        }),
    }),
    request_quota: yup
      .number()
      .transform(transformEmptyString)
      .when("$isRequestQuotaUnlimited", {
        is: (isRequestQuotaUnlimited: boolean) => !isRequestQuotaUnlimited,
        then: (requestQuotaSchema) =>
          requestQuotaSchema.required(validationMessage.Required),
      }),
    ...Object.fromEntries(
      Object.values(Permission).map((permission) => [
        permission,
        yup.boolean().optional(),
      ]),
    ),
    expires_at: yup.string().required(validationMessage.Required),
  })
  .required();

// maybe delete it
const permissionsSet = new Set<string>(Object.values(Permission));

const requestText = <TParams, TResponse>({
  request,
  params,
  field,
  api,
}: {
  request: (variables: TParams) => Promise<TResponse>;
  params: TParams;
  field: string;
  api: ReturnType<typeof useNotification>[0];
}) =>
  request(params)
    .then(() =>
      api.success({
        key: field,
        message: `Поле ${field} успешно изменено`,
      }),
    )
    .catch(() => {
      api.error({
        key: field,
        message: `Не удалось изменить ${field}`,
      });
    });

interface AccessesSettingsViewProps {
  group: Group;
  permissions: Permission[];
}

const AccessesSettingsView: FC<AccessesSettingsViewProps> = ({
  group,
  permissions,
}) => {
  const { user_id } = useParams();
  const user = useMemo(
    () =>
      group?.users.find((groupUser) => groupUser.user_id === Number(user_id)),
    [group?.users, user_id],
  );
  const { state: isRequestQuotaUnlimited, toggle: setRequestQuotaUnlimited } =
    useBooleanState(user && !user.request_quota);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState,
    trigger,
    clearErrors,
  } = useForm<AccessesSettingsFormValues>({
    defaultValues: {
      group_id: group.group_id,
      name: user?.name || "",
      login: user?.login || "",
      password: "",
      passwordConfirm: "",
      request_quota: user?.request_quota,
      expires_at: "01.01.9999 00:00:00",
      ...Object.fromEntries(
        permissions.map((permission) => [
          permission,
          user?.permissions.includes(permission),
        ]),
      ),
    },
    mode: "onChange",
    resolver: yupResolver(schema),
    context: { user, isRequestQuotaUnlimited },
  });
  const { isValid, errors } = formState;
  const { isMobile } = usePageSize();

  const login = watch("login");
  const password = watch("password");

  useEffect(() => {
    if (password) trigger("passwordConfirm");
    if (isRequestQuotaUnlimited) {
      clearErrors("request_quota");
    } else {
      trigger("request_quota");
    }
  }, [clearErrors, isRequestQuotaUnlimited, password, trigger]);

  const { mutateAsync: addMember } = useGroupsAddMember();
  const { mutateAsync: addPermissions } = useGroupsGrantUserPermissions();
  const { mutateAsync: editUser } = useUsersEdit();
  const { mutateAsync: grantUserPermissions } = useGroupsGrantUserPermissions();
  const { mutateAsync: changeUserPassword } = useUserChangePasswordMutation();
  const { mutateAsync: changeUserQuota } = useUsersSetQuotaMutation();

  const onGeneratePassword = useCallback(async () => {
    const generatedPassword = await generatePassword();

    setValue("password", generatedPassword);
    setValue("passwordConfirm", generatedPassword);

    await trigger().catch(() => {});
  }, [setValue, trigger]);

  const [api] = useNotification();

  const navigate = useNavigate();
  const { openModal, closeModal } = useModalMessage();

  const onCancel = useCallback(() => {
    openModal({
      children: (
        <CommonModal
          type="normal"
          title="Вы уверены, что хотите выйти без сохранения изменений?"
          icon={<IoIosWarning size={120} />}
          iconSize={120}
          iconWrapperClassName={s.form__icon}
          buttonsVertical
          primaryButtonProps={{
            children: "Да, продолжить",
            type: "primary",
            onClick: async () => {
              navigate(AppRoute.Accesses);
            },
            size: "l",
          }}
          secondaryButtonProps={{
            children: "Отменить",
            type: "flat_secondary",
            onClick: closeModal,
            size: "l",
          }}
        />
      ),
    });
  }, [navigate, openModal, closeModal]);

  const onSubmit = useCallback(
    async (values: AccessesSettingsFormValues) => {
      if (user) {
        const userId = user.user_id;
        const initialPermissions = Object.entries(values)
          .filter((el): el is [key: Permission, value: true] =>
            Boolean(permissionsSet.has(el[0]) && el[1]),
          )
          .map(([permission]) => permission);

        if (
          !values.password &&
          user.name === values.name &&
          (isRequestQuotaUnlimited ||
            user.request_quota === values.request_quota) &&
          JSON.stringify(initialPermissions.sort()) ===
            JSON.stringify(user.permissions.sort())
        ) {
          api.success({
            key: "no-changes",
            message: "Изменений не обнаружено",
          });
          return;
        }
        if (values.password) {
          await requestText({
            request: changeUserPassword,
            params: { user_id: userId, password: values.password },
            api,
            field: "Пароль",
          });
          femidaQueryClient.invalidateQueries({ queryKey: ["groups"] });
        }
        if (user.name !== values.name) {
          await requestText({
            request: editUser,
            params: { user_id: userId, name: values.name },
            api,
            field: "ФИО",
          });
          femidaQueryClient.invalidateQueries({ queryKey: ["groups"] });
        }
        if (
          !isRequestQuotaUnlimited &&
          values.request_quota &&
          user.request_quota !== values.request_quota
        ) {
          await requestText({
            request: changeUserQuota,
            params: {
              user_id: user.user_id,
              request_quota: values.request_quota,
            },
            api,
            field: "Лимит запросов",
          });
          femidaQueryClient.invalidateQueries({ queryKey: ["groups"] });
        }
        if (
          JSON.stringify(initialPermissions.sort()) !==
          JSON.stringify(user.permissions.sort())
        ) {
          await requestText({
            request: grantUserPermissions,
            params: {
              group_id: values.group_id,
              user_id: userId,
              permissions: initialPermissions,
            },
            api,
            field: "Доступы",
          });
        }
        navigate(
          generatePath(AppRoute.Accesses, {
            group_id: String(values.group_id),
          }),
        );
      } else {
        const addMemberRequest: GroupsAddMemberRequest = {
          group_id: values.group_id,
          name: values.name,
          login: values.login,
          password: values.password || undefined,
          request_quota: isRequestQuotaUnlimited
            ? undefined
            : values.request_quota,
          expires_at: values.expires_at,
        };

        addMember(addMemberRequest)
          .then((res) => {
            const grantUserPermissionsRequest: GroupsGrantUserPermissionsRequest =
              {
                group_id: values.group_id,
                user_id: res.user_id,
                permissions: Object.entries(values)
                  .filter((el): el is [key: Permission, value: true] =>
                    Boolean(permissionsSet.has(el[0]) && el[1]),
                  )
                  .map(([permission]) => permission),
              };

            addPermissions(grantUserPermissionsRequest)
              .then(() => {
                api.success({
                  key: "add-permission",
                  message: "Пользователь успешно создан",
                });

                navigate(
                  generatePath(AppRoute.Accesses, {
                    group_id: String(values.group_id),
                  }),
                );
              })
              .catch((error) => {
                handleBackendErrors({
                  api,
                  error,
                });
              });
          })
          .catch((error) => {
            handleBackendErrors({
              api,
              error,
            });
          });
      }
    },
    [
      addMember,
      addPermissions,
      api,
      changeUserPassword,
      changeUserQuota,
      editUser,
      grantUserPermissions,
      isRequestQuotaUnlimited,
      navigate,
      user,
    ],
  );

  return (
    <Flex vertical gap={60}>
      <Flex
        vertical
        gap={32}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* {hierarchyEnabled && (
          <>
            <Flex vertical gap={16}>
              <Text variant="label-4">Права доступа:</Text>
            </Flex>
            <Flex vertical gap={16}>
              <Text variant="label-4">Под руководством:</Text>
              <Flex gap={32}>
                <div className={s.form__right}>
                  <Controller
                    name="login"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Input
                        {...field}
                        placeholder="Выбрать"
                        required
                        validate={fieldState}
                      />
                    )}
                  />
                </div>
                <Alert
                  icon={<PiWarningBold size={16} />}
                  showIcon
                  type="warning"
                  message="Оставьте поле не заполненным, если руководитель для этого доступа не нужен"
                />
              </Flex>
            </Flex>
          </>
        )} */}
        <Flex vertical gap={16}>
          <Text variant="label-4">Данные сотрудника:</Text>
          <Flex gap={32}>
            <div className={s.form__right}>
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState }) => (
                  <Input
                    {...field}
                    placeholder="Иванов Иван Иванович"
                    required
                    validate={fieldState}
                  />
                )}
              />
            </div>
          </Flex>
        </Flex>
        <Flex vertical gap={16}>
          <Text variant="label-4">Логин:</Text>
          <div className={s.form__right}>
            <Controller
              name="login"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  placeholder="Логин"
                  disabled={Boolean(user)}
                  required
                  validate={fieldState}
                />
              )}
            />
          </div>
        </Flex>
        <Flex vertical gap={16}>
          <Text variant="label-4">Пароль:</Text>
          <Flex gap={isMobile ? 16 : 32} className={s.block_with_alert}>
            <div className={s.form__right}>
              <Flex vertical gap={16}>
                <Controller
                  name="password"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Input
                      {...field}
                      placeholder="Пароль"
                      required={!user}
                      validate={fieldState}
                    />
                  )}
                />
                <Controller
                  name="passwordConfirm"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Input
                      {...field}
                      placeholder="Повторите пароль"
                      required={!user}
                      type="password"
                      validate={fieldState}
                    />
                  )}
                />
                <Button
                  className={s.form__secondary_button}
                  size="s"
                  onClick={onGeneratePassword}
                  type="secondary"
                >
                  Сгенерировать случайный пароль
                </Button>
              </Flex>
            </div>
            <Alert
              icon={<PiWarningBold size={16} />}
              showIcon
              type="warning"
              message="Пароль должен содержать *Не менее 8 символов, верхний регистр, числа или символы*"
            />
          </Flex>
        </Flex>
        <Flex vertical gap={16}>
          <Text variant="label-4">Лимит использования проверок в месяц:</Text>
          <Flex
            gap={isMobile ? 16 : 32}
            style={{
              flexDirection: isMobile ? "column" : "row",
            }}
            align={isMobile ? "start" : "center"}
          >
            <div className={s.form__right}>
              <Controller
                name="request_quota"
                control={control}
                render={({ field, fieldState }) => (
                  <Input
                    {...field}
                    placeholder="Введите количество"
                    required
                    disabled={isRequestQuotaUnlimited}
                    validate={fieldState}
                  />
                )}
              />
            </div>
            <Toggle
              onChange={setRequestQuotaUnlimited}
              value={isRequestQuotaUnlimited}
              title="Безлимит"
            />
          </Flex>
        </Flex>
        {permissions.length ? (
          <Flex vertical gap={16}>
            <Text variant="label-4">Доступы к проверкам:</Text>
            <div className={s.form__right}>
              <AccessesSettingsPermissionReports
                permissions={permissions}
                trigger={trigger}
                control={control}
                watch={watch}
                setValue={setValue}
              />
            </div>
          </Flex>
        ) : null}
        {/* {analyticsPermissionsEnabled && (
          <>
            <Flex vertical gap={16}>
              <Text variant="label-4">Доступы к аналитике:</Text>
              <Flex gap={32}>
                <div className={s.form__right}>
                  <Controller
                    name="login"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Input
                        {...field}
                        placeholder="IP адрес"
                        required
                        validate={fieldState}
                      />
                    )}
                  />
                </div>
              </Flex>
            </Flex>
            <Flex vertical gap={16}>
              <Text variant="label-4">Разделы аналитики:</Text>
              <Flex className={s.form__right} vertical gap={8}>
                <Controller
                  name="lol"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Toggle
                      className={s.checkbox_extended}
                      {...field}
                      validate={fieldState}
                      title="Все проверки"
                    />
                  )}
                />
                <Controller
                  name="lol"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Toggle
                      className={s.checkbox_extended}
                      {...field}
                      validate={fieldState}
                      title="По типам проверки"
                    />
                  )}
                />
                <Controller
                  name="lol"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Toggle
                      className={s.checkbox_extended}
                      {...field}
                      validate={fieldState}
                      title="Работа с проверками"
                    />
                  )}
                />
                <Controller
                  name="lol"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Toggle
                      className={s.checkbox_extended}
                      {...field}
                      validate={fieldState}
                      title="Проверки сотрудников"
                    />
                  )}
                />
                <Controller
                  name="lol"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Toggle
                      className={s.checkbox_extended}
                      {...field}
                      validate={fieldState}
                      title="Задачи сотрудников"
                    />
                  )}
                />
              </Flex>
            </Flex>
          </>
        )} */}
        {/* {pagePermissionsEnabled && (
          <Flex vertical gap={16}>
            <Text variant="label-4">Доступ к страница:</Text>
            <Flex className={s.form__right} vertical gap={8}>
              <Controller
                name="lol"
                control={control}
                render={({ field, fieldState }) => (
                  <Toggle
                    className={s.checkbox_extended}
                    {...field}
                    validate={fieldState}
                    title="Мониторинг сотрудников"
                  />
                )}
              />
            </Flex>
          </Flex>
        )} */}
      </Flex>
      <AccessesAddInvite
        login={errors.login ? "" : login}
        password={errors.password ? "" : password}
      />
      <Drawer
        placement="bottom"
        visible
        title={null}
        closable={false}
        height={isMobile ? "146" : "76"}
        mask={false}
      >
        <Flex
          gap={isMobile ? 16 : 32}
          justify="flex-end"
          className={s.form__drawer}
        >
          <Button type="flat_secondary" onClick={onCancel}>
            Отменить
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid}
          >
            {user ? "Сохранить" : "Создать"}
          </Button>
        </Flex>
      </Drawer>
    </Flex>
  );
};

interface AccessesSettingsProps {
  group: Group;
}

export const AccessesSettings: FC<AccessesSettingsProps> = ({ group }) => {
  const {
    data: permissionsResponse,
    error: permissionsError,
    isSuccess: isPermissionsSuccess,
    isLoading: isPermissionsLoading,
  } = useGroupsGetPermissions({ group_id: group.group_id });
  /**
   * Empty string means that group members already have superuser permissions
   */
  const permissions = permissionsResponse
    ? permissionsResponse.filter(Boolean)
    : [];

  const [api] = useNotification();

  useEffect(() => {
    if (permissionsError) {
      api.error({
        key: "list-permission",
        message: "Не удалось получить список доступов группы",
        description: JSON.stringify(String(permissionsError), null, 2),
      });
    }
  }, [permissionsError, api]);

  if (isPermissionsLoading) {
    return <Skeleton />;
  }

  if (permissionsError) {
    return <Text color="red">Не удалось получить список доступов группы</Text>;
  }

  if (isPermissionsSuccess) {
    return <AccessesSettingsView group={group} permissions={permissions} />;
  }

  return null;
};
