import type { FullDossierReport } from "@femida1/schemas";
import {
  EMultiQueryKeys,
  ReportType,
  mapMultiQueryKeyToTitle,
} from "@femida1/schemas";
import s from "@entities/RetrieveFullDossier/RetrieveFullDossier.module.scss";
import { SearchFioWidget } from "@widgets/SearchFioWidget/SearchFioWidget";
import { SearchPhoneWidget } from "@widgets/SearchPhoneWidget/SearchPhoneWidget";
import { SearchPassportWidget } from "@widgets/SearchPassportWidget/SearchPassportWidget";
import { SearchEmailWidget } from "@widgets/SearchEmailWidget/SearchEmailWidget";
import { SearchInnFlWidget } from "@widgets/SearchInnFlWidget/SearchInnFlWidget";
import type { ReactNode } from "react";

export const mapReportTypeToReport: Record<
  Extract<
    ReportType,
    | ReportType.SEARCH_FIO
    | ReportType.SEARCH_PASSPORT
    | ReportType.SEARCH_PHONE
    | ReportType.SEARCH_EMAIL
    | ReportType.SEARCH_INN_FL
  >,
  (report: FullDossierReport) => ReactNode
> = {
  [ReportType.SEARCH_FIO]: (report: FullDossierReport) => {
    const { type, id } = report;
    return (
      <div
        data-name="multi-query-block-report"
        id={mapMultiQueryKeyToTitle[EMultiQueryKeys.fio]}
        className={s.reportContainer}
      >
        <SearchFioWidget type={type} subReportId={id} />
      </div>
    );
  },
  [ReportType.SEARCH_PASSPORT]: (report: FullDossierReport) => {
    const { type, id } = report;
    return (
      <div
        data-name="multi-query-block-report"
        id={mapMultiQueryKeyToTitle[EMultiQueryKeys.passport]}
        className={s.reportContainer}
      >
        <SearchPassportWidget type={type} subReportId={id} />
      </div>
    );
  },
  [ReportType.SEARCH_PHONE]: (report: FullDossierReport) => {
    const { type, id } = report;
    return (
      <div
        data-name="multi-query-block-report"
        id={mapMultiQueryKeyToTitle[EMultiQueryKeys.phone]}
        className={s.reportContainer}
      >
        <SearchPhoneWidget type={type} subReportId={id} />
      </div>
    );
  },
  [ReportType.SEARCH_EMAIL]: (report: FullDossierReport) => {
    const { type, id } = report;
    return (
      <div
        data-name="multi-query-block-report"
        id={mapMultiQueryKeyToTitle[EMultiQueryKeys.email]}
        className={s.reportContainer}
      >
        <SearchEmailWidget type={type} subReportId={id} />
      </div>
    );
  },
  [ReportType.SEARCH_INN_FL]: (report: FullDossierReport) => {
    const { type, id } = report;
    return (
      <div
        data-name="multi-query-block-report"
        id={mapMultiQueryKeyToTitle[EMultiQueryKeys.inn]}
        className={s.reportContainer}
      >
        <SearchInnFlWidget type={type} subReportId={id} />
      </div>
    );
  },
};
