import type { FC } from "react";
import { useCallback, useEffect } from "react";
import { Flex, Input } from "@femida1/uikit";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitButton } from "@pages/Main/components/MainTabs/components/SubmitButton/SubmitButton";
import type {
  MainTabFieldValues,
  MainTabProps,
} from "@pages/Main/components/MainTabs/types";
import { dropZoneFileListSchema } from "@pages/Main/components/MainTabs/constants/dropZoneFileListSchema";
import type { ReportsSnilsSearchRequest } from "@femida1/schemas";
import { useReportsSnilsSearch } from "@femida1/gui_web_core";
import { validationMessage } from "@utils/constants";
import { correctSnilsRegexCallback } from "@utils/validation";
import s from "../MainTabInnOrSnils.module.scss";

interface ReportsSnilsFieldValues
  extends ReportsSnilsSearchRequest,
    MainTabFieldValues {}

const schema = yup
  .object()
  .shape({
    snils: yup
      .string()
      .test(
        "snils-check",
        validationMessage.WrongSnils,
        correctSnilsRegexCallback,
      )
      .required(),
    sopdFileList: dropZoneFileListSchema,
  })
  .required();

type MainTabSnilsProps = MainTabProps;

export const MainTabSnils: FC<MainTabSnilsProps> = ({
  onSubmit,
  setMultiQueryCheckboxVisible,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      snils: "",
    },
  });
  // TODO bring out the configuration above
  useEffect(() => {
    setMultiQueryCheckboxVisible?.(false);
  }, []);

  const { mutateAsync: search, isPending: isSearchPending } =
    useReportsSnilsSearch();

  const onSubmitInner = useCallback(
    (data: ReportsSnilsFieldValues) => {
      const requestData = {
        ...data,
        snils: data.snils.replace(/\D/g, ""),
      };

      return onSubmit({
        requestData,
        search,
        withSopd: true,
        onSuccess: reset,
      });
    },
    [search, onSubmit, reset],
  );

  return (
    <form onSubmit={handleSubmit(onSubmitInner)}>
      <Flex gap={12} className={s.controllersContainer}>
        <Controller
          name="snils"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              tabIndex={0}
              required
              mask="000-000-000 00"
              placeholder="СНИЛС"
              {...field}
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="sopdFileList"
          control={control}
          render={({ field }) => (
            <SubmitButton
              fileList={field.value}
              onFileListChange={field.onChange}
              disabled={!isValid || isSearchPending}
            />
          )}
        />
      </Flex>
    </form>
  );
};
