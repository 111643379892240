import { appConfig } from "@app/providers/AppFeatures/appConfig";
import type { Theme } from "@femida1/gui_web_core";
import { useCallback, useLayoutEffect } from "react";

const appIcon = appConfig.app.icon;

export const useAppFavicon = () => {
  const updateFavicon = useCallback((theme: Theme) => {
    const favicon = document.getElementById("favicon") as HTMLLinkElement;
    if (!favicon) return;

    favicon.href = appIcon.mobile[theme].path;
  }, []);

  useLayoutEffect(() => {
    const darkScheme = window.matchMedia("(prefers-color-scheme: dark)");
    updateFavicon(darkScheme.matches ? "dark" : "light");

    const listener = (e: MediaQueryListEvent) => {
      updateFavicon(e.matches ? "dark" : "light");
    };

    darkScheme.addEventListener("change", listener);

    return () => {
      darkScheme.removeEventListener("change", listener);
    };
  }, [updateFavicon]);
};
