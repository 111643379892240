import { Text } from "@femida1/uikit";
import type { ReportType } from "@femida1/schemas";
import type { FC } from "react";
import { MdEdit } from "react-icons/md";
import { NoteModal } from "./NoteModal";

interface NoteModalMultiQueryFullDossierProps {
  reportId: string;
  type: ReportType;
  note: string;
}

export const NoteModalMultiQueryFullDossier: FC<
  NoteModalMultiQueryFullDossierProps
> = ({ reportId, type, note }) => {
  return (
    <NoteModal
      type={type}
      reportId={reportId}
      buttonTitle={
        <Text color="secondary">
          <MdEdit size={14} />
        </Text>
      }
      note={note}
    />
  );
};
