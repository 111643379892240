import type { FC } from "react";
import { useCallback } from "react";
import Sider from "antd/es/layout/Sider";
import { Drawer } from "@femida1/uikit";
import { MainLayoutAlertsContent } from "./components/MainLayoutAlertsContent/MainLayoutAlertsContent";

interface MainLayoutAlertsProps {
  collapsed: boolean;
  isTablet: boolean;
  onCollapse: (collapsed: boolean) => void;
  setWrapperRef: (element: HTMLElement | null) => void;
  siderClassName: string;
}

export const MainLayoutAlerts: FC<MainLayoutAlertsProps> = ({
  collapsed,
  onCollapse,
  setWrapperRef,
  isTablet,
  siderClassName,
}) => {
  const onClose = useCallback(() => {
    onCollapse(true);
  }, [onCollapse]);

  return isTablet ? (
    <Drawer
      width={230}
      placement="right"
      visible={!collapsed}
      onClose={onClose}
    >
      <span ref={setWrapperRef}>
        <MainLayoutAlertsContent onClose={onClose} />
      </span>
    </Drawer>
  ) : (
    <Sider
      ref={setWrapperRef}
      className={siderClassName}
      defaultCollapsed
      width={320}
      collapsedWidth={0}
      collapsed={collapsed}
      onCollapse={onCollapse}
    >
      <MainLayoutAlertsContent onClose={onClose} />
    </Sider>
  );
};
