import type { FC, RefObject } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { MainTab } from "@pages/Main/constants";
import cn from "classnames";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import type { TabsProps } from "antd";
import type { MainTabsProps } from "@pages/Main/components/MainTabs/types/MainTabsProps";
import { Button } from "@femida1/uikit";
import s from "./TabsArrows.module.scss";

interface ITabsArrowsProps extends MainTabsProps {
  filteredTabItems: TabsProps["items"];
  tabsNavRef: RefObject<HTMLDivElement>;
}

export const TabsArrows: FC<ITabsArrowsProps> = ({
  tab,
  setTab,
  filteredTabItems,
  tabsNavRef,
}) => {
  const [isLeftArrowVisible, setIsLeftArrowVisible] = useState(false);
  const [isRightArrowVisible, setIsRightArrowVisible] = useState(true);

  const checkArrowsVisibility = useCallback(() => {
    const tabsNav = tabsNavRef.current;
    if (tabsNav) {
      const tabsNavWrap = tabsNav.querySelector(".ant-tabs-nav-wrap");
      if (tabsNavWrap) {
        const hasLeftPing = tabsNavWrap.classList.contains(
          "ant-tabs-nav-wrap-ping-left",
        );
        const hasRightPing = tabsNavWrap.classList.contains(
          "ant-tabs-nav-wrap-ping-right",
        );
        setIsLeftArrowVisible(hasLeftPing);
        setIsRightArrowVisible(hasRightPing);
      }
    }
  }, [tabsNavRef]);

  useEffect(() => {
    const tabsNav = tabsNavRef.current;
    if (tabsNav) {
      const observer = new MutationObserver(checkArrowsVisibility);
      observer.observe(tabsNav, {
        attributes: true,
        childList: true,
        subtree: true,
      });
      checkArrowsVisibility();
      return () => {
        observer.disconnect();
      };
    }
    return undefined;
  }, [tabsNavRef]);

  const currentIndex = useMemo(
    () => filteredTabItems?.findIndex((item) => item.key === tab) || 0,
    [filteredTabItems, tab],
  );

  const handlePrev = useCallback(() => {
    if (!filteredTabItems) return;

    if (currentIndex > 0) {
      setTab(filteredTabItems[currentIndex - 1].key as MainTab);
    }
  }, [currentIndex, filteredTabItems, setTab]);

  const handleNext = useCallback(() => {
    if (!filteredTabItems) return;

    if (currentIndex < filteredTabItems.length - 1) {
      setTab(filteredTabItems[currentIndex + 1].key as MainTab);
    }
  }, [currentIndex, filteredTabItems, setTab]);

  return (
    <div className={s.arrowContainer}>
      <Button
        type="flat"
        onClick={handlePrev}
        className={cn(
          s.arrowContainer_arrow,
          isLeftArrowVisible && s.arrowContainer_arrow_visible,
        )}
      >
        <IoIosArrowBack color="black" size={32} />
      </Button>
      <Button
        type="flat"
        onClick={handleNext}
        className={cn(
          s.arrowContainer_arrow,
          isRightArrowVisible && s.arrowContainer_arrow_visible,
        )}
      >
        <IoIosArrowForward color="black" size={32} />
      </Button>
    </div>
  );
};
