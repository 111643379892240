import { appConfig } from "@app/providers/AppFeatures/appConfig";
import { MenuGroup } from "@app/layouts/MainLayout/components/constants";
import { isMainTab } from "@pages/Main/constants";
import { mapMainTabs } from "@pages/Main/components/MainTabs/constants/mapMainTabs";
import type { IMainTab } from "../types/IMainTab";

export const getMainTabItems = (): IMainTab[] => {
  const { items } = appConfig.navMenu;
  const checkingTabs = items.find(
    (menuItem) => menuItem.key === MenuGroup.Checking,
  )?.children;

  return checkingTabs!
    .map((tab) => {
      if (isMainTab(tab)) return mapMainTabs[tab];
      return undefined;
    })
    .filter((subItem) => !!subItem);
};
