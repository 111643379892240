import { type ReactNode, Suspense } from "react";
import { Skeleton } from "antd";
import {
  Accesses,
  AccessesAdd,
  AccessesEdit,
  ActionLog,
  Alerts,
  Analytics,
  Contacts,
  Documents,
  Employees,
  History,
  Search,
  SupportChat,
  Telegram,
  UserSettings,
} from "@pages/index";
import { AppRoute } from "@app/routes/constants";

interface IRouterItem {
  element: ReactNode;
  path: string;
}

export const mapRouterItems: Record<
  Exclude<
    AppRoute,
    | AppRoute.Report
    | AppRoute.TemporaryShutdown
    | AppRoute.Tariffs
    | AppRoute.Lk
    | AppRoute.Root
  >,
  IRouterItem
> = {
  [AppRoute.Analytics]: {
    element: (
      <Suspense fallback={<Skeleton />}>
        <Analytics />
      </Suspense>
    ),
    path: AppRoute.Analytics,
  },
  [AppRoute.Employees]: {
    element: (
      <Suspense fallback={<Skeleton />}>
        <Employees />
      </Suspense>
    ),
    path: AppRoute.Employees,
  },
  [AppRoute.Accesses]: {
    element: (
      <Suspense fallback={<Skeleton />}>
        <Accesses />
      </Suspense>
    ),
    path: AppRoute.Accesses,
  },
  [AppRoute.Telegram]: {
    element: (
      <Suspense fallback={<Skeleton />}>
        <Telegram />
      </Suspense>
    ),
    path: AppRoute.Telegram,
  },
  [AppRoute.History]: {
    element: (
      <Suspense fallback={<Skeleton />}>
        <History />
      </Suspense>
    ),
    path: AppRoute.History,
  },
  [AppRoute.Contacts]: {
    element: (
      <Suspense fallback={<Skeleton />}>
        <Contacts />
      </Suspense>
    ),
    path: AppRoute.Contacts,
  },
  [AppRoute.Support]: {
    element: (
      <Suspense fallback={<Skeleton />}>
        <SupportChat />
      </Suspense>
    ),
    path: AppRoute.Support,
  },
  [AppRoute.Action]: {
    element: (
      <Suspense fallback={<Skeleton />}>
        <ActionLog />
      </Suspense>
    ),
    path: AppRoute.Action,
  },
  [AppRoute.Documents]: {
    element: (
      <Suspense fallback={<Skeleton />}>
        <Documents />
      </Suspense>
    ),
    path: AppRoute.Documents,
  },
  [AppRoute.User]: {
    element: (
      <Suspense fallback={<Skeleton />}>
        <UserSettings />
      </Suspense>
    ),
    path: AppRoute.User,
  },
  [AppRoute.AccessesAdd]: {
    element: (
      <Suspense fallback={<Skeleton />}>
        <AccessesAdd />
      </Suspense>
    ),
    path: AppRoute.AccessesAdd,
  },
  [AppRoute.AccessesEdit]: {
    element: (
      <Suspense fallback={<Skeleton />}>
        <AccessesEdit />
      </Suspense>
    ),
    path: AppRoute.AccessesEdit,
  },
  [AppRoute.Search]: {
    element: (
      <Suspense fallback={<Skeleton />}>
        <Search />
      </Suspense>
    ),
    path: AppRoute.Search,
  },
  [AppRoute.Alerts]: {
    element: (
      <Suspense fallback={<Skeleton />}>
        <Alerts />
      </Suspense>
    ),
    path: AppRoute.Alerts,
  },
};
