import type { FC } from "react";
import { useCallback } from "react";
import { Flex, Input } from "@femida1/uikit";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitButton } from "@pages/Main/components/MainTabs/components/SubmitButton/SubmitButton";
import type { ReportsInfoSearchRequest } from "@femida1/schemas";
import { useReportsInfoSearch } from "@femida1/gui_web_core";
import s from "./MainTabInfo.module.scss";
import type { MainTabFieldValues, MainTabProps } from "../../types";
import { dropZoneFileListSchema } from "../../constants/dropZoneFileListSchema";

interface ReportsInfoFieldValues
  extends ReportsInfoSearchRequest,
    MainTabFieldValues {}

const schema = yup
  .object()
  .shape({
    info: yup.string().min(10).max(200).required(),
    sopdFileList: dropZoneFileListSchema,
  })
  .required();

type MainTabInfoProps = MainTabProps;

export const MainTabInfo: FC<MainTabInfoProps> = ({ onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      info: "",
    },
  });

  const { mutateAsync: search, isPending: isSearchPending } =
    useReportsInfoSearch();

  const onSubmitInner = useCallback(
    (requestData: ReportsInfoFieldValues) =>
      onSubmit({ requestData, search, withSopd: true, onSuccess: reset }),
    [search, onSubmit, reset],
  );

  return (
    <form onSubmit={handleSubmit(onSubmitInner)}>
      <Flex gap={12} className={s.controllersContainer}>
        <Controller
          name="info"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              tabIndex={0}
              required
              placeholder="Ключевое слово"
              {...field}
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="sopdFileList"
          control={control}
          render={({ field }) => (
            <SubmitButton
              fileList={field.value}
              onFileListChange={field.onChange}
              disabled={!isValid || isSearchPending}
            />
          )}
        />
      </Flex>
    </form>
  );
};
