import type { FC } from "react";
import { useCallback } from "react";
import { Flex, Input } from "@femida1/uikit";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitButton } from "@pages/Main/components/MainTabs/components/SubmitButton/SubmitButton";
import { validationMessage } from "@utils/constants";
import { correctPassportRegexCallback } from "@utils/validation";
import { useReportsPassportSearch } from "@femida1/gui_web_core";
import type { ReportsPassportSearchRequest } from "@femida1/schemas";
import s from "./MainTabPassport.module.scss";
import type { MainTabFieldValues, MainTabProps } from "../../types";
import { dropZoneFileListSchema } from "../../constants/dropZoneFileListSchema";

interface ReportsPassportFieldValues
  extends ReportsPassportSearchRequest,
    MainTabFieldValues {}

const schema = yup
  .object()
  .shape({
    passport: yup
      .string()
      .test(
        "passport-check",
        validationMessage.WrongPassport,
        correctPassportRegexCallback,
      )
      .required(),
    sopdFileList: dropZoneFileListSchema,
  })
  .required();

type MainTabPassportProps = MainTabProps;

export const MainTabPassport: FC<MainTabPassportProps> = ({ onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      passport: "",
    },
  });

  const { mutateAsync: search, isPending: isSearchPending } =
    useReportsPassportSearch();

  const onSubmitInner = useCallback(
    (data: ReportsPassportFieldValues) => {
      const requestData = {
        ...data,
        passport: data.passport.replace(/\D/g, ""),
      };

      return onSubmit({
        requestData,
        search,
        withSopd: true,
        onSuccess: reset,
      });
    },
    [search, onSubmit, reset],
  );

  return (
    <form onSubmit={handleSubmit(onSubmitInner)}>
      <Flex gap={12} className={s.controllersContainer}>
        <Controller
          name="passport"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              tabIndex={0}
              required
              mask="0000 000000"
              placeholder="Серия и номер паспорта"
              {...field}
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="sopdFileList"
          control={control}
          render={({ field }) => (
            <SubmitButton
              fileList={field.value}
              onFileListChange={field.onChange}
              disabled={!isValid || isSearchPending}
            />
          )}
        />
      </Flex>
    </form>
  );
};
