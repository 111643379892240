import type { ReportCommonProps } from "@femida1/schemas";
import { useParams } from "react-router-dom";
import { useReportsKadNumberRetrieve } from "@femida1/gui_web_core";
import { Skeleton } from "antd";
import { SearchFull } from "@entities/SearchFull/SearchFull";

export const SearchKadNumberWidget = ({
  type,
}: Omit<ReportCommonProps, "reportId">) => {
  const { reportId = "" } = useParams();

  const { data: kadNumberReport, isLoading } = useReportsKadNumberRetrieve({
    id: reportId,
  });

  return (
    <Skeleton loading={isLoading}>
      {kadNumberReport && (
        <SearchFull report={kadNumberReport} type={type} reportId={reportId} />
      )}
    </Skeleton>
  );
};
