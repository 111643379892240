import { Permission } from "@femida1/schemas";
import { MainTab } from "@pages/Main/constants";

export const mapPermissionToLabel: Record<Permission, string> = {
  [Permission.AccessApiPermission]: "Доступ к API",
  [Permission.AdminPermission]: "Админ",
  [Permission.MemberPermission]: "Права сотрудника",
  [Permission.ReportIncludeOpenSourcesPermission]: "Открытые источники",
  [Permission.ReportFullDossierPermission]: "Поиск по полному досье",
  [Permission.ReportFullDossierLightPermission]: "Поиск по полному досье",
  [Permission.ReportAddressPermission]: "Поиск по адресу",
  [Permission.ReportCreditRatingPermission]: "Поиск по кредитному рейтингу",
  [Permission.ReportFullCreditRatingPermission]:
    "Поиск по расширенному кредитному рейтингу",
  [Permission.ReportDriverLicensePermission]: "Поиск по ВУ",
  [Permission.ReportKadNumberPermission]: "Поиск по кадастровому номеру",
  [Permission.ReportEmailPermission]: "Поиск по Email",
  [Permission.ReportEmailLightPermission]: "Поиск по Email",
  [Permission.ReportFioPermission]: "Поиск по ФИО",
  [Permission.ReportGrnPermission]: "Поиск по ГРН",
  [Permission.ReportInfoPermission]: "Поиск по Ключевому слову",
  [Permission.ReportInnFlPermission]: "Поиск по ИНН (физ.лица)",
  [Permission.ReportInnUlPermission]: "Поиск по ИНН (юр.лица)",
  [Permission.ReportOgrnPermission]: "Поиск по ОГРН",
  [Permission.ReportPassportPermission]: "Поиск по Паспорту",
  [Permission.ReportPhonePermission]: "Поиск по Телефону",
  [Permission.ReportPhoneLightPermission]: "Поиск по Телефону",
  [Permission.ReportPhotoByFacePermission]: "Поиск по Фото",
  [Permission.ReportPhotoByFaceLightPermission]: "Поиск по Фото",
  [Permission.ReportPhotoByFioPermission]: "Поиск фото по ФИО",
  [Permission.ReportScoringPermission]: "Поиск по Скорингу",
  [Permission.ReportSnilsPermission]: "Поиск по СНИЛС",
  [Permission.ReportVinPermission]: "Поиск по ВИН",
  [Permission.SuperuserPermission]: "Полные права",
  [Permission.ReportTelegramPermission]: "Поиск по Telegram",
  [Permission.ReportVKPermission]: "Поиск по VK",
};

export const mapPermissionToMainTab: Record<
  Exclude<
    Permission,
    | Permission.SuperuserPermission
    | Permission.AdminPermission
    | Permission.MemberPermission
    | Permission.AccessApiPermission
    | Permission.ReportIncludeOpenSourcesPermission
    | Permission.ReportPhotoByFioPermission
  >,
  MainTab
> = {
  [Permission.ReportFullDossierPermission]: MainTab.FullDossier,
  [Permission.ReportFullDossierLightPermission]: MainTab.FullDossierLight,
  [Permission.ReportDriverLicensePermission]: MainTab.DriverLicense,
  [Permission.ReportEmailPermission]: MainTab.Email,
  [Permission.ReportEmailLightPermission]: MainTab.EmailLight,
  [Permission.ReportFioPermission]: MainTab.Fio,
  [Permission.ReportInfoPermission]: MainTab.Info,
  [Permission.ReportPassportPermission]: MainTab.Passport,
  [Permission.ReportPhonePermission]: MainTab.Phone,
  [Permission.ReportPhoneLightPermission]: MainTab.PhoneLight,
  [Permission.ReportPhotoByFacePermission]: MainTab.Photo,
  [Permission.ReportPhotoByFaceLightPermission]: MainTab.PhotoLight,
  [Permission.ReportScoringPermission]: MainTab.Scoring,
  [Permission.ReportAddressPermission]: MainTab.Address,
  [Permission.ReportCreditRatingPermission]: MainTab.CreditRating,
  [Permission.ReportFullCreditRatingPermission]: MainTab.CreditRating,
  [Permission.ReportKadNumberPermission]: MainTab.Address,
  [Permission.ReportGrnPermission]: MainTab.Auto,
  [Permission.ReportInnFlPermission]: MainTab.InnOrSnils,
  [Permission.ReportInnUlPermission]: MainTab.Company,
  [Permission.ReportOgrnPermission]: MainTab.Company,
  [Permission.ReportSnilsPermission]: MainTab.InnOrSnils,
  [Permission.ReportVinPermission]: MainTab.Auto,
  [Permission.ReportTelegramPermission]: MainTab.Telegram,
  [Permission.ReportVKPermission]: MainTab.Vk,
};
