interface PrintFileProps {
  data: Blob | MediaSource;
}

export const printFile = ({ data }: PrintFileProps) => {
  const url = URL.createObjectURL(data);
  const iframe = document.createElement("iframe");
  iframe.style.display = "none";
  iframe.src = url;
  document.body.appendChild(iframe);

  iframe.onload = () => {
    if (!iframe) return;

    iframe.focus();
    iframe.contentWindow?.print();

    URL.revokeObjectURL(url);
  };
};
