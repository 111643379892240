import { Flex, Block } from "@femida1/uikit";
// import { AccessesApi } from './components/AccessesApi/AccessesApi';
import { AppRoute } from "@app/routes/constants";
import { useParams } from "react-router-dom";
import { useCurrentUserQuery, useGroup } from "@femida1/gui_web_core";
import { AccessesSettings } from "@components/AccessesSettings/AccessesSettings";
import { PageHeader } from "@app/layouts/PageHeader/PageHeader";
import s from "./AccessesAdd.module.scss";

export const AccessesAdd = () => {
  const { data: user } = useCurrentUserQuery();
  const { group_id } = useParams();
  const { data: group } = useGroup(
    { group_id: Number(group_id) },
    { enabled: Boolean(group_id) },
  );

  if (!user || !group) {
    return null;
  }

  return (
    <Flex vertical gap={32} className={s.wrapper}>
      <PageHeader
        title="Новый доступ"
        mapPathToTitle={{
          [AppRoute.Accesses]: "Доступы",
          [AppRoute.AccessesAdd]: "Новый доступ",
        }}
        paths={[AppRoute.Accesses, AppRoute.AccessesAdd]}
      />
      <Block>
        <Flex vertical gap={60}>
          <AccessesSettings group={group} />
        </Flex>
      </Block>
      <div className={s.wrapper__dummyDrawer} />
    </Flex>
  );
};
