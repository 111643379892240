import { AppRoute } from "@app/routes/constants";
import { ENavMenuItem, MainTab } from "@pages/Main/constants";
import { MenuGroup } from "@app/layouts/MainLayout/components/constants";
import { ReactComponent as OdysseyBlack } from "@assets/logo_odyssey_black.svg";
import type { AppConfig } from "../../types";

const telegramBotEnabled = false;

export const odysseyBlackStageConfig: AppConfig = {
  app: {
    icon: {
      desktop: {
        dark: {
          Icon: OdysseyBlack,
          path: "src/assets/logo_odyssey_black.svg",
        },
        light: {
          Icon: OdysseyBlack,
          path: "src/assets/logo_odyssey_black.svg",
        },
      },
      mobile: {
        dark: {
          Icon: OdysseyBlack,
          path: "src/assets/logo_odyssey_black.svg",
        },
        light: {
          Icon: OdysseyBlack,
          path: "src/assets/logo_odyssey_black.svg",
        },
      },
    },
  },
  features: {
    appEnabled: true,

    authResetPasswordEnabled: false,
    authUserSelfRegistrationEnabled: false,
    chatsAvailable: false,
    documentsPageEnabled: true,
    mainIncludeOpenSourcesEnabled: false,
    mainMultiQueryEnabled: false,

    onboardingEnabled: false,

    reportCrossingBordersExtended: false,
    reportFioAsGift: true,
    reportFooterDescriptionEnabled: false,
    reportPdfFooterLinkEnabled: false,
    reportPossibleNetworksCreateReportEnabled: true,
    reportStatusInverted: false,

    tabEmailLightSearch: false,
    tabFioSopdEnabled: false,
    tabFullDossierLightDriverLicenseEnabled: false,
    tabMainSopdEnabled: false,
    tabPhoneLightSearch: false,
    tabVkSopdEnabled: false,

    telegramBotEnabled,
    themeSwitchEnabled: false,

    userSettingsGroupType: true,
  },
  hosts: {
    apiDocsHref: "https://docs.zbebwk.ai",
  },
  navMenu: {
    items: [
      {
        children: [
          MainTab.FullDossier,
          MainTab.Fio,
          MainTab.Phone,
          MainTab.Address,
          MainTab.InnOrSnils,
          MainTab.Passport,
          MainTab.Auto,
          MainTab.DriverLicense,
          MainTab.Email,
          MainTab.Company,
          MainTab.Photo,
          MainTab.CreditRating,
          MainTab.Scoring,
          MainTab.Vk,
          MainTab.Info,
          MainTab.Telegram,
        ],
        key: MenuGroup.Checking,
      },
      {
        children: [ENavMenuItem.Analytics, ENavMenuItem.History],
        key: MenuGroup.Cabinet,
      },
      {
        children: [
          ENavMenuItem.Action,
          ENavMenuItem.Accesses,
          ENavMenuItem.Support,
        ],
        key: MenuGroup.Organization,
      },
      {
        children: [ENavMenuItem.ApiDocs],
        key: MenuGroup.Info,
      },
      ...(telegramBotEnabled ? [{ key: MenuGroup.Telegram }] : []),
    ],
  },
  pages: {
    analytics: {
      graphColors: {
        all: {
          /**
           * --color-main-0
           */
          bgGradientBottom: "rgba(18, 18, 18, 0)",
          /**
           * --color-main-300
           */
          bgGradientTop: "rgba(18, 18, 18, 0.3)",
          /**
           * --color-main-1000
           */
          borderColor: "rgb(18, 18, 18)",
        },
        target: {
          /**
           * --color-base-accident-0
           */
          bgGradientBottom: "rgba(251, 139, 91, 0)",
          /**
           * --color-base-accident-300
           */
          bgGradientTop: "rgba(251, 139, 91, 0.3)",
          /**
           * --color-base-accident-1000
           */
          borderColor: "rgb(251, 139, 91)",
        },
      },
    },
  },
  routes: [
    { key: AppRoute.Analytics },
    { key: AppRoute.Employees },
    { key: AppRoute.Accesses },
    { key: AppRoute.History },
    { key: AppRoute.Support },
    { key: AppRoute.Action },
    { key: AppRoute.User },
    { key: AppRoute.AccessesAdd },
    { key: AppRoute.AccessesEdit },
    { key: AppRoute.Search },
    { key: AppRoute.Alerts },
  ],
  title: "BL",
};
