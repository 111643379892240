import { YandexMap } from "@components/YandexMap/YandexMap";
import { averageGeographicCoordinate } from "@components/YandexMap/averageGeographicCoordinate";
import type { OrderMapItem } from "@femida1/schemas";
import type { RetrieveCardCommonProps } from "@femida1/gui_web_core";
import { RetrieveCard, RetrieveCardPrint } from "@femida1/gui_web_core";

export type PhoneBookProps = {
  orderMap?: OrderMapItem[];
} & RetrieveCardCommonProps;

export const OrderMap = ({
  orderMap,
  status,
  print = RetrieveCardPrint.device,
  isSubReport,
}: PhoneBookProps) => {
  const center = orderMap
    ? averageGeographicCoordinate(
        orderMap.map((order) => [
          order.latitude || "0",
          order.longitude || "0",
        ]),
      )
    : undefined;

  return (
    <RetrieveCard
      isSubReport={isSubReport}
      expanded={isSubReport}
      title="Карта заказов"
      status={status}
      print={print}
    >
      <YandexMap center={center} orderMap={orderMap} />
    </RetrieveCard>
  );
};
