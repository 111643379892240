import type { Theme } from "@femida1/gui_web_core";
import { USER_APP_THEME_KEY_LS } from "./constants";

export const saveUserAppTheme = (theme: Theme) => {
  try {
    localStorage.setItem(USER_APP_THEME_KEY_LS, theme);
  } catch {
    /* empty */
  }
};
