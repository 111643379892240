import { createBrowserRouter, Navigate } from "react-router-dom";
import { Suspense } from "react";
import { Root } from "@pages/Root/Root";
import { Skeleton } from "antd";
import { Main, Report, Tariffs, TemporaryShutdown } from "@pages/index";
import { appConfig } from "@app/providers/AppFeatures/appConfig";
import { AppRoute } from "./constants";
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute";
import { mapRouterItems } from "./constants/mapRouterItems";

const routes = appConfig.routes.map((route) => mapRouterItems[route.key]);

export const router = createBrowserRouter([
  {
    children: appConfig.features.appEnabled
      ? [
          {
            element: <Navigate to={AppRoute.Lk} replace />,
            path: "",
          },
          {
            element: <Navigate to={AppRoute.Lk} replace />,
            path: "*",
          },
          {
            children: [
              {
                element: (
                  <Suspense fallback={<Skeleton />}>
                    <Main />
                  </Suspense>
                ),
                path: "",
              },
              ...routes,
            ],
            element: <PrivateRoute />,
            path: AppRoute.Lk,
          },
          {
            element: (
              <Suspense fallback={<Skeleton />}>
                <Report />
              </Suspense>
            ),
            path: AppRoute.Report,
          },
          {
            element: (
              <Suspense fallback={<Skeleton />}>
                <Tariffs />
              </Suspense>
            ),
            path: AppRoute.Tariffs,
          },
        ]
      : [
          {
            element: <Navigate to={AppRoute.TemporaryShutdown} replace />,
            path: "",
          },
          {
            element: <Navigate to={AppRoute.TemporaryShutdown} replace />,
            path: "*",
          },
          {
            element: (
              <Suspense fallback={<Skeleton />}>
                <TemporaryShutdown />
              </Suspense>
            ),
            path: AppRoute.TemporaryShutdown,
          },
        ],
    element: <Root />,
    path: AppRoute.Root,
  },
]);
