import type { FC } from "react";
import { useCallback, useMemo, useState } from "react";
import { Flex, Toggle } from "@femida1/uikit";
import { useCurrentUserQuery } from "@femida1/gui_web_core";
import { Permission } from "@femida1/schemas";
import { getMainTabToggleSettings } from "@pages/Main/components/MainTabs/utils/getMainTabToggleSettings";
import type { MainTabProps } from "../../types";
import { MainTabAddressNormal } from "./components/MainTabAddressNormal/MainTabAddressNormal";
import { MainTabAddressCadastral } from "./components/MainTabAddressCadastral/MainTabAddressCadastral";

type MainTabAddressProps = MainTabProps;

export const MainTabAddress: FC<MainTabAddressProps> = ({ onSubmit }) => {
  const { data: user } = useCurrentUserQuery();
  const userPermissions = user?.groups?.[0].permissions;

  const { shouldShowToggle, initialType } = useMemo(
    () =>
      getMainTabToggleSettings<"normal", "cadastral">({
        userPermissions,
        firstPermission: Permission.ReportAddressPermission,
        secondPermission: Permission.ReportKadNumberPermission,
        firstType: "normal",
        secondType: "cadastral",
      }),
    [userPermissions],
  );

  const [type, setType] = useState<"cadastral" | "normal">(initialType);

  const onTypeChange = useCallback((checked: boolean) => {
    if (checked) {
      setType("cadastral");
    } else {
      setType("normal");
    }
  }, []);

  return (
    <Flex vertical gap={24}>
      {type === "normal" ? (
        <MainTabAddressNormal onSubmit={onSubmit} />
      ) : (
        <MainTabAddressCadastral onSubmit={onSubmit} />
      )}
      {shouldShowToggle && (
        <Toggle
          value={type === "cadastral"}
          onChange={onTypeChange}
          title="По кадастровому номеру"
        />
      )}
    </Flex>
  );
};
