import { Flex, Block, Text, Button, usePageSize } from "@femida1/uikit";
import { AppRoute } from "@app/routes/constants";
import { Link } from "react-router-dom";
import { MdMailOutline, MdOutlinePhoneIphone } from "react-icons/md";
import { PageHeader } from "@app/layouts/PageHeader/PageHeader";
import s from "./Contacts.module.scss";

const ODYSSEY_PHONE = "8 (800) 600-64-08";
const ODYSSEY_MAIL = "info@odyssey-search.info";

const SupportButton = ({ isMobile }: { isMobile?: boolean }) => (
  <Link to={AppRoute.Support}>
    <Button type="secondary" width={isMobile ? "full" : "fit"}>
      <Text variant="label-3" color="accent">
        Связаться с поддержкой
      </Text>
    </Button>
  </Link>
);

export const Contacts = () => {
  const { isMobile } = usePageSize();

  return (
    <Flex vertical gap={32} className={s.wrapper}>
      <PageHeader
        title="Контакты"
        mapPathToTitle={{ [AppRoute.Contacts]: "Контакты" }}
        paths={[AppRoute.Contacts]}
      />
      <Block>
        <Flex vertical gap={24} align="flex-start">
          <Text variant="subheader-5">ООО &quot;ОДИССЕЙ-ИНФО&quot;</Text>
          <Link to={`tel:${ODYSSEY_PHONE}`}>
            <Flex gap={8} align="center">
              <MdOutlinePhoneIphone />
              <Text variant="subheader-3">{ODYSSEY_PHONE}</Text>
            </Flex>
          </Link>
          <Link to={`mailto:${ODYSSEY_MAIL}`}>
            <Flex gap={8} align="center">
              <MdMailOutline />
              <Text variant="subheader-3">{ODYSSEY_MAIL}</Text>
            </Flex>
          </Link>
          <Flex vertical gap={32}>
            <Text variant="body-2">
              ООО &quot;ОДИССЕЙ-ИНФО&quot;
              <br />
              ИНН 7708393721
              <br />
              ОГРН 121770002928
            </Text>
            <Text variant="body-2">
              Юридический адрес: 107140, г. Москва вн.тер.г муниципальный округ
              <br />
              Красносельский пл Комсомольская, 6 , э/пом/к/оф 1/1/105/07
              <br />
              <br />
              Фактический адрес: 121069, г. Москва, Новинский Бульвар 20ас8
              <br />
              Обособленное подразделение: 197101, г. Санкт-Петербург, ул. Мира 5
              б,
              <br />
              помещение 7-н
            </Text>
          </Flex>
          {!isMobile && <SupportButton />}
        </Flex>
      </Block>
      {isMobile && (
        <Block className={s.supportButtonContainer}>
          <SupportButton isMobile={isMobile} />
        </Block>
      )}
    </Flex>
  );
};
