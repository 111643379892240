import "core-js";
import ReactDOM from "react-dom/client";
import { QueryClientProvider } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import "./setup";
import "../styles/index.css";
import "../styles/modal.scss";
import "../styles/menu.scss";
import "./validation/yupConfig";
import "@femida1/gui_web_core/styles.css";
import "@femida1/uikit/styles.css";
import { femidaQueryClient } from "@femida1/gui_web_core";
import { ConfigProvider } from "antd";
import { StrictMode } from "react";
import { ErrorBoundary } from "./layouts/ErrorBoundary/ErrorBoundary";
import { App } from "./App/App";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <ErrorBoundary>
      <QueryClientProvider client={femidaQueryClient}>
        <ConfigProvider theme={{ hashed: false }}>
          <App />
        </ConfigProvider>
        {/* <ReactQueryDevtools initialIsOpen={false} position="bottom" /> */}
      </QueryClientProvider>
    </ErrorBoundary>
  </StrictMode>,
);
