import type { FC } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import type { TabsProps } from "antd";
import { Tabs } from "antd";
import {
  Flex,
  Text,
  Checkbox,
  useNotification,
  useSessionStorage,
  useBooleanState,
  Tooltip,
} from "@femida1/uikit";
import type { CheckboxChangeEvent } from "@femida1/uikit";
import { fileToBase64 } from "@utils/files/fileToBase64";
import { isAxiosError } from "axios";
import { mainTabFioFormKey } from "@pages/Main/components/MainTabs/constants/mainTabFioFormKey";
import { AuthForm } from "@components/AuthForm/AuthForm";
import _ from "lodash";
import type { Theme } from "@femida1/gui_web_core";
import { useCurrentUserQuery } from "@femida1/gui_web_core";
import { Permission } from "@femida1/schemas";
import cn from "classnames";
import { HintAlert } from "@pages/Main/components/MainTabs/components/HintAlert/HintAlert";
import type {
  PossibleNetworkResults,
  SearchRequestExtended,
} from "@femida1/schemas";
import { isTabWithMultiQuery } from "@pages/Main/components/MainTabs/utils/isTabWithMultiQuery";
import { AiFillQuestionCircle } from "react-icons/ai";
import { useAppFeatures, useAppTheme } from "@app/providers";
import { getMainTabItems } from "@pages/Main/components/MainTabs/utils/getMainTabItems";
import { handleBackendErrors } from "@utils/notifications/handleBackendErrors";
import { getAvailableCheckTabs } from "@utils/permissions/getAvailableCheckTabs";
import s from "./MainTabs.module.scss";
import type { ENavMenuItem } from "../../constants";
import { isMainTab, MainTab } from "../../constants";
import type {
  MainTabFieldValues,
  MainTabOnSubmitProps,
  MainTabProps,
} from "./types";
import { TabsArrows } from "./components/TabsArrows/TabsArrows";
import type { MainTabsProps } from "./types/MainTabsProps";

const items = getMainTabItems();

interface TabWrapperProps {
  tab: MainTab;
  hint: string;
  TabComponent: FC<MainTabProps>;
}

const TabWrapper: FC<TabWrapperProps> = ({ tab, hint, TabComponent }) => {
  const [api] = useNotification();
  const [excludeOpenSources, setExcludeOpenSources] = useState(false);
  const [enableMultiQuery, setEnableMultiQuery] = useState(false);
  const { data: user } = useCurrentUserQuery();
  const userPermissions = user?.groups?.[0].permissions;

  const onExcludeOpenSources = useCallback(async (e: CheckboxChangeEvent) => {
    setExcludeOpenSources(!e.target.checked);
  }, []);
  const onEnableMultiQuery = useCallback(async (e: CheckboxChangeEvent) => {
    setEnableMultiQuery(e.target.checked);
  }, []);
  const [multiQueryCheckboxVisible, setMultiQueryCheckboxVisible] =
    useState(false);

  const {
    state: isAuthModalVisible,
    setTrue: showAuthModal,
    setFalse: hideAuthModal,
  } = useBooleanState(false);

  const [, setValue] = useSessionStorage<PossibleNetworkResults | null>(
    mainTabFioFormKey,
    null,
  );

  const onSubmit = useCallback(
    async <T extends MainTabFieldValues>({
      requestData,
      search,
      withSopd,
      onSuccess,
    }: MainTabOnSubmitProps<T>) => {
      const trimmedRequestData = _.mapValues(requestData, (v) =>
        typeof v === "string" ? v.trim() : v,
      ) as T;

      const data: SearchRequestExtended & T = {
        ...trimmedRequestData,
        sopd: undefined,
        sopdFileList: undefined,
      };

      if (excludeOpenSources) {
        data.exclude_open_sources = excludeOpenSources;
      }

      if (enableMultiQuery) {
        data.include_multi_query = enableMultiQuery;
      }

      if (withSopd) {
        const sopdFile: File | undefined =
          trimmedRequestData.sopdFileList?.[0].originFileObj;
        data.sopd = sopdFile
          ? await fileToBase64(sopdFile).then((b) =>
              b?.replace(/^data:application\/pdf;base64,/, ""),
            )
          : undefined;
      }

      return search(data)
        .then(() => {
          api.success({
            message: "Запрос успешно создан!",
          });
          onSuccess();

          setValue(null);
        })
        .catch((error: unknown) => {
          handleBackendErrors({
            api,
            error,
          });

          if (isAxiosError(error) && error.response?.status === 401) {
            showAuthModal();
          }
        });
    },
    [excludeOpenSources, enableMultiQuery, api, setValue, showAuthModal],
  );

  const { mainIncludeOpenSourcesEnabled, mainMultiQueryEnabled } =
    useAppFeatures();

  return (
    <Flex vertical gap={24}>
      {!!hint && <HintAlert hint={hint} tab={tab} />}
      <AuthForm
        onSuccess={hideAuthModal}
        onClose={hideAuthModal}
        modalVisible={isAuthModalVisible}
      />
      <TabComponent
        setMultiQueryCheckboxVisible={(value) => {
          setMultiQueryCheckboxVisible(value);
          if (!value) setEnableMultiQuery(value);
        }}
        onSubmit={onSubmit}
        enableMultiQuery={enableMultiQuery}
      />
      <Flex vertical gap={16} align="start">
        {mainIncludeOpenSourcesEnabled &&
        (userPermissions?.includes(Permission.SuperuserPermission) ||
          userPermissions?.includes(
            Permission.ReportIncludeOpenSourcesPermission,
          )) ? (
          <Checkbox
            checked={!excludeOpenSources}
            onChange={onExcludeOpenSources}
          >
            Использовать открытые источники
          </Checkbox>
        ) : null}
        {mainMultiQueryEnabled &&
        (multiQueryCheckboxVisible || isTabWithMultiQuery(tab)) ? (
          <Flex align="center">
            <Checkbox checked={enableMultiQuery} onChange={onEnableMultiQuery}>
              Мультизапрос
            </Checkbox>
            <Tooltip
              className={s.multiQueryTooltip}
              title={
                <Text color="white">
                  Все отчеты, собранные в одном месте по данным из краткой
                  сводки
                </Text>
              }
            >
              <Text color="secondary">
                <AiFillQuestionCircle size={12} />
              </Text>
            </Tooltip>
          </Flex>
        ) : null}
      </Flex>
    </Flex>
  );
};

interface GetTabItemsProps {
  tab: MainTab | ENavMenuItem;
  theme: Theme;
}

const getTabItems = ({ tab, theme }: GetTabItemsProps): TabsProps["items"] =>
  items.map(({ key, label, Icon, TabComponent, hint }) => ({
    key,
    label: (
      <Text
        variant="body-2"
        color="inherit"
        className={cn(s[`tabs__tab_theme_${theme}`], {
          [s.tabs__tab_active]: tab === key,
        })}
      >
        <Flex gap={12}>
          {label}
          <Icon size={20} />
        </Flex>
      </Text>
    ),
    children: <TabWrapper tab={key} TabComponent={TabComponent} hint={hint} />,
  }));

export const MainTabs: FC<MainTabsProps> = ({ tab = MainTab.Fio, setTab }) => {
  const [theme] = useAppTheme();
  const tabsNavRef = useRef<HTMLDivElement>(null);
  const { data: user } = useCurrentUserQuery();
  const userPermissions = user?.groups?.[0].permissions;
  const tabItems = useMemo(() => getTabItems({ tab, theme }), [tab, theme]);
  const filteredTabItems = useMemo(
    () => getAvailableCheckTabs(tabItems, userPermissions),
    [tabItems, userPermissions],
  );

  useEffect(() => {
    if (
      filteredTabItems &&
      filteredTabItems.length > 0 &&
      !filteredTabItems.map(({ key }) => key).includes(tab)
    ) {
      const firstAvailableTab = filteredTabItems[0].key;
      if (firstAvailableTab && isMainTab(firstAvailableTab)) {
        setTab(firstAvailableTab);
      }
    }
  }, [tab, setTab, filteredTabItems]);

  return (
    <div className={s.tabsContainer} ref={tabsNavRef}>
      {filteredTabItems && filteredTabItems.length > 8 && (
        <TabsArrows
          setTab={setTab}
          tab={tab}
          filteredTabItems={filteredTabItems}
          tabsNavRef={tabsNavRef}
        />
      )}
      <Tabs
        activeKey={tab}
        destroyInactiveTabPane
        items={filteredTabItems}
        type="line"
        onChange={setTab as TabsProps["onChange"]}
        className={s.tabs}
      />
    </div>
  );
};
