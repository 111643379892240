import type { FC } from "react";
import { useCallback } from "react";
import { Flex, Input } from "@femida1/uikit";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitButton } from "@pages/Main/components/MainTabs/components/SubmitButton/SubmitButton";
import { validationRegex } from "@utils/constants";
import type { ReportsPhoneSearchRequest } from "@femida1/schemas";
import {
  useReportsPhoneLightSearch,
  useReportsPhoneSearch,
} from "@femida1/gui_web_core";
import { appConfig } from "@app/providers/AppFeatures/appConfig";
import s from "./MainTabPhone.module.scss";
import type { MainTabFieldValues, MainTabProps } from "../../types";
import { dropZoneFileListSchema } from "../../constants/dropZoneFileListSchema";

interface ReportsPhoneSearchFormValues
  extends ReportsPhoneSearchRequest,
    MainTabFieldValues {}

const schema = yup
  .object<ReportsPhoneSearchFormValues>()
  .shape({
    phone: yup
      .string()
      .matches(validationRegex.phone, "Номер телефона неверен")
      .required("Номер телефона обязателен"),
    sopdFileList: dropZoneFileListSchema,
  })
  .required();

type MainTabPhoneProps = MainTabProps;

const { features } = appConfig;

export const MainTabPhone: FC<MainTabPhoneProps> = ({ onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      phone: "",
    },
  });

  const { mutateAsync: search, isPending: isSearchPending } =
    useReportsPhoneSearch();
  const { mutateAsync: lightSearch, isPending: isLightSearchPending } =
    useReportsPhoneLightSearch();

  const onSubmitInner = useCallback(
    async (data: ReportsPhoneSearchFormValues) => {
      const requestData = {
        ...data,
        phone: data.phone.replace(/\D/g, ""),
      };

      return onSubmit({
        requestData,
        search: features.tabPhoneLightSearch ? lightSearch : search,
        withSopd: true,
        onSuccess: reset,
      });
    },
    [onSubmit, lightSearch, search, reset],
  );

  return (
    <form onSubmit={handleSubmit(onSubmitInner)}>
      <Flex gap={12} className={s.controllersContainer}>
        <Controller
          name="phone"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              mask="+70000000000"
              required
              placeholder="Телефон"
              {...field}
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="sopdFileList"
          control={control}
          render={({ field }) => (
            <SubmitButton
              fileList={field.value}
              onFileListChange={field.onChange}
              disabled={!isValid || isSearchPending || isLightSearchPending}
            />
          )}
        />
      </Flex>
    </form>
  );
};
