import { OrderType } from "@femida1/schemas";

export const historyListSortingRulesOrder: OrderType[] = [
  OrderType.DESCEND,
  OrderType.ASCEND,
];
export const historyListSortingRules: Record<OrderType, string> = {
  [OrderType.DESCEND]: "Сначала новые",
  [OrderType.ASCEND]: "Сначала старые",
};
