import type { FC } from "react";
import { useMemo } from "react";
import { Flex, Text, Button } from "@femida1/uikit";
import {
  useReportsRetrieveAll,
  useGroups,
  useCurrentUserQuery,
} from "@femida1/gui_web_core";
import { getReportTypeToTitleGetter } from "@utils/reports/getReportTypeToTitleGetter";
import { generatePath, Link } from "react-router-dom";
import { AppRoute } from "@app/routes/constants";
import type { User } from "@femida1/schemas";
import { getNavItems } from "@app/layouts/MainLayout/components/utils/getNavItems";
import { SearchItem } from "./components/SearchItem/SearchItem";
import s from "./MainLayoutHeaderSearch.module.scss";

const REPORTS_SHOW_ELEMENTS_COUNT = 3;

const filterUsersByQuery =
  (query: string) =>
  ({ user_id, name }: User) =>
    String(user_id).toLowerCase().includes(query) ||
    name.toLowerCase().includes(query);

interface MainLayoutHeaderSearchProps {
  onShowAll: () => void;
  onShowItem: () => void;
  query: string;
}

export const MainLayoutHeaderSearch: FC<MainLayoutHeaderSearchProps> = ({
  query,
  onShowItem,
  onShowAll,
}) => {
  const queryLc = query.toLowerCase();

  const { data: user } = useCurrentUserQuery();
  const { data: groups } = useGroups();
  const group = groups?.[0];

  const { data: reports } = useReportsRetrieveAll(
    {
      limit: REPORTS_SHOW_ELEMENTS_COUNT,
      request_contains: queryLc,
    },
    { enabled: Boolean(queryLc) },
  );
  const users = useMemo(() => {
    const mappedUsers = groups
      ?.map((g) => g.users.map((u) => ({ ...u, group_id: g.group_id })))
      .flat();

    if (!queryLc) {
      return mappedUsers;
    }

    const filteredUsers = mappedUsers?.filter(filterUsersByQuery(queryLc));

    return filteredUsers?.sort((a, b) => a.user_id - b.user_id);
  }, [queryLc, groups]);

  const flatMenuItems = useMemo(() => {
    const items = getNavItems({
      group_id: group?.group_id,
      user_id: user?.id,
    });
    const flatItems = items
      .map((item) => {
        if (item.children) {
          return item.children.map((subItem) => ({
            ...subItem,
            icon: item.icon,
          }));
        }

        return item;
      })
      .flat();

    return flatItems.filter(
      ({ title, meta }) =>
        meta.toLowerCase().includes(queryLc) ||
        title.toLowerCase().includes(queryLc),
    );
  }, [group?.group_id, user?.id, queryLc]);

  const foundItemsCount = useMemo(
    () =>
      (users && users.length > 0 ? users.length : 0) +
      (flatMenuItems && flatMenuItems.length > 0 ? flatMenuItems.length : 0) +
      (reports && reports.length > 0 ? reports.length : 0),
    [users, flatMenuItems, reports],
  );

  return (
    <Flex className={s.search} vertical gap={8}>
      {foundItemsCount > 0 && (
        <Flex justify="end" className={s.search__items_count_container}>
          <Text color="secondary">найдено совпадений {foundItemsCount}</Text>
        </Flex>
      )}
      {flatMenuItems?.length ? (
        <Flex vertical>
          <Text className={s.search__title} variant="subheader-4">
            Навигация
          </Text>
          {flatMenuItems.slice(0, 5).map(({ key, title, href, icon }) => (
            <SearchItem
              key={key}
              icon={icon}
              title={title}
              onClick={onShowItem}
              href={href}
            />
          ))}
        </Flex>
      ) : null}
      {users?.length ? (
        <Flex vertical>
          <Text className={s.search__title} variant="subheader-4">
            Аналитика
          </Text>
          {users.slice(0, 3).map(({ user_id, name, group_id, login }) => {
            const href = generatePath(AppRoute.Analytics, {
              group_id: String(group_id),
              user_id: String(user_id),
            });

            return (
              <SearchItem
                key={href}
                title={name || login}
                onClick={onShowItem}
                href={href}
              />
            );
          })}
        </Flex>
      ) : null}
      {reports?.length ? (
        <Flex vertical>
          <Text className={s.search__title} variant="subheader-4">
            История проверок
          </Text>
          {reports.map(({ type, request, id }) => {
            const href = `${generatePath(AppRoute.Report, {
              reportId: id,
            })}?type=${type}`;

            return (
              <SearchItem
                key={id}
                title={getReportTypeToTitleGetter(type)(request)}
                onClick={onShowItem}
                href={href}
              />
            );
          })}
        </Flex>
      ) : null}
      <Flex justify="center" className={s.search__more}>
        {users?.length || reports?.length ? (
          <Link
            to={`${generatePath(AppRoute.Search)}?query=${query}`}
            className={s.search__more_link}
          >
            <Button width="full" size="s" type="flat" onClick={onShowAll}>
              <Text color="accent">Показать все</Text>
            </Button>
          </Link>
        ) : (
          <Text color="secondary" className={s.search__more_placeholder}>
            Ничего не найдено
          </Text>
        )}
      </Flex>
    </Flex>
  );
};
