import type { FC } from "react";
import { memo, useLayoutEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "@app/routes/router";
import { env } from "@app/providers/AppFeatures/appConfig";
import { useAppFavicon } from "./hooks/useAppFavicon";
import { AppFeaturesProvider, AppThemeProvider } from "../providers";

export const App: FC = memo(() => {
  useLayoutEffect(() => {
    document.documentElement.dataset.theme = env.MODE;
  }, []);

  useAppFavicon();

  return (
    <AppThemeProvider>
      <AppFeaturesProvider>
        <RouterProvider router={router} />
      </AppFeaturesProvider>
    </AppThemeProvider>
  );
});

App.displayName = "App";
