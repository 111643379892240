import type { OnboardingPage } from "./OnboardingModal/types";

export class OnboardingModalStorage {
  static get(page: OnboardingPage): boolean | null {
    try {
      const showOnboarding = localStorage.getItem(page);
      return showOnboarding ? (JSON.parse(showOnboarding) as boolean) : null;
    } catch (e) {
      return null;
    }
  }

  static set(page: OnboardingPage, show: boolean) {
    localStorage.setItem(page, JSON.stringify(show));
  }
}
