import type { FC } from "react";
import type {
  ReportsFullDossierLightRetrieveResponse,
  ReportType,
} from "@femida1/schemas";
import { RetrieveFullDossierLightView } from "@femida1/gui_web_core";
import { RetrieveStatus } from "@entities/RetrieveStatus/RetrieveStatus";
import { useModalMessage, useNotification } from "@femida1/uikit";
import { usePdfGenerateHandlers } from "@app/api/pdf/usePdfGenerateHandlers";
import { datePickerLocale } from "@utils/date/datePickerLocale";
import { NoteModalFullDossier } from "@components/NoteModal/NoteModalFullDossier";
import { NoteModalMultiQueryFullDossier } from "@components/NoteModal/NoteModalMultiQueryFullDossier";
import { SearchByFound } from "@components/SearchByFound/SearchByFound";
import { RelatedPersonsModal } from "@components/RelatedPersonsModal/RelatedPersonsModal";
import { DownloadCsvButton } from "@components/DownloadCsvButton/DownloadCsvButton";

interface RetrieveFullDossierLightProps {
  report: ReportsFullDossierLightRetrieveResponse;
  reportId: string;
  type: ReportType;
}

export const RetrieveFullDossierLight: FC<RetrieveFullDossierLightProps> = ({
  report,
  reportId,
  type,
}) => {
  const { openModal, closeModal } = useModalMessage();

  const [api] = useNotification();

  const { onReportLoad, onReportPrint, isLoadingGeneratePdf } =
    usePdfGenerateHandlers({
      api,
      type,
      reportId,
    });

  return (
    <RetrieveStatus status={report.status}>
      <RetrieveFullDossierLightView
        datePickerLocale={datePickerLocale}
        report={report}
        reportType={type}
        relatedPersonSubtitle={<DownloadCsvButton reportId={reportId} />}
        onRelatedPersonShow={(possibleNetworks) =>
          openModal({
            children: (
              <RelatedPersonsModal
                possibleNetworks={possibleNetworks}
                onClose={closeModal}
              />
            ),
            maskClosable: true,
          })
        }
        onReportLoad={onReportLoad}
        onReportPrint={onReportPrint}
        isLoadingGeneratePdf={isLoadingGeneratePdf}
        renderNoteModal={() => (
          <NoteModalFullDossier reportId={reportId} type={type} />
        )}
        renderMultiQueryNoteModal={({ note }) => (
          <NoteModalMultiQueryFullDossier
            reportId={reportId}
            type={type}
            note={note}
          />
        )}
        renderSearchByFound={(props) => <SearchByFound {...props} />}
      />
    </RetrieveStatus>
  );
};
