import { MainTab } from "@pages/Main/constants";
import { mapMainTabToLabel } from "@utils/tabs/mapMainTabToLabel";
import {
  IoMdBriefcase,
  IoMdCar,
  IoMdInformationCircle,
  IoMdPerson,
} from "react-icons/io";
import {
  MdAssignment,
  MdFeaturedVideo,
  MdMapsHomeWork,
  MdOutlinePlaylistAddCheck,
  MdOutlineSpeed,
  MdPhoneIphone,
  MdPortrait,
  MdPublic,
} from "react-icons/md";
import { BsPassportFill } from "react-icons/bs";
import { IoMail } from "react-icons/io5";
import { FaTelegramPlane, FaVk } from "react-icons/fa";
import { MainTabTelegram } from "@pages/Main/components/MainTabs/tabs/MainTabTelegram/MainTabTelegram";
import { MainTabVk } from "@pages/Main/components/MainTabs/tabs/MainTabVk/MainTabVk";
import { MainTabInfo } from "@pages/Main/components/MainTabs/tabs/MainTabInfo/MainTabInfo";
import type { IMainTab } from "@pages/Main/components/MainTabs/types/IMainTab";
import { MainTabFullDossier } from "../tabs/MainTabFullDossier/MainTabFullDossier";
import { MainTabDriverLicense } from "../tabs/MainTabDriverLicense/MainTabDriverLicense";
import { MainTabCreditRating } from "../tabs/MainTabCreditRating/MainTabCreditRating";
import { MainTabCompany } from "../tabs/MainTabCompany/MainTabCompany";
import { MainTabPhoto } from "../tabs/MainTabPhoto/MainTabPhoto";
import { MainTabScoring } from "../tabs/MainTabScoring/MainTabScoring";
import { MainTabEmail } from "../tabs/MainTabEmail/MainTabEmail";
import { MainTabAuto } from "../tabs/MainTabAuto/MainTabAuto";
import { MainTabPassport } from "../tabs/MainTabPassport/MainTabPassport";
import { MainTabInnOrSnils } from "../tabs/MainTabInnOrSnils/MainTabInnOrSnils";
import { MainTabPhone } from "../tabs/MainTabPhone/MainTabPhone";
import { MainTabAddress } from "../tabs/MainTabAddress/MainTabAddress";
import { MainTabFio } from "../tabs/MainTabFio/MainTabFio";
import { MainTabFullDossierLight } from "../tabs/MainTabFullDossierLight/MainTabFullDossierLight";
import { MainTabPhotoLight } from "../tabs/MainTabPhotoLight/MainTabPhotoLight";

export const mapMainTabs: Record<MainTab, IMainTab> = {
  [MainTab.FullDossier]: {
    key: MainTab.FullDossier,
    Icon: MdPublic,
    label: mapMainTabToLabel[MainTab.FullDossier],
    TabComponent: MainTabFullDossier,
    hint: "Проверка чувствительна к количеству заполненных полей. Чем больше информации вы предоставите, тем более точное досье получите.",
  },
  [MainTab.FullDossierLight]: {
    key: MainTab.FullDossierLight,
    Icon: MdPublic,
    label: mapMainTabToLabel[MainTab.FullDossierLight],
    TabComponent: MainTabFullDossierLight,
    hint: "Проверка чувствительна к количеству заполненных полей. Чем больше информации вы предоставите, тем более точное досье получите.",
  },
  [MainTab.Fio]: {
    key: MainTab.Fio,
    Icon: IoMdPerson,
    label: mapMainTabToLabel[MainTab.Fio],
    TabComponent: MainTabFio,
    hint: "Доступен поиск по неполным данным",
  },
  [MainTab.Phone]: {
    key: MainTab.Phone,
    Icon: MdPhoneIphone,
    label: mapMainTabToLabel[MainTab.Phone],
    TabComponent: MainTabPhone,
    hint: "",
  },
  [MainTab.PhoneLight]: {
    key: MainTab.PhoneLight,
    Icon: MdPhoneIphone,
    label: mapMainTabToLabel[MainTab.PhoneLight],
    TabComponent: MainTabPhone,
    hint: "",
  },
  [MainTab.Address]: {
    key: MainTab.Address,
    Icon: MdMapsHomeWork,
    label: mapMainTabToLabel[MainTab.Address],
    TabComponent: MainTabAddress,
    hint: "Для поиска по кадастровому номеру или адресу используйте переключатель",
  },
  [MainTab.InnOrSnils]: {
    key: MainTab.InnOrSnils,
    Icon: MdAssignment,
    label: mapMainTabToLabel[MainTab.InnOrSnils],
    TabComponent: MainTabInnOrSnils,
    hint: "Для поиска по СНИЛС или по номеру ИНН используйте переключатель",
  },
  [MainTab.Passport]: {
    key: MainTab.Passport,
    Icon: BsPassportFill,
    label: mapMainTabToLabel[MainTab.Passport],
    TabComponent: MainTabPassport,
    hint: "Заполните поле без пробелов и знаков препинания",
  },
  [MainTab.Auto]: {
    key: MainTab.Auto,
    Icon: IoMdCar,
    label: mapMainTabToLabel[MainTab.Auto],
    TabComponent: MainTabAuto,
    hint: "Для поиска транспорта по VIN или гос. номеру используйте переключатель",
  },
  [MainTab.DriverLicense]: {
    key: MainTab.DriverLicense,
    Icon: MdFeaturedVideo,
    label: mapMainTabToLabel[MainTab.DriverLicense],
    TabComponent: MainTabDriverLicense,
    hint: "Введите номер без пробелов",
  },
  [MainTab.Email]: {
    key: MainTab.Email,
    Icon: IoMail,
    label: mapMainTabToLabel[MainTab.Email],
    TabComponent: MainTabEmail,
    hint: "",
  },
  [MainTab.EmailLight]: {
    key: MainTab.EmailLight,
    Icon: IoMail,
    label: mapMainTabToLabel[MainTab.EmailLight],
    TabComponent: MainTabEmail,
    hint: "",
  },
  [MainTab.Company]: {
    key: MainTab.Company,
    Icon: IoMdBriefcase,
    label: mapMainTabToLabel[MainTab.Company],
    TabComponent: MainTabCompany,
    hint: "Для поиска юридического лица по номеру ОГРН или ИНН используйте переключатель",
  },
  [MainTab.Photo]: {
    key: MainTab.Photo,
    Icon: MdPortrait,
    label: mapMainTabToLabel[MainTab.Photo],
    TabComponent: MainTabPhoto,
    hint: "",
  },
  [MainTab.PhotoLight]: {
    key: MainTab.PhotoLight,
    Icon: MdPortrait,
    label: mapMainTabToLabel[MainTab.PhotoLight],
    TabComponent: MainTabPhotoLight,
    hint: "",
  },
  [MainTab.CreditRating]: {
    key: MainTab.CreditRating,
    Icon: MdOutlinePlaylistAddCheck,
    label: mapMainTabToLabel[MainTab.CreditRating],
    TabComponent: MainTabCreditRating,
    hint: "",
  },
  [MainTab.Scoring]: {
    key: MainTab.Scoring,
    Icon: MdOutlineSpeed,
    label: mapMainTabToLabel[MainTab.Scoring],
    TabComponent: MainTabScoring,
    hint: "",
  },
  [MainTab.Info]: {
    key: MainTab.Info,
    Icon: IoMdInformationCircle,
    label: mapMainTabToLabel[MainTab.Info],
    TabComponent: MainTabInfo,
    hint: "Для поиска по ключевому слову введите не менее 10 символов. Для точности заключите фразу в кавычки",
  },
  [MainTab.Vk]: {
    key: MainTab.Vk,
    Icon: FaVk,
    label: mapMainTabToLabel[MainTab.Vk],
    TabComponent: MainTabVk,
    hint: "",
  },
  [MainTab.Telegram]: {
    key: MainTab.Telegram,
    Icon: FaTelegramPlane,
    label: mapMainTabToLabel[MainTab.Telegram],
    TabComponent: MainTabTelegram,
    hint: "Для поиска по Telegram введите номер телефона, логин или ID",
  },
};
