import type { FC } from "react";
import { useCallback } from "react";
import { Flex, Input } from "@femida1/uikit";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitButton } from "@pages/Main/components/MainTabs/components/SubmitButton/SubmitButton";
import type {
  MainTabFieldValues,
  MainTabProps,
} from "@pages/Main/components/MainTabs/types";
import { dropZoneFileListSchema } from "@pages/Main/components/MainTabs/constants/dropZoneFileListSchema";
import type { ReportsVinSearchRequest } from "@femida1/schemas";
import { useReportsVinSearch } from "@femida1/gui_web_core";
import { transformStringToUpperCase } from "@utils/transformers/transformStringToUpperCase";
import { validationBounds, validationMessage } from "@utils/constants";
import { correctVinRegexCallback } from "@utils/validation";
import s from "../MainTabAuto.module.scss";

interface ReportsVinFieldValues
  extends ReportsVinSearchRequest,
    MainTabFieldValues {}

const schema = yup
  .object()
  .shape({
    vin: yup
      .string()
      .transform(transformStringToUpperCase)
      .length(validationBounds.Vin.Length, validationMessage.WrongVin)
      .test("vin-check", validationMessage.WrongVin, correctVinRegexCallback)
      .required(validationMessage.Required),
    sopdFileList: dropZoneFileListSchema,
  })
  .required();

type MainTabAutoVinProps = MainTabProps;

export const MainTabAutoVin: FC<MainTabAutoVinProps> = ({ onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      vin: "",
    },
  });

  const { mutateAsync: search, isPending: isSearchPending } =
    useReportsVinSearch();

  const onSubmitInner = useCallback(
    (requestData: ReportsVinFieldValues) =>
      onSubmit({ requestData, search, withSopd: true, onSuccess: reset }),
    [onSubmit, search, reset],
  );

  return (
    <form onSubmit={handleSubmit(onSubmitInner)}>
      <Flex gap={12} className={s.controllersContainer}>
        <Controller
          name="vin"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              required
              placeholder="Номер VIN"
              mask={"*".repeat(validationBounds.Vin.Length)}
              {...field}
              value={field.value.toUpperCase()}
              onChange={(e) => {
                e.target.value = e.target.value.toUpperCase();
                field.onChange(e);
              }}
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="sopdFileList"
          control={control}
          render={({ field }) => (
            <SubmitButton
              fileList={field.value}
              onFileListChange={field.onChange}
              disabled={!isValid || isSearchPending}
            />
          )}
        />
      </Flex>
    </form>
  );
};
