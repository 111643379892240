import type { CurrentUser } from "@femida1/schemas";
import { Permission } from "@femida1/schemas";

export const isUserAdministrator = (user?: CurrentUser): boolean => {
  if (!user) return false;

  const userPermissions = user?.groups?.[0]?.permissions;
  if (!Array.isArray(userPermissions)) return false;

  const userPermissionsSet = new Set(userPermissions);

  return (
    userPermissionsSet.has(Permission.SuperuserPermission) ||
    userPermissionsSet.has(Permission.AdminPermission)
  );
};
