import type { FC } from "react";
import { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Flex, Input } from "@femida1/uikit";
import * as yup from "yup";
import { validationMessage } from "@utils/constants";
import { correctUrlRegexCallback } from "@utils/validation";
import { dropZoneFileListSchema } from "@pages/Main/components/MainTabs/constants/dropZoneFileListSchema";
import { SOPDModal } from "@pages/Main/components/MainTabs/components/SOPDModal/SOPDModal";
import { useReportsVkSearch } from "@femida1/gui_web_core";
import { useAppFeatures } from "@app/providers";
import s from "./MainTabVk.module.scss";
import type { MainTabFieldValues, MainTabProps } from "../../types";
import { SubmitButton } from "../../components/SubmitButton/SubmitButton";

interface ReportsVkFieldValues extends MainTabFieldValues {
  url: string;
}

const schema = yup
  .object<ReportsVkFieldValues>()
  .shape({
    url: yup
      .string()
      .test("test-url", validationMessage.WrongUrl, correctUrlRegexCallback)
      .required(),
    sopdFileList: dropZoneFileListSchema,
  })
  .required();

type MainTabScoringProps = MainTabProps;

export const MainTabVk: FC<MainTabScoringProps> = ({ onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm<ReportsVkFieldValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      url: "",
    },
  });

  const { mutateAsync: search, isPending: isSearchPending } =
    useReportsVkSearch();

  const onSubmitInner = useCallback(
    (data: ReportsVkFieldValues) =>
      onSubmit({ requestData: data, search, withSopd: true, onSuccess: reset }),
    [search, onSubmit, reset],
  );

  const { tabVkSopdEnabled } = useAppFeatures();

  return (
    <form className={s.form} onSubmit={handleSubmit(onSubmitInner)}>
      <Flex vertical gap={12}>
        <Flex gap={12} className={s.controllersContainer}>
          <Controller
            name="url"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                className={s.input}
                tabIndex={0}
                required
                placeholder="Ссылка"
                {...field}
                validate={fieldState}
              />
            )}
          />
          <SubmitButton disabled={!isValid || isSearchPending} />
        </Flex>
        {tabVkSopdEnabled ? (
          <div className={s.inputsContainer}>
            <Controller
              name="sopdFileList"
              control={control}
              render={({ field }) => (
                <SOPDModal
                  fileList={field.value || []}
                  onFileListChange={field.onChange}
                />
              )}
            />
          </div>
        ) : null}
      </Flex>
    </form>
  );
};
