import { PageHeader } from "@app/layouts/PageHeader/PageHeader";
import type { KeyboardEventHandler } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import cn from "classnames";
import { Controller, useForm } from "react-hook-form";
import {
  Text,
  TextArea,
  Button,
  Loading,
  Block,
  AddTicket,
  Send,
} from "@femida1/uikit";
import * as yup from "yup";
import type {
  Chat,
  Message,
  ChatResponse,
  MessageResponse,
} from "@femida1/schemas";
import {
  useGetChats,
  useGetMessages,
  useCreateMessage,
  useFileMessage,
} from "@femida1/gui_web_core";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import { IoMdAttach } from "react-icons/io";
import { AppRoute } from "@app/routes/constants";
import { Link } from "react-router-dom";
import { useAppFeatures } from "@app/providers";
import s from "./SupportChat.module.scss";
import { CardChat } from "../CardChat/CardChat";
import { CardMessage } from "../CardMessage/CardMessage";
import { CurrentDate } from "../CurrentDate/CurrentDate";
import { Sentinel } from "../Sentinel/Sentinel";
import { AddTicketModal } from "../AddTicketModal/AddTicketModal";

function getDate(isoDateStr: string): string {
  try {
    const date = dayjs(isoDateStr);
    return date.format("DD.MM.YYYY");
  } catch {
    return "";
  }
}

function getLocaleDate(isoDateStr: string): string {
  try {
    const date = dayjs(isoDateStr);
    return date.locale("ru").format("DD MMMM YYYY");
  } catch {
    return "";
  }
}

function getTime(isoDateStr: string): string {
  try {
    const date = dayjs(isoDateStr);
    return date.format("HH:mm");
  } catch {
    return "";
  }
}

function isNowDate(isoDateStr: string): boolean {
  try {
    const date = dayjs(isoDateStr);
    const now = dayjs(new Date().toISOString());
    return date.format("DD.MM.YYYY") === now.format("DD.MM.YYYY");
  } catch {
    return false;
  }
}

function isLocaleNowDate(isoDateStr: string): boolean {
  try {
    const now = dayjs(new Date().toISOString());
    return isoDateStr === now.locale("ru").format("DD MMMM YYYY");
  } catch {
    return false;
  }
}

const sortChats = (a: Chat, b: Chat) => {
  const bDate = b?.created_at ? new Date(b?.created_at).getTime() : 0;
  const aDate = a?.created_at ? new Date(a?.created_at).getTime() : 0;
  return bDate - aDate;
};

const sortMessages = (a: Message, b: Message) => {
  const bDate = new Date(b.created_at).getTime();
  const aDate = new Date(a.created_at).getTime();
  return aDate - bDate;
};

const schema = yup
  .object()
  .shape({
    question: yup.string(),
    file: yup
      .mixed<FileList>()
      .test(
        "fileCount",
        "Можно загрузить только 1 файл",
        (value) => value?.length === 1,
      )
      .test("fileSize", "Файл слишком большой (макс. 20 МБ)", (value) =>
        value?.[0] ? value[0].size <= 20 * 1024 * 1024 : true,
      )
      .test("fileType", "Неверный формат файла", (value) =>
        value?.[0]
          ? [
              "image/jpeg",
              "image/jpg",
              "image/png",
              "application/pdf",
              "application/msword",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ].includes(value[0].type)
          : true,
      )
      .test(
        "fileNotForbidden",
        "Файл имеет недопустимое расширение",
        (value) => {
          if (!value?.[0]) return true;
          const forbiddenExtensions = [
            ".mp4",
            ".avi",
            ".mkv",
            ".mov",
            ".mp3",
            ".wav",
            ".ogg",
            ".xls",
            ".xlsx",
            ".ppt",
            ".pptx",
            ".zip",
            ".rar",
            ".7z",
            ".tar",
            ".gif",
            ".bmp",
            ".exe",
            ".bat",
            ".sh",
            ".js",
            ".jsx",
            ".py",
            ".html",
            ".php",
          ];
          const fileName = value[0]?.name.toLowerCase();
          return !forbiddenExtensions.some((ext) => fileName.endsWith(ext));
        },
      ),
  })
  .required();

type MapMessageType = { date: string; messages: Message[] }[];

export const SupportChat = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const chatRef = useRef<HTMLDivElement>(null);
  const [hiddenDates, setHiddenDates] = useState<string[]>([]);
  const [chats, setChats] = useState<ChatResponse | null>(null);
  const [messages, setMessages] = useState<MessageResponse | null>(null);
  const [currentChat, setCurrentChat] = useState<Chat | undefined>();
  const { mutateAsync: getChats, isPending: isLoadingChats } = useGetChats();
  const { mutateAsync: getMessages, isPending: isLoadingMessages } =
    useGetMessages();
  const { mutateAsync: createMessage } = useCreateMessage();
  const { mutateAsync: downloadFile } = useFileMessage();
  const { chatsAvailable } = useAppFeatures();

  const {
    control,
    watch,
    resetField,
    setValue,
    formState: { errors },
  } = useForm<{
    question?: string;
    file?: FileList;
  }>({
    defaultValues: {
      question: "",
    },
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const hasError = Object.keys(errors).length > 0;

  const file = watch("file");

  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const getChatsHandler = async () => {
    const data = await getChats({
      limit: 500,
      offset: 0,
    });
    setChats(data);
  };

  const getMessagesHandler = async () => {
    if (currentChat) {
      const data = await getMessages({
        support_chat_id: Number(currentChat.id),
        limit: 500,
        offset: 0,
      });
      setMessages(data);
    }
  };

  useEffect(() => {
    if (chatsAvailable) getChatsHandler();
  }, [chatsAvailable]);

  useEffect(() => {
    if (chatsAvailable) getMessagesHandler();
  }, [chatsAvailable, currentChat]);

  useEffect(() => {
    chatRef.current?.scrollTo(0, chatRef.current.scrollHeight);
  }, [messages]);

  const onHiddenHandler = (value: boolean, date: string) => {
    setHiddenDates((dates) =>
      value ? [...dates, date] : dates.filter((item) => item !== date),
    );
  };

  const isHidden = (date: string) =>
    !!hiddenDates.find((value) => value === date);

  const mapMessages = useMemo<MapMessageType>(
    () =>
      [
        ...new Set(
          messages?.results
            ?.sort(sortMessages)
            .map((value) => getLocaleDate(value.created_at)),
        ),
      ].map((date) => ({
        date,
        messages:
          messages?.results?.filter(
            (message) => date === getLocaleDate(message.created_at),
          ) || [],
      })),
    [messages],
  );

  const onCurrentChatHandler = (chat: Chat) => {
    setCurrentChat(chat);
  };

  const onCreateMessageHandler = async () => {
    if (currentChat) {
      const question = watch("question");
      const data = await createMessage({
        chat_id: currentChat.id,
        message: question ?? "",
        file: file?.[0],
      });
      if (data) {
        resetField("question");
        resetField("file");
        getMessagesHandler();
      }
    }
  };

  const keyUp: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.nativeEvent.key === "Enter" && !e.shiftKey) {
      onCreateMessageHandler();
    }
  };

  const handleRemoveFile = () => {
    setValue("file", undefined);
  };

  if (isLoadingChats) {
    return <Loading />;
  }
  return (
    <div onKeyUp={keyUp} className={s.container}>
      <PageHeader
        title={!currentChat ? "Техническая поддержка" : currentChat.subject}
        mapPathToTitle={{
          [AppRoute.Support]: "Техническая поддержка",
        }}
        paths={[AppRoute.Support]}
        hasBreadCrumbs={!currentChat}
        onClick={!currentChat ? undefined : () => setCurrentChat(undefined)}
        prev={!currentChat ? undefined : AppRoute.Support}
      />
      {chatsAvailable ? (
        <>
          {!currentChat ? (
            <div
              className={cn(
                s.block,
                chats?.count && chats?.count > 0
                  ? s.with_chats
                  : s.without_chats,
              )}
            >
              <div className={s.header}>
                <Text variant="subheader-5">Тикеты</Text>
              </div>
              {chats?.count && chats?.count > 0 ? (
                <>
                  <div className={s.list}>
                    {chats?.results?.sort(sortChats).map((chat: Chat) => {
                      let date = "";
                      if (chat?.created_at) date = getDate(chat.created_at);
                      if (chat?.created_at && isNowDate(chat?.created_at))
                        date = `Сегодня ${getTime(chat.created_at)}`;

                      return (
                        <CardChat
                          key={chat.id}
                          date={date}
                          title={chat.subject}
                          message={chat.question}
                          onClick={() => onCurrentChatHandler(chat)}
                        />
                      );
                    })}
                  </div>
                  <div className={s.footer}>
                    <Button
                      iconRight={<AddTicket width={20} height={20} />}
                      size="l"
                      width="full"
                      onClick={openModal}
                    >
                      Создать тикет
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div className={s.list}>
                    <Text variant="subheader-3" color="secondary">
                      Добавьте тикет, чтоб мы могли сделать наш сервис лучше
                    </Text>
                  </div>
                  <div className={s.footer}>
                    <Button
                      iconRight={<AddTicket width={20} height={20} />}
                      size="l"
                      width="full"
                      onClick={openModal}
                    >
                      Создать тикет
                    </Button>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className={cn(s.block, s.messages)}>
              <div ref={chatRef} className={s.list_messages}>
                {mapMessages.map((item) => (
                  <div className={s.messages_wrapper} key={item.date}>
                    <Sentinel
                      onHidden={(value) => onHiddenHandler(value, item.date)}
                    />
                    <CurrentDate
                      isHidden={isHidden(item.date)}
                      date={isLocaleNowDate(item.date) ? `Сегодня` : item.date}
                    />
                    {item.messages.sort(sortMessages).map((message) => (
                      <CardMessage
                        key={message.id}
                        date={getTime(message.created_at)}
                        message={message.message}
                        isSupport={message.support_reply}
                        fileName={message?.files?.[0]}
                        onFileClick={() =>
                          downloadFile({
                            filename: message?.files?.[0],
                            id: message.id,
                          })
                        }
                      />
                    ))}
                  </div>
                ))}
                {isLoadingMessages && <Loading />}
              </div>
              <div className={s.footer}>
                <button
                  type="button"
                  className={s.button}
                  onClick={onCreateMessageHandler}
                  disabled={hasError}
                >
                  <Send width={20} height={20} />
                </button>
                <Controller
                  name="file"
                  control={control}
                  render={({ field }) => (
                    <div className={s.fileInputWrapper}>
                      <input
                        type="file"
                        id="fileInput"
                        onChange={(e) => field.onChange(e.target.files)}
                        style={{ display: "none" }}
                        aria-label="file"
                      />
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="fileInput" className={s.attach}>
                        <IoMdAttach size={20} />
                      </label>
                      {file?.[0] && (
                        <div className={s.attach__message}>
                          <span>
                            {errors?.file
                              ? errors?.file?.message
                              : file[0]?.name}
                          </span>
                          <button type="button" onClick={handleRemoveFile}>
                            Удалить
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                />
                <Controller
                  name="question"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextArea
                      {...field}
                      onPressEnter={(e) => !e.shiftKey && e.preventDefault()}
                      placeholder="Сообщение"
                      autoSize={{ minRows: 1, maxRows: 6 }}
                      validate={fieldState}
                      withIcon
                    />
                  )}
                />
              </div>
            </div>
          )}
          <AddTicketModal
            getChats={getChatsHandler}
            onClose={closeModal}
            isModalOpen={isModalOpen}
          />
        </>
      ) : (
        <Block>
          <Text variant="subheader-4">
            По всем вопросам обращаться на{" "}
            <Link
              target="_blank"
              to="https://mail.google.com/mail/?view=cm&fs=1&to=support@odi.is"
            >
              почту
            </Link>
          </Text>
        </Block>
      )}
    </div>
  );
};
