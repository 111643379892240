import { Text, Flex, NotesPin } from "@femida1/uikit";
import type { ReportType } from "@femida1/schemas";
import type { FC } from "react";
import { NoteModal } from "./NoteModal";

interface NoteModalFullDossierProps {
  reportId: string;
  type: ReportType;
}

export const NoteModalFullDossier: FC<NoteModalFullDossierProps> = ({
  reportId,
  type,
}) => {
  return (
    <NoteModal
      type={type}
      reportId={reportId}
      buttonTitle={
        <Text variant="body-4" color="secondary">
          <Flex align="center" gap={4}>
            <NotesPin /> Добавить заметку
          </Flex>
        </Text>
      }
    />
  );
};
