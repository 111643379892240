import { useParams } from "react-router-dom";
import { useReportsInnUlRetrieve } from "@femida1/gui_web_core";
import { SearchFull } from "@entities/SearchFull/SearchFull";
import { Skeleton } from "antd";
import type { ReportCommonProps } from "@femida1/schemas";

export const SearchInnUlWidget = ({
  type,
}: Omit<ReportCommonProps, "reportId">) => {
  const { reportId = "" } = useParams();

  const { data: innUlReport, isLoading } = useReportsInnUlRetrieve({
    id: reportId,
  });

  return (
    <Skeleton loading={isLoading}>
      {innUlReport && (
        <SearchFull report={innUlReport} type={type} reportId={reportId} />
      )}
    </Skeleton>
  );
};
