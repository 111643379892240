import { useCallback, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { AuthForm } from "@components/AuthForm/AuthForm";
import { useCurrentUserQuery } from "@femida1/gui_web_core";
import { useBooleanState } from "@femida1/uikit";

export const PrivateRoute = () => {
  const {
    isSuccess: isUserSuccess,
    refetch: refetchUser,
    isPending,
  } = useCurrentUserQuery();
  const {
    state: isAuthModalVisible,
    setTrue: showAuthModal,
    setFalse: hideAuthModal,
  } = useBooleanState(false);

  const onSuccess = useCallback(() => {
    refetchUser().catch(() => {});

    hideAuthModal();
  }, [refetchUser, hideAuthModal]);

  useEffect(() => {
    if (!isPending && !isUserSuccess) {
      showAuthModal();
    }
  }, [isUserSuccess, onSuccess, isPending, showAuthModal]);

  return (
    <>
      <Outlet />
      <AuthForm
        onSuccess={onSuccess}
        onClose={hideAuthModal}
        modalVisible={isAuthModalVisible}
      />
    </>
  );
};
