import type { MenuItem } from "@app/layouts/MainLayout/components/types";
import { MdAnalytics, MdPolicy, MdSnippetFolder } from "react-icons/md";
import { IoDocumentText } from "react-icons/io5";
import { MenuGroup } from "@app/layouts/MainLayout/components/constants";
import { AppRoute } from "@app/routes/constants";
import { FaTelegram } from "react-icons/fa";

export const mapMenuGroupItems: Record<
  MenuGroup,
  Omit<MenuItem, "children">
> = {
  [MenuGroup.Checking]: {
    icon: <MdPolicy size={24} />,
    key: MenuGroup.Checking,
    meta: "проверка",
    title: "Проверка",
  },
  [MenuGroup.Cabinet]: {
    icon: <MdAnalytics size={24} />,
    key: MenuGroup.Cabinet,
    meta: "кабинет,cabinet",
    title: "Кабинет",
  },
  [MenuGroup.Organization]: {
    icon: <MdSnippetFolder size={24} />,
    key: MenuGroup.Organization,
    meta: "организация,organization",
    title: "Организация",
  },
  [MenuGroup.Info]: {
    icon: <IoDocumentText size={24} />,
    key: MenuGroup.Info,
    meta: "инфо,info,информация,information",
    title: "Инфо",
  },
  [MenuGroup.Telegram]: {
    href: AppRoute.Telegram,
    icon: <FaTelegram size={24} />,
    key: MenuGroup.Telegram,
    meta: "телеграм бот,telegram bot,telegram,bot",
    title: "Телеграм бот",
  },
};
