import type { OnboardingPage, OnboardingSlide } from "./types";

const sourceImagePath = "/onboarding_assets/";
export const mapOnboardingContent: Record<OnboardingPage, OnboardingSlide[]> = {
  main: [
    {
      image: `${sourceImagePath}main/1.webp`,
      title: "Добро пожаловать в ODYSSEY SEARCH",
      label:
        "Главная страница - это страница для совершения ваших проверок по разным типам данных. В системе присутствует возможность делать массовые запросы из файла (запрос по ФИО),  а также загружать СОПД (Согласие на обработку персональных данных).",
    },
    {
      image: `${sourceImagePath}main/2.webp`,
      title: "История проверок",
      label:
        "Здесь отображаются сделанные проверки по каждому типу. Более детально ознакомиться можно на странице История проверок.",
    },
    {
      image: `${sourceImagePath}main/3.webp`,
      title: "Быстро выбирайте нужный тип",
      label:
        "Используя панель, можно легко и быстро выбрать нужный тип проверки.",
    },
    {
      image: `${sourceImagePath}main/4.webp`,
      title: "Навигация по типам проверки",
      label: "Также есть возможность выбрать тип проверки через левое меню.",
    },
    {
      image: `${sourceImagePath}main/5.webp`,
      title: "Навигация по кабинету",
      label:
        "Собран весь фунционал кабинета: аналитика, история проверок, мониторинг сотрудников, папки и задачи.",
    },
    {
      image: `${sourceImagePath}main/6.webp`,
      title: "Навигация по организации",
      label:
        "Помощь в управлении организации: журнал действий, настройка доступов и поддержка.",
    },
    {
      image: `${sourceImagePath}main/7.webp`,
      title: "Навигация по инфо",
      label:
        "Сосредоточена вся информация, которая может быть полезна в работе с сервисом.",
    },
    {
      image: `${sourceImagePath}main/8.webp`,
      title: "Личный кабинет и настройки",
      label:
        "Включает в себя: состояние баланса, список документов, а также настройка аккаунта.",
    },
    {
      image: `${sourceImagePath}main/9.webp`,
      title: "Уведомления",
      label: "Собраны все уведомления, чтобы ничего не упустить.",
    },
  ],
  analytics: [
    {
      image: `${sourceImagePath}analytics/1.webp`,
      title: "Проверки на временом отрезке",
      label:
        "Отображаются все совершённые проверки за выбранный промежуток времени, а также есть возможность сравнивать их с проверками по выбранному типу.",
    },
    {
      image: `${sourceImagePath}analytics/2.webp`,
      title: "Аналитика по типу проверок",
      label: "Возможность анализа всех проверок по их типам.",
    },
    {
      image: `${sourceImagePath}analytics/3.webp`,
      title: "Настройки отображения и фильтры",
      label:
        "Гибкие фильтры для настройки отображения графиков, с возможностью получения более точной аналитики.",
    },
  ],
  employees: [
    {
      image: `${sourceImagePath}employees/1.webp`,
      title: "Мониторинг сотрудников",
      label:
        "Список добавленных сотрудников, по которым с определенной периодичностью автоматически проходят проверки. Данные поступают из открытых источников и автоматически обновляются каждые 14 дней с момента добавление сотрудника в список мониторинга.",
    },
    {
      image: `${sourceImagePath}employees/2.webp`,
      title: "Ручное обновление",
      label:
        "Возможность обновить данные по каждому сотруднику отдельно. В этом случае время следующего обновления начнется с даты последнего обновления.",
    },
    {
      image: `${sourceImagePath}employees/3.webp`,
      title: "Дополнительные действия",
      label:
        "При нажатии на меню есть возможность ознакомиться со сводкой по сотруднику, редактировать его данные или удалить из списка мониторинга.",
    },
    {
      image: `${sourceImagePath}employees/4.webp`,
      title: "Добавление сотрудников",
      label:
        "Кнопки в правом верхнем углу дают возможность добавить сотрудника вручную или массовым запросом из файла со списком сотрудников, предварительно заполненный в формате таблицы CSV.",
    },
  ],
  history: [
    {
      image: `${sourceImagePath}history/1.webp`,
      title: "История проверок в одном месте",
      label:
        "Все проверки собраны в одном месте. Легкий просмотр совершенных проверок. Карточки проверок с наглядным отображением информации, что позволяет быстро ориентироваться и переходить к сформированному отчету.",
    },
    {
      image: `${sourceImagePath}history/2.webp`,
      title: "Поиск, фильтры и сортировка",
      label:
        "Поиск позволяет быстро перейти к нужному запросу. Также в этом могут помочь сортировка по времени и фильтры.",
    },
    {
      image: `${sourceImagePath}history/3.webp`,
      title: "Работа с результатами запроса",
      label:
        "Удобный способ оставлять заметки для сформированного запроса. Возможность удалить запрос из истории.",
    },
  ],
  support: [
    {
      image: `${sourceImagePath}support/1.webp`,
      title: "Тикеты в службу поддержки",
      label:
        "При возникновении проблем или предложений есть возможность создать тикет в службу поддержки сервиса. Обращения рассматриваются быстро и оперативно. Также раздел позволяет следить за статусом тикета.",
    },
    {
      image: `${sourceImagePath}support/2.webp`,
      title: "Чат поддержки",
      label:
        "Общайтесь в чате тикета с живым человеком, мы всегда подробно погружаемся в тему вашего вопроса. В чат можно прикреплять файлы, если в этом есть необходимость.",
    },
  ],
  accesses: [
    {
      image: `${sourceImagePath}accesses/1.webp`,
      title: "Легкое управление доступами к сервису",
      label:
        "Управление доступами, быстрое включение или отключение возможности использования сервиса для определённого аккаунта. Выдача доступов сотрудникам и изменение прав уже существующих пользователей.",
    },
    {
      image: `${sourceImagePath}accesses/2.webp`,
      title: "Добавление нового доступа",
      label: "Добавить новый доступ поможет кнопка в левом верхнем углу блока.",
    },
    {
      image: `${sourceImagePath}accesses/3.webp`,
      title: "Аналитика для предоставленного доступа",
      label:
        "Отображена статистика аккаунта, выполненные запросы и авторизации через контекстное меню. Также возможность удалить доступ или редактировать права, перейдя в соответствующие разделы через контекстное меню.",
    },
  ],
};
