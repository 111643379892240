import { MainTab } from "@pages/Main/constants";

export const mapMainTabToLabel: Record<MainTab, string> = {
  [MainTab.FullDossier]: "Полное досье",
  [MainTab.FullDossierLight]: "Полное досье",
  [MainTab.Fio]: "По ФИО",
  [MainTab.Phone]: "По телефону",
  [MainTab.PhoneLight]: "По телефону",
  [MainTab.Address]: "По адресу",
  [MainTab.InnOrSnils]: "По ИНН или СНИЛС",
  [MainTab.Passport]: "По паспорту",
  [MainTab.Auto]: "По номеру авто",
  [MainTab.DriverLicense]: "По ВУ",
  [MainTab.Email]: "По Email",
  [MainTab.EmailLight]: "По Email",
  [MainTab.Company]: "По юридическим лицам",
  [MainTab.Photo]: "По фото",
  [MainTab.PhotoLight]: "По фото",
  [MainTab.CreditRating]: "Кредитный рейтинг",
  [MainTab.Scoring]: "Скоринг",
  [MainTab.Info]: "По ключевому слову",
  [MainTab.Telegram]: "По Telegram",
  [MainTab.Vk]: "По ВКонтакте",
};
