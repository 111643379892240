import {
  Flex,
  Text,
  useNotification,
  useBooleanState,
  NotesPin,
} from "@femida1/uikit";
import cn from "classnames";
import type { FC } from "react";
import { useMemo } from "react";
import type {
  ReportCommonProps,
  PersonInfo,
  MultiQuery,
} from "@femida1/schemas";
import { validationRegex } from "@utils/constants";
import { QueryClientProvider } from "@tanstack/react-query";
import {
  femidaQueryClient,
  DownloadReportPdfButton,
  OptionsMenu,
  RetrieveCard,
  RetrieveCardPrint,
} from "@femida1/gui_web_core";
import { PersonInfoKeys, ReportType } from "@femida1/schemas";
import { MdEdit } from "react-icons/md";
import styles from "@entities/RetrievePhotoByFace/RetrievePhotoByFace.module.scss";
import type { Option, RetrieveCardCommonProps } from "@femida1/gui_web_core";
import { NoteModal } from "@components/NoteModal";
import s from "./ReportHeader.module.scss";
import { SearchByFound } from "../SearchByFound/SearchByFound";

type TReportHeaderProps = ReportCommonProps & {
  multiQueryReport?: MultiQuery;
  requestDescribe?: string;
  note?: string | null;
  onReportLoad?: () => void;
  onReportPrint?: () => Promise<void>;
  isLoadingGeneratePdf?: boolean;
  title?: string;
} & RetrieveCardCommonProps;

export const ReportHeader: FC<TReportHeaderProps> = ({
  multiQueryReport,
  requestDescribe,
  status,
  print = RetrieveCardPrint.device,
  note,
  type,
  reportId,
  onReportLoad,
  onReportPrint,
  isLoadingGeneratePdf,
  title = "Мультизапрос",
}) => {
  const isPrintView = print === RetrieveCardPrint.print;
  const requestDescribeWithoutDob = requestDescribe
    ?.replace(validationRegex.date, "")
    .replace(/\.+$/, "")
    .trim();
  const report: PersonInfo | undefined = useMemo(() => {
    if (multiQueryReport?.result) {
      return {
        passports: Object.values(multiQueryReport.result.passport)
          .flatMap((arr) => arr[PersonInfoKeys.passports])
          .filter((item) => typeof item === "string"),
        phones: Object.values(multiQueryReport.result.phone)
          .flatMap((arr) => arr[PersonInfoKeys.phones])
          .filter((item) => typeof item === "string"),
        autos: Object.values(multiQueryReport.result.grn)
          .flatMap((arr) => arr[PersonInfoKeys.autos])
          .filter((item) => typeof item === "string"),
        snilses: Object.values(multiQueryReport.result.snils)
          .flatMap((arr) => arr[PersonInfoKeys.snilses])
          .filter((item) => typeof item === "string"),
        inns: Object.values(multiQueryReport.result.inn)
          .flatMap((arr) => arr[PersonInfoKeys.inns])
          .filter((item) => typeof item === "string"),
        emails: Object.values(multiQueryReport.result.email)
          .flatMap((arr) => arr[PersonInfoKeys.emails])
          .filter((item) => typeof item === "string"),
      };
    }
    return {
      passports: [],
      phones: [],
      autos: [],
      snilses: [],
      inns: [],
      emails: [],
    };
  }, [multiQueryReport]);

  const {
    state: isSearchByFoundModalVisible,
    setTrue: showSearchByFoundModal,
    setFalse: hideSearchByFoundModal,
  } = useBooleanState(false);
  const [copyReportUrl] = useNotification();

  const options = useMemo<Option[]>(
    () =>
      [
        { text: "Поиск по найденному", onClick: showSearchByFoundModal },
        {
          text: "Печать",
          onClick: async () => {
            copyReportUrl.info({
              key: "iframe-print",
              message: "Отчет создается...",
              duration: 5,
              className: s.notification,
            });

            onReportPrint?.().catch(() => {
              copyReportUrl.error({
                key: "iframe-print",
                message: "Не удалось распечатать отчёт...",
                duration: 5,
                className: s.notification,
              });
            });
          },
          disabled: isLoadingGeneratePdf,
        },
        {
          text: "Поделиться",
          onClick: () => {
            // eslint-disable-next-line no-restricted-globals
            const reportUrl = `${window.location.origin}${location.pathname}${location.search}`;

            navigator.clipboard.writeText(reportUrl).then(() =>
              copyReportUrl.success({
                key: "report-url",
                message: "Ссылка на отчет скопирована",
                className: s.notification,
              }),
            );
          },
        },
      ].filter(
        (point) =>
          !(
            (!report?.passports || report?.passports?.length < 1) &&
            (!report?.phones || report?.phones?.length < 1) &&
            (!report?.autos || report?.autos?.length < 1) &&
            (!report?.snilses || report?.snilses?.length < 1) &&
            (!report?.inns || report?.inns?.length < 1) &&
            (!report?.emails || report?.emails?.length < 1) &&
            point.text === "Поиск по найденному"
          ),
      ),
    [
      report,
      copyReportUrl,
      onReportPrint,
      isLoadingGeneratePdf,
      showSearchByFoundModal,
    ],
  );

  return (
    <RetrieveCard
      print={print}
      title=""
      status={status}
      isSubReport
      expanded={Boolean(note)}
      header={
        <div className={s.container__header}>
          <Flex vertical gap={16}>
            {!isPrintView && <Text color="aqua">{title}</Text>}
            {requestDescribe &&
              ([
                ReportType.SEARCH_PHOTO_BY_IMAGE,
                ReportType.SEARCH_PHOTO_BY_FACE_LIGHT,
              ].includes(type) ? (
                <img
                  src={`data:image/png;base64,${requestDescribe}`}
                  alt="Some"
                  className={styles.container__sourceImage}
                />
              ) : (
                <div>
                  <Text
                    className={cn(
                      !isPrintView && s.container__header__name,
                      isPrintView && s.container__header__name_print,
                    )}
                  >
                    {requestDescribeWithoutDob}
                  </Text>
                </div>
              ))}
            {!isPrintView && !note && (
              <NoteModal
                type={type}
                reportId={reportId}
                buttonTitle={
                  <Text variant="body-4" color="secondary">
                    <Flex align="center" gap={4}>
                      <NotesPin /> Добавить заметку
                    </Flex>
                  </Text>
                }
              />
            )}
          </Flex>
          {!isPrintView && (
            <Flex align="center" gap={16}>
              <OptionsMenu
                className={s.container__header__options__more}
                options={options}
              />
              <DownloadReportPdfButton
                onClick={onReportLoad}
                isLoading={isLoadingGeneratePdf}
                isMultiQueryReport
              />
            </Flex>
          )}
          <QueryClientProvider client={femidaQueryClient}>
            <SearchByFound
              visible={isSearchByFoundModalVisible}
              onClose={hideSearchByFoundModal}
              propKeys={report ? Object.keys(report) : []}
              personInfo={{
                [PersonInfoKeys.passports]: report?.passports,
                [PersonInfoKeys.phones]: report?.phones,
                [PersonInfoKeys.autos]: report?.autos,
                [PersonInfoKeys.snilses]: report?.snilses,
                [PersonInfoKeys.inns]: report?.inns,
                [PersonInfoKeys.emails]: report?.emails,
              }}
            />
          </QueryClientProvider>
        </div>
      }
    >
      {note && (
        <Flex vertical className={s.container__row__note}>
          <Flex gap={4} align="center">
            <Text color="secondary" variant="body-1">
              Заметка
            </Text>
            {!isPrintView && (
              <NoteModal
                type={type}
                reportId={reportId}
                buttonTitle={
                  <Text color="secondary">
                    <MdEdit size={14} />
                  </Text>
                }
                note={note}
              />
            )}
          </Flex>
          <Text variant="body-5">{note}</Text>
        </Flex>
      )}
    </RetrieveCard>
  );
};
