import type { SubReportsVisibilityContextProps } from "./SubReportsVisibilityContextProps";
import { useSubReportsVisibilityProvider } from "./SubReportsVisibilityContextProps";

export const useSubReportsVisibility = (): SubReportsVisibilityContextProps => {
  const context = useSubReportsVisibilityProvider();
  if (!context) {
    throw new Error(
      "useVisibility must be used within a SubReportsVisibilityProvider",
    );
  }
  return context;
};
