import { validationMessage } from "@utils/constants";
import { correctDigitRegexCallback } from "@utils/validation";
import * as yup from "yup";

export const driverLicenseSchema = yup
  .string()
  .test(
    "digits-check",
    validationMessage.WrongDriverLicense,
    correctDigitRegexCallback,
  )
  .min(10, validationMessage.WrongDriverLicense)
  .max(10, validationMessage.WrongDriverLicense);
