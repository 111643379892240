import type { FC } from "react";
import { Suspense } from "react";
import { useCurrentUserQuery } from "@femida1/gui_web_core";
import { usePageSize } from "@femida1/uikit";
import { Skeleton } from "antd";
import { appConfig } from "@app/providers/AppFeatures/appConfig";
import { OnboardingModal } from "./OnboardingModal";
import type { OnboardingPage } from "./OnboardingModal/types";

const { onboardingEnabled } = appConfig.features;

interface OnboardingModalContainerProps {
  onboardingPage: OnboardingPage;
}

export const OnboardingModalContainer: FC<OnboardingModalContainerProps> = ({
  onboardingPage,
}) => {
  const { isDesktop } = usePageSize();
  const { isSuccess: isUserSuccess, isPending } = useCurrentUserQuery();

  if (!onboardingEnabled || !isDesktop || (!isPending && !isUserSuccess)) {
    return null;
  }

  return (
    <Suspense fallback={<Skeleton />}>
      <OnboardingModal onboardingPage={onboardingPage} />
    </Suspense>
  );
};
