import { useReportsPossibleNetworksExportCsv } from "@femida1/gui_web_core";
import { useCallback } from "react";
import { downloadFile } from "@utils/files/downloadFile";
import { handleBackendErrors } from "@utils/notifications/handleBackendErrors";
import { Download, Text, useNotification } from "@femida1/uikit";
import s from "./DownloadCsvButton.module.scss";

interface DownloadCsvButtonProps {
  reportId: string;
}

export const DownloadCsvButton = ({ reportId }: DownloadCsvButtonProps) => {
  const [api] = useNotification();

  const { mutateAsync: getPossibleNetworksExportCsv } =
    useReportsPossibleNetworksExportCsv();

  const csvLinkOnClick = useCallback(() => {
    getPossibleNetworksExportCsv({ id: reportId })
      .then((response) => {
        const bom = "\uFEFF";
        downloadFile({
          data: bom + response,
          type: "text/csv;charset=utf-8;",
          filename: `possible-networks-${reportId}.csv`,
        });
      })
      .catch((error) => {
        handleBackendErrors({
          api,
          error,
        });
      });
  }, [reportId, getPossibleNetworksExportCsv, api]);

  return (
    <button type="button" onClick={csvLinkOnClick} className={s.button}>
      <Text variant="subheader-2">Экспортировать в CSV</Text>
      <Download />
    </button>
  );
};
