import { useParams } from "react-router-dom";
import { Skeleton } from "antd";
import { useReportsFullDossierLightRetrieve } from "@femida1/gui_web_core";
import type { ReportCommonProps } from "@femida1/schemas";
import { RetrieveFullDossierLight } from "@entities/RetrieveFullDossierLight/RetrieveFullDossierLight";

export const SearchFullDossierLightWidget = ({
  type,
}: Omit<ReportCommonProps, "reportId">) => {
  const { reportId = "" } = useParams();

  const { data: report } = useReportsFullDossierLightRetrieve({
    id: reportId,
  });

  return (
    <Skeleton loading={false}>
      {report && (
        <RetrieveFullDossierLight
          type={type}
          reportId={reportId}
          report={report}
        />
      )}
    </Skeleton>
  );
};
