import type { FC } from "react";
import { useCallback, useState } from "react";

import { Modal } from "antd";
import type { LoginRequest } from "@femida1/schemas";
import { TestId } from "@utils/tests";
import { AuthFormMode } from "./constants";
import { AuthFormLogin } from "./components/AuthFormLogIn/AuthFormLogIn";
import type { AuthFormRegisterFormValues } from "./components/AuthFormRegister/AuthFormRegister";
import { AuthFormRegister } from "./components/AuthFormRegister/AuthFormRegister";
import { AuthFormChangePassword } from "./components/AuthFormChangePassword/AuthFormChangePassword";
import { AuthFormConfirmCode } from "./components/AuthFormConfirmCode/AuthFormConfirmCode";

interface AuthFormProps {
  onSuccess: (data?: AuthFormRegisterFormValues | LoginRequest) => void;
  onClose: () => void;
  modalVisible: boolean;
}

export const AuthForm: FC<AuthFormProps> = ({
  onSuccess,
  onClose,
  modalVisible,
}) => {
  const [mode, setMode] = useState<AuthFormMode>(AuthFormMode.LogIn);

  const onRegister = useCallback(() => {
    setMode(AuthFormMode.Register);
  }, []);
  const onChooseAuthorize = useCallback(() => {
    setMode(AuthFormMode.LogIn);
  }, []);
  const onChangePassword = useCallback(() => {
    setMode(AuthFormMode.ChangePassword);
  }, []);
  const onConfirmCode = useCallback(() => {
    setMode(AuthFormMode.ConfirmCode);
  }, []);

  const handleSuccess = useCallback(
    (data?: AuthFormRegisterFormValues | LoginRequest) => {
      setMode(AuthFormMode.LogIn);
      onSuccess(data);
    },
    [onSuccess],
  );
  const handleClose = useCallback(() => {
    onClose();
    setMode(AuthFormMode.LogIn);
  }, [onClose]);

  return (
    <Modal
      open={modalVisible}
      closable={false}
      footer={null}
      bodyProps={{
        "data-testid": TestId.AuthFormModal,
      }}
    >
      {mode === AuthFormMode.LogIn && (
        <AuthFormLogin
          onSuccess={handleSuccess}
          onRegister={onRegister}
          onChangePassword={onChangePassword}
          onClose={handleClose}
        />
      )}
      {mode === AuthFormMode.Register && (
        <AuthFormRegister
          onChooseAuthorize={onChooseAuthorize}
          onSuccess={handleSuccess}
          onClose={handleClose}
          onBack={onChooseAuthorize}
        />
      )}
      {mode === AuthFormMode.ChangePassword && (
        <AuthFormChangePassword
          onSuccess={onConfirmCode}
          onClose={handleClose}
        />
      )}
      {mode === AuthFormMode.ConfirmCode && (
        <AuthFormConfirmCode
          onSuccess={onConfirmCode}
          email="lol"
          password="kek"
        />
      )}
    </Modal>
  );
};
