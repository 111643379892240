import type { FC } from "react";
import { Text } from "@femida1/uikit";
import cn from "classnames";
import s from "./CardMessage.module.scss";

interface CardMessageProps {
  date: string;
  message: string;
  isSupport?: boolean;
  fileName?: string;
  onFileClick?: () => void;
}

export const CardMessage: FC<CardMessageProps> = ({
  date,
  message,
  isSupport,
  fileName,
  onFileClick,
}) => (
  <div className={cn({ [s.user]: !isSupport }, s.wrap)}>
    <div className={s.card}>
      <Text variant="header-1" color="primary">
        {message}
      </Text>
      <div className={cn({ [s.user]: !isSupport }, s.wrap)}>
        <Text variant="body-1" color="secondary">
          {date}
        </Text>
      </div>
      {fileName && (
        <button type="button">
          <Text variant="body-1" color="secondary" onClick={onFileClick}>
            {fileName}
          </Text>
        </button>
      )}
    </div>
  </div>
);
