import type { useNotification } from "@femida1/uikit";
import { downloadFile } from "@utils/files/downloadFile";
import { printFile } from "@utils/files/printFile";
import { handleBackendErrors } from "@utils/notifications/handleBackendErrors";
import { useCallback } from "react";
import type { ReportType } from "@femida1/schemas";
import { usePdfGenerateQuery } from "./usePdfGenerateQuery";

const type = "application/pdf";

interface UsePdfGenerateHandlersProps {
  api: ReturnType<typeof useNotification>[0];
  reportId: string;
  type: ReportType;
}

export const usePdfGenerateHandlers = ({
  api,
  reportId,
  type: reportType,
}: UsePdfGenerateHandlersProps) => {
  const filename = `${reportId}.pdf`;

  const {
    refetch: generatePdf,
    data,
    isLoading: isLoadingGeneratePdf,
  } = usePdfGenerateQuery({
    report_id: reportId,
    report_type: reportType,
  });

  const onReportLoad = useCallback(async () => {
    if (data) {
      downloadFile({
        data,
        filename,
        type,
      });
    } else {
      await generatePdf()
        .then((query) => {
          if (!query.data) return;
          downloadFile({
            data: query.data,
            filename,
            type,
          });
        })
        .catch((error) => {
          handleBackendErrors({
            api,
            error,
          });
        });
    }
  }, [generatePdf, filename, api, data]);

  const onReportPrint = useCallback(async () => {
    if (data) {
      printFile({ data: new Blob([data], { type }) });
    } else {
      await generatePdf()
        .then((query) => {
          if (!query.data) return;
          printFile({
            data: new Blob([query.data], { type }),
          });
        })
        .catch((error) => {
          handleBackendErrors({
            api,
            error,
          });
        });
    }
  }, [generatePdf, api, data]);

  return { isLoadingGeneratePdf, onReportLoad, onReportPrint };
};
