import type { ClipboardEventHandler, FC } from "react";
import { useCallback, useRef } from "react";
import { Flex, Input } from "@femida1/uikit";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitButton } from "@pages/Main/components/MainTabs/components/SubmitButton/SubmitButton";
import { validationMessage, validationRegex } from "@utils/constants";
import {
  correctDateCallback,
  correctLettersSpacesHyphenDashRegexCallback,
  correctPassportRegexCallback,
} from "@utils/validation";
import dayjs from "dayjs";
import { POSSIBLE_DATE_FORMATS } from "@pages/Main/components/MainTabs/utils/isValidDate";
import { guessWordTypes } from "@pages/Main/components/MainTabs/utils/guessWordTypes";
import { WordType } from "@pages/Main/components/MainTabs/utils/types";
import { getOtherWords } from "@pages/Main/components/MainTabs/utils/getOtherWords";
import { useReportsFullDossierLightSearch } from "@femida1/gui_web_core";
import { transformEmptyString } from "@utils/transformers/transformEmptyString";
import type { ReportsFullDossierLightSearchRequest } from "@femida1/schemas";
import { dropZoneFileListSchema } from "@pages/Main/components/MainTabs/constants/dropZoneFileListSchema";
import { driverLicenseSchema } from "@utils/validation/driverLicenseSchema";
import { appConfig } from "@app/providers/AppFeatures/appConfig";
import type { MainTabFieldValues, MainTabProps } from "../../types";
import s from "./MainTabFullDossierLight.module.scss";

interface ReportsFullDossierFieldValues
  extends ReportsFullDossierLightSearchRequest,
    MainTabFieldValues {}

const schema = yup
  .object<ReportsFullDossierLightSearchRequest>()
  .shape({
    last_name: yup
      .string()
      .test(
        "test-symbols",
        validationMessage.WrongLastName,
        correctLettersSpacesHyphenDashRegexCallback,
      )
      .required(),
    first_name: yup
      .string()
      .test(
        "test-symbols",
        validationMessage.WrongFirstName,
        correctLettersSpacesHyphenDashRegexCallback,
      )
      .required(),
    middle_name: yup
      .string()
      .test(
        "test-symbols",
        validationMessage.WrongMiddleName,
        correctLettersSpacesHyphenDashRegexCallback,
      )
      .required(),
    passport: yup
      .string()
      .transform((value, originalValue: string) =>
        transformEmptyString(value, originalValue.replace(/\D/g, "")),
      )
      .test(
        "passport-check",
        validationMessage.WrongPassport,
        correctPassportRegexCallback,
      )
      .required(),
    birth_date: yup
      .string()
      .test(
        "birth-date-check",
        validationMessage.WrongBirthDate,
        correctDateCallback,
      )
      .required(),
    phone: yup
      .string()
      .transform((value, originalValue: string) =>
        transformEmptyString(value, originalValue.replace(/\+7|\D/g, "")),
      )
      .matches(validationRegex.phone, "Номер телефона неверен")
      .required(),
    email: yup
      .string()
      .transform(transformEmptyString)
      .email(validationMessage.WrongEmail),
    driver_license: driverLicenseSchema,
    driver_license_date: yup
      .string()
      .test(
        "driver-license-date-check",
        validationMessage.WrongDriverLicenseDate,
        correctDateCallback,
      ),
    sopdFileList: dropZoneFileListSchema,
  })
  .required();

interface MainTabFullDossierLightProps extends MainTabProps {}

export const MainTabFullDossierLight: FC<MainTabFullDossierLightProps> = ({
  onSubmit,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
    setValue,
    trigger,
  } = useForm<ReportsFullDossierFieldValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      last_name: "",
      first_name: "",
      middle_name: "",
      passport: "",
      birth_date: "",
      phone: "",
    },
    mode: "onChange",
  });
  const passportFieldRef = useRef("");
  const birthDateFieldRef = useRef("");
  const driverLicenseDateFieldRef = useRef("");
  const phoneFieldRef = useRef("");

  const { mutateAsync: search, isPending: isSearchPending } =
    useReportsFullDossierLightSearch();

  const onSubmitInner = useCallback(
    (data: ReportsFullDossierFieldValues) => {
      const requestData = {
        ...data,
        passport: data.passport.replace(/\D/g, ""),
        phone: data.phone.replace(/\D/g, ""),
      };
      const onSuccess = () => {
        reset();
        passportFieldRef.current = "";
        birthDateFieldRef.current = "";
        driverLicenseDateFieldRef.current = "";
        phoneFieldRef.current = "";
      };

      return onSubmit({
        requestData,
        search,
        withSopd: true,
        onSuccess,
      });
    },
    [search, onSubmit, reset],
  );

  const pasteDate = useCallback(
    (name: "birth_date" | "driver_license_date", dateWord: string): boolean => {
      const date = dayjs(dateWord, POSSIBLE_DATE_FORMATS, true);
      if (date.isValid()) {
        setValue(name, dateWord);
        return true;
      }
      return false;
    },
    [setValue],
  );

  const onBirthDatePaste = useCallback<ClipboardEventHandler<HTMLInputElement>>(
    async (e) => {
      const pastedDate = e.clipboardData.getData("text");
      if (pasteDate("birth_date", pastedDate)) {
        e.preventDefault();
      }

      await trigger().catch(() => {});
    },
    [pasteDate, trigger],
  );

  const onDriverLicenseDatePaste = useCallback<
    ClipboardEventHandler<HTMLInputElement>
  >(
    async (e) => {
      const pastedDate = e.clipboardData.getData("text");
      if (pasteDate("driver_license_date", pastedDate)) {
        e.preventDefault();
      }

      await trigger().catch(() => {});
    },
    [pasteDate, trigger],
  );

  const onLastNamePaste = useCallback<ClipboardEventHandler<HTMLInputElement>>(
    async (e) => {
      const pastedText = e.clipboardData.getData("text");
      const pastedWords = pastedText.split(" ");
      if (!pastedWords.length) return;

      e.preventDefault();

      const wordsByType = guessWordTypes({ pastedWords });

      const birthDateWord = wordsByType[WordType.Date].shift();
      if (birthDateWord) {
        pasteDate("birth_date", birthDateWord);
        birthDateFieldRef.current = birthDateWord;
      }

      const DriverLicenseDateWord = wordsByType[WordType.Date].shift();
      if (DriverLicenseDateWord) {
        pasteDate("driver_license_date", DriverLicenseDateWord);
        driverLicenseDateFieldRef.current = DriverLicenseDateWord;
      }

      const probableOrderOfPastedWords: (keyof ReportsFullDossierFieldValues)[] =
        [];

      const lastName = wordsByType[WordType.LastName].shift();
      if (lastName) {
        setValue("last_name", lastName);
      } else {
        probableOrderOfPastedWords.push("last_name");
      }
      const firstName = wordsByType[WordType.FirstName].shift();
      if (firstName) {
        setValue("first_name", firstName);
      } else {
        probableOrderOfPastedWords.push("first_name");
      }
      const middleName = wordsByType[WordType.MiddleName].shift();
      if (middleName) {
        setValue("middle_name", middleName);
      } else {
        probableOrderOfPastedWords.push("middle_name");
      }
      const passport = wordsByType[WordType.Passport].shift();
      if (passport) {
        setValue("passport", passport);
      } else {
        probableOrderOfPastedWords.push("passport");
      }
      const phone = wordsByType[WordType.Phone].shift();
      if (phone) {
        const correctPhone = phone.replace(/^./, "+7");
        setValue("phone", correctPhone);
        phoneFieldRef.current = correctPhone;
      } else {
        probableOrderOfPastedWords.push("phone");
      }

      const otherWords = getOtherWords(wordsByType);
      const maxLen = probableOrderOfPastedWords.length;
      if (otherWords.length > maxLen) {
        const restPastedWords = otherWords.slice(maxLen - 1);
        otherWords[maxLen - 1] = restPastedWords.join(" ");
        otherWords.length = maxLen;
      }

      otherWords.forEach((pastedWord, index) => {
        if (probableOrderOfPastedWords[index] === "passport") {
          const formattedPassport = pastedWord.replace(
            /(\d{4})(\d{6})/,
            "$1 $2",
          );
          setValue(probableOrderOfPastedWords[index], formattedPassport);
          passportFieldRef.current = formattedPassport;
        } else {
          setValue(probableOrderOfPastedWords[index], pastedWord);
        }
      });

      await trigger().catch(() => {});
    },
    [setValue, pasteDate, trigger],
  );

  return (
    <form className={s.form} onSubmit={handleSubmit(onSubmitInner)}>
      <Flex gap={12} vertical>
        <div className={s.inputsContainer}>
          <Controller
            name="last_name"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                className={s.input}
                tabIndex={0}
                required
                placeholder="Фамилия"
                {...field}
                value={field.value.replace(
                  validationRegex.lettersSpacesHyphenDashExcept,
                  "",
                )}
                onChange={(e) => {
                  e.target.value = e.target.value.replace(
                    validationRegex.lettersSpacesHyphenDashExcept,
                    "",
                  );
                  field.onChange(e);
                }}
                onPaste={onLastNamePaste}
                validate={fieldState}
              />
            )}
          />
          <Controller
            name="first_name"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                className={s.input}
                tabIndex={0}
                required
                placeholder="Имя"
                {...field}
                value={field.value.replace(
                  validationRegex.lettersSpacesHyphenDashExcept,
                  "",
                )}
                onChange={(e) => {
                  e.target.value = e.target.value.replace(
                    validationRegex.lettersSpacesHyphenDashExcept,
                    "",
                  );
                  field.onChange(e);
                }}
                validate={fieldState}
              />
            )}
          />
          <Controller
            name="middle_name"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                className={s.input}
                tabIndex={0}
                required
                placeholder="Отчество"
                {...field}
                value={field.value.replace(
                  validationRegex.lettersSpacesHyphenDashExcept,
                  "",
                )}
                onChange={(e) => {
                  e.target.value = e.target.value.replace(
                    validationRegex.lettersSpacesHyphenDashExcept,
                    "",
                  );
                  field.onChange(e);
                }}
                validate={fieldState}
              />
            )}
          />
          <Controller
            name="birth_date"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                key={birthDateFieldRef.current}
                className={s.input}
                tabIndex={0}
                required
                mask="00.00.0000"
                placeholder="Дата рождения"
                onPaste={onBirthDatePaste}
                {...field}
                validate={fieldState}
              />
            )}
          />
          <Controller
            name="passport"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                key={passportFieldRef.current}
                className={s.input}
                tabIndex={0}
                required
                mask="0000 000000"
                placeholder="Номер паспорта"
                {...field}
                validate={fieldState}
              />
            )}
          />
          <Controller
            name="phone"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                key={phoneFieldRef.current}
                className={s.input}
                tabIndex={0}
                mask="+70000000000"
                required
                placeholder="Номер телефона"
                {...field}
                validate={fieldState}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                className={s.input}
                tabIndex={0}
                type="email"
                placeholder="Email"
                {...field}
                validate={fieldState}
              />
            )}
          />
          {appConfig.features.tabFullDossierLightDriverLicenseEnabled ? (
            <Controller
              name="driver_license"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  className={s.input}
                  tabIndex={0}
                  mask="0000000000"
                  placeholder="Серия и номер ВУ"
                  {...field}
                  validate={fieldState}
                />
              )}
            />
          ) : null}
          {appConfig.features.tabFullDossierLightDriverLicenseEnabled ? (
            <Controller
              name="driver_license_date"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  key={driverLicenseDateFieldRef.current}
                  className={s.input}
                  tabIndex={0}
                  mask="00.00.0000"
                  placeholder="Дата выдачи ВУ"
                  onPaste={onDriverLicenseDatePaste}
                  {...field}
                  validate={fieldState}
                />
              )}
            />
          ) : null}
          <span className={s.submitButton}>
            <Controller
              name="sopdFileList"
              control={control}
              render={({ field }) => (
                <SubmitButton
                  fileList={field.value}
                  onFileListChange={field.onChange}
                  disabled={!isValid || isSearchPending}
                />
              )}
            />
          </span>
        </div>
      </Flex>
    </form>
  );
};
