import { Modal } from "antd";
import { Text } from "@femida1/uikit";
import s from "./TemporaryShutdown.module.scss";

export const TemporaryShutdown = () => {
  return (
    <Modal
      className={s.modal}
      title={<Text variant="header-3">Важно!</Text>}
      closable={false}
      footer={null}
      open
    >
      <Text>
        Уважаемые клиенты! <br />
        <br /> С 11 декабря 2024 года вступает в силу Федеральный закон от
        30.11.2024 г. № 421-ФЗ &quot;О внесении изменений в Уголовный кодекс
        Российской Федерации&quot;. Поскольку в законе отсутствуют чёткие
        разъяснения о том, что является &quot;компьютерной информацией,
        содержащей персональные данные, полученной незаконным путем&quot; и
        какие форматы работы допустимы, мы вынуждены временно приостановить
        предоставление информации в прежнем объёме. Это необходимо для защиты
        Ваших и наших интересов. <br />
        <br /> На данном этапе мы уже работаем над новой системой, чтобы
        обеспечить Вам безопасный доступ к информации. Мы бросаем все свои
        ресурсы и возможности на эту задачу. Ожидаемая дата запуска — конец
        декабря текущего года.
        <br />
        <br /> В обновлённой версии сервиса мы предложим:
        <br /> *Данные из ФССП и иных ведомств;
        <br /> *Информацию и фотографии из Telegram, ВК и других социальных
        сетей;
        <br /> *Данные из открытых источников по телефонам и email;
        <br /> *Скоринги и вектора на основе наших уникальных данных;
        <br /> *Множество других решений, адаптированных под новые реалии.
        <br />
        <br /> Мы уверены, что сможем сохранить статус лучшего профессионального
        инструмента для сотрудников служб безопасности в условиях нового
        правового поля. Мы понимаем, что перемены могут вызывать неудобства, но
        уверены: совместными усилиями мы преодолеем этот период. Благодарим за
        доверие, поддержку и терпение!
        <br />
        <br /> Рады ответить на Ваши вопросы, обращайтесь к персональным
        менеджерам, на info@odyssey-search.info или на +7(800)600-64-08
        <br />
        <br /> С уважением,
        <br /> Генеральный директор Кириллов С.М.
      </Text>
    </Modal>
  );
};
