import { AppRoute } from "@app/routes/constants";
import { ReportType, type SubReportListItem } from "@femida1/schemas";
import { FaArrowRight } from "react-icons/fa6";
import { Flex, Text } from "@femida1/uikit";
import { generatePath, Link } from "react-router-dom";

export const renderFoundPerson = (report: SubReportListItem) => {
  return (
    <Link
      key={report.report_task_id}
      to={`${generatePath(AppRoute.Report, {
        reportId: report.report_task_id,
      })}?type=${ReportType.SEARCH_FIO}`}
      state={{
        id: report.report_task_id,
        type: ReportType.SEARCH_FIO,
      }}
    >
      <Flex gap={10} align="center">
        <Text color="accent" variant="subheader-2">
          {report.request}
        </Text>
        <FaArrowRight color="#00CC69" />
      </Flex>
    </Link>
  );
};
