import type { FC } from "react";
import { useCallback } from "react";
import { Flex, Input } from "@femida1/uikit";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitButton } from "@pages/Main/components/MainTabs/components/SubmitButton/SubmitButton";
import { validationMessage } from "@utils/constants";
import {
  useReportsEmailSearch,
  useReportsEmailLightSearch,
} from "@femida1/gui_web_core";
import type { ReportsEmailSearchRequest } from "@femida1/schemas";
import { appConfig } from "@app/providers/AppFeatures/appConfig";
import s from "./MainTabEmail.module.scss";
import type { MainTabFieldValues, MainTabProps } from "../../types";
import { dropZoneFileListSchema } from "../../constants/dropZoneFileListSchema";

interface ReportsEmailFieldValues
  extends ReportsEmailSearchRequest,
    MainTabFieldValues {}

const schema = yup
  .object()
  .shape({
    email: yup.string().email(validationMessage.WrongEmail).required(),
    sopdFileList: dropZoneFileListSchema,
  })
  .required();

type MainTabEmailProps = MainTabProps;

const { features } = appConfig;

export const MainTabEmail: FC<MainTabEmailProps> = ({ onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
    },
  });

  const { mutateAsync: search, isPending: isSearchPending } =
    useReportsEmailSearch();
  const { mutateAsync: lightSearch, isPending: isLightSearchPending } =
    useReportsEmailLightSearch();

  const onSubmitInner = useCallback(
    (requestData: ReportsEmailFieldValues) =>
      onSubmit({
        requestData,
        search: features.tabEmailLightSearch ? lightSearch : search,
        withSopd: true,
        onSuccess: reset,
      }),
    [onSubmit, lightSearch, search, reset],
  );

  return (
    <form onSubmit={handleSubmit(onSubmitInner)}>
      <Flex gap={12} className={s.controllersContainer}>
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              tabIndex={0}
              required
              type="email"
              placeholder="Email"
              {...field}
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="sopdFileList"
          control={control}
          render={({ field }) => (
            <SubmitButton
              fileList={field.value}
              onFileListChange={field.onChange}
              disabled={!isValid || isSearchPending || isLightSearchPending}
            />
          )}
        />
      </Flex>
    </form>
  );
};
