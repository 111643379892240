import type { ReportCommonProps } from "@femida1/schemas";
import { useParams } from "react-router-dom";
import { Skeleton } from "antd";
import { useReportsPhoneLightRetrieve } from "@femida1/gui_web_core";
import { RetrieveEmailOrPhoneLight } from "@entities/RetrieveEmailOrPhoneLight/RetrieveEmailOrPhoneLight";

export const SearchPhoneLightWidget = ({
  type,
}: Omit<ReportCommonProps, "reportId">) => {
  const { reportId = "" } = useParams();

  const { data: phoneLightReport, isLoading } = useReportsPhoneLightRetrieve({
    id: reportId,
  });

  return (
    <Skeleton loading={isLoading}>
      {phoneLightReport && (
        <RetrieveEmailOrPhoneLight
          reportId={reportId}
          report={phoneLightReport}
          type={type}
        />
      )}
    </Skeleton>
  );
};
