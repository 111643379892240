import type React from "react";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

export interface SubReportsVisibilityContextProps {
  visibilityState: Record<string, boolean>;
  setVisibilityState: (key: string, isVisible: boolean) => void;
}

const SubReportsVisibilityContext = createContext<
  SubReportsVisibilityContextProps | undefined
>(undefined);

// eslint-disable-next-line react-refresh/only-export-components
export const useSubReportsVisibilityProvider = () =>
  useContext(SubReportsVisibilityContext);

export const SubReportsVisibilityProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [visibilityState, setState] = useState<Record<string, boolean>>({});

  const setVisibilityState = useCallback((key: string, isVisible: boolean) => {
    setState((prev) => ({ ...prev, [key]: isVisible }));
  }, []);

  const value = useMemo(
    () => ({ visibilityState, setVisibilityState }),
    [visibilityState, setVisibilityState],
  );

  return (
    <SubReportsVisibilityContext.Provider value={value}>
      {children}
    </SubReportsVisibilityContext.Provider>
  );
};
