import { Modal, Tooltip } from "antd";
import {
  Flex,
  Button,
  Text,
  DropZone,
  usePageSize,
  useBooleanState,
} from "@femida1/uikit";
import type { DropZoneFile } from "@femida1/uikit";
import { IoMdDocument } from "react-icons/io";
import { useCallback } from "react";
import { AiFillQuestionCircle } from "react-icons/ai";
import styles from "./SOPDModal.module.scss";

interface SOPDModalProps {
  fileList: DropZoneFile[];
  onFileListChange: (fileList: DropZoneFile[]) => void;
}

export const SOPDModal = ({ fileList, onFileListChange }: SOPDModalProps) => {
  const { isFromTabletVertical } = usePageSize();
  const file = fileList?.[0]?.originFileObj;

  const {
    state: isSopdOpen,
    setTrue: openSopd,
    setFalse: closeSopd,
  } = useBooleanState(false);

  const onClear = useCallback(() => {
    onFileListChange([]);
  }, [onFileListChange]);

  return (
    <>
      <Button
        className={styles.button_sopd}
        type="text_secondary"
        disabled={false}
        htmlType="button"
        onClick={openSopd}
      >
        <Flex gap={6} align="center">
          <Text color={file ? "primary" : "secondary"}>
            {file ? "Приложено СОПД" : "Загрузить СОПД"}
          </Text>
          <Tooltip
            title="Согласие на обработку персональных данных"
            trigger="hover"
          >
            <AiFillQuestionCircle />
          </Tooltip>
        </Flex>
      </Button>
      <Modal
        title={<Text variant="header-6">Загрузка СОПД</Text>}
        open={isSopdOpen}
        onOk={closeSopd}
        onCancel={closeSopd}
        footer={[
          <Button key="clear" type="flat_secondary" size="s" onClick={onClear}>
            Очистить
          </Button>,
          <Button key="submit" type="primary" size="s" onClick={closeSopd}>
            Применить
          </Button>,
        ]}
      >
        <Flex vertical gap={12}>
          <Text color="secondary">
            Загрузите файл в формате PDF подтверждающий согласие на обработку
            персональных данных
          </Text>
          <DropZone
            value={fileList}
            onChange={onFileListChange}
            accept=".pdf"
            multiple={false}
            selectFileButtonTitle={file ? "Поменять файл" : "Выбрать файл"}
          >
            <Flex align="center" vertical gap={24} className={styles.dropZone}>
              <Flex align="center" vertical gap={16}>
                <IoMdDocument size={48} className={styles.icon} />
                <Text color="aqua" variant="body-6">
                  {file
                    ? file.name
                    : `Загрузите${isFromTabletVertical ? " или перетащите" : ""} файл в формате: PDF`}
                </Text>
              </Flex>
            </Flex>
          </DropZone>
        </Flex>
      </Modal>
    </>
  );
};
