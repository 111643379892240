import type { CrossingBorderItem } from "@femida1/schemas";
import { CrossingBorderItemEnum } from "@femida1/schemas";
import dayjs from "dayjs";
import {
  CROSSING_BORDER_DATE_FORMAT,
  CrossingBordersDateOrder,
} from "../constants";
import type { CrossingBordersFilters } from "../types";

interface SortFilterDataProps {
  filters: CrossingBordersFilters;
  dateSortOrder: CrossingBordersDateOrder;
  data?: CrossingBorderItem[];
}

export const sortFilterData = ({
  filters,
  dateSortOrder,
  data,
}: SortFilterDataProps): CrossingBorderItem[] => {
  if (!data?.length) {
    return [];
  }

  let result = data;
  const dayJsInstanceByDate: Record<string, dayjs.Dayjs> = {};
  let isDayJsInstanceByDateFilled = false;

  if (filters.endDate && filters.startDate) {
    if (!isDayJsInstanceByDateFilled) {
      isDayJsInstanceByDateFilled = true;
      result?.forEach((item) => {
        const date = item[CrossingBorderItemEnum.crossingDate];
        dayJsInstanceByDate[date] ||= dayjs(date, CROSSING_BORDER_DATE_FORMAT);
      });
    }

    result = result?.slice().filter((value) => {
      const date = value[CrossingBorderItemEnum.crossingDate];
      const dateDayJs = dayJsInstanceByDate[date];

      return (
        dateDayJs.isAfter(filters.startDate) &&
        dateDayJs.isBefore(filters.endDate)
      );
    });
  }

  if (dateSortOrder === CrossingBordersDateOrder.Desc) {
    if (!isDayJsInstanceByDateFilled) {
      isDayJsInstanceByDateFilled = true;
      result?.forEach((item) => {
        const date = item[CrossingBorderItemEnum.crossingDate];
        dayJsInstanceByDate[date] ||= dayjs(date, CROSSING_BORDER_DATE_FORMAT);
      });
    }

    result = result?.slice().sort((a, b) => {
      const aDate = a[CrossingBorderItemEnum.crossingDate];
      const bDate = b[CrossingBorderItemEnum.crossingDate];

      return dayJsInstanceByDate[aDate].isAfter(dayJsInstanceByDate[bDate])
        ? 1
        : -1;
    });
  }

  return result;
};
