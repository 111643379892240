import type { FC } from "react";
import type {
  ReportsEmailLightRetrieveResponse,
  ReportsPhoneLightRetrieveResponse,
  ReportType,
} from "@femida1/schemas";
import { FoundStatus } from "@femida1/schemas";
import {
  calculateReportStatus,
  mapReportTypeToTitle,
  RetrieveEmailOrPhoneLightView,
} from "@femida1/gui_web_core";
import { RetrieveStatus } from "@entities/RetrieveStatus/RetrieveStatus";
import { ReportHeader } from "@components/ReportHeader/ReportHeader";
import { useNotification } from "@femida1/uikit";
import { usePdfGenerateHandlers } from "@app/api/pdf/usePdfGenerateHandlers";

interface RetrieveEmailOrPhoneLightProps {
  report: ReportsEmailLightRetrieveResponse | ReportsPhoneLightRetrieveResponse;
  type: ReportType;
  reportId: string;
}

export const RetrieveEmailOrPhoneLight: FC<RetrieveEmailOrPhoneLightProps> = ({
  report,
  type,
  reportId,
}) => {
  const resultingFound =
    report.fio.found ||
    report.email?.found ||
    report.phone?.found ||
    report.service.found;

  const [api] = useNotification();

  const { onReportLoad, onReportPrint, isLoadingGeneratePdf } =
    usePdfGenerateHandlers({
      api,
      type,
      reportId,
    });

  return (
    <RetrieveStatus
      status={
        calculateReportStatus({
          sourceStatuses: [
            report.fio.status,
            report.email?.status,
            report.phone?.status,
            report.service.status,
          ],
          found: resultingFound === FoundStatus.found,
        }) || report?.status
      }
    >
      <ReportHeader
        reportId={reportId}
        title={mapReportTypeToTitle[type]}
        type={type}
        requestDescribe={report.request_describe}
        status={report.status}
        note={report.note}
        onReportLoad={onReportLoad}
        onReportPrint={onReportPrint}
        isLoadingGeneratePdf={isLoadingGeneratePdf}
      />
      <RetrieveEmailOrPhoneLightView report={report} type={type} />
    </RetrieveStatus>
  );
};
