import type { DefaultRequestObjectEntry } from "../types";

const mapFieldKeyToOrder: Record<string, number> = {
  last_name: 1003,
  first_name: 1002,
  middle_name: 1001,
  birth_day: 903,
  birth_month: 902,
  birth_year: 901,
  birth: 900,
};

export const sortDefaultRequestObject = (
  entries: DefaultRequestObjectEntry[],
): DefaultRequestObjectEntry[] =>
  entries.sort(([aKey], [bKey]) => {
    const orderAByKey = mapFieldKeyToOrder[aKey] || 0;
    const orderBByKey = mapFieldKeyToOrder[bKey] || 0;

    return orderBByKey - orderAByKey;
  });
