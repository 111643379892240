import type { FC, PropsWithChildren } from "react";
import { useEffect, useState } from "react";
import { List } from "antd";
import { motion, AnimatePresence } from "framer-motion";

const DURATION_MS = 800;
const TRANSITION = { ease: "easeOut", duration: DURATION_MS / 1_000 };
const INITIAL = { opacity: 0, y: -215 };
const ANIMATE = { opacity: 1, y: 0 };
const EXIT = { opacity: 0, y: 215 };

interface AnimatedListItemProps extends PropsWithChildren {
  isAnimated?: boolean;
}

export const AnimatedListItem: FC<AnimatedListItemProps> = ({
  isAnimated,
  children,
}) => {
  const [isAnimatedDelayed, setIsAnimatedDelayed] = useState(isAnimated);

  useEffect(() => {
    let timeoutId: undefined | number;

    if (isAnimated) {
      setIsAnimatedDelayed(true);
    } else {
      timeoutId = setTimeout(() => {
        setIsAnimatedDelayed(false);
      }, DURATION_MS);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isAnimated]);

  if (!isAnimatedDelayed) {
    return <List.Item>{children}</List.Item>;
  }

  return (
    <AnimatePresence>
      <motion.li
        layout
        transition={TRANSITION}
        initial={INITIAL}
        animate={ANIMATE}
        exit={EXIT}
      >
        {children}
      </motion.li>
    </AnimatePresence>
  );
};
