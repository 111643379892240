import { useParams } from "react-router-dom";
import { useReportsInnFlRetrieve } from "@femida1/gui_web_core";
import { SearchFull } from "@entities/SearchFull/SearchFull";
import { Skeleton } from "antd";
import type { ReportCommonProps } from "@femida1/schemas";

interface ISearchInnFlWidgetProps extends Omit<ReportCommonProps, "reportId"> {
  subReportId?: string;
}

export const SearchInnFlWidget = ({
  type,
  subReportId,
}: ISearchInnFlWidgetProps) => {
  const { reportId: paramsReportId = "" } = useParams();

  const resultReportId = subReportId ?? paramsReportId;

  const { data: innFlReport, isLoading } = useReportsInnFlRetrieve({
    id: resultReportId,
  });

  return (
    <Skeleton loading={isLoading}>
      {innFlReport && (
        <SearchFull
          isFullDossierReport={!!subReportId}
          isSubReport={!!subReportId}
          report={innFlReport}
          type={type}
          reportId={resultReportId}
        />
      )}
    </Skeleton>
  );
};
