import { useParams } from "react-router-dom";
import { useReportsEmailRetrieve } from "@femida1/gui_web_core";
import { SearchFull } from "@entities/SearchFull/SearchFull";
import { Skeleton } from "antd";
import type { ReportCommonProps } from "@femida1/schemas";

interface ISearchEmailWidgetProps extends Omit<ReportCommonProps, "reportId"> {
  subReportId?: string;
}

export const SearchEmailWidget = ({
  type,
  subReportId,
}: ISearchEmailWidgetProps) => {
  const { reportId: paramsReportId = "" } = useParams();

  const resultReportId = subReportId ?? paramsReportId;

  const { data: emailReport, isLoading } = useReportsEmailRetrieve({
    id: resultReportId,
  });

  return (
    <Skeleton loading={isLoading}>
      {emailReport && (
        <SearchFull
          isFullDossierReport={!!subReportId}
          isSubReport={!!subReportId}
          report={emailReport}
          type={type}
          reportId={resultReportId}
        />
      )}
    </Skeleton>
  );
};
