import { useParams } from "react-router-dom";
import { useReportsInfoRetrieve } from "@femida1/gui_web_core";
import { SearchFull } from "@entities/SearchFull/SearchFull";
import { Skeleton } from "antd";
import type { ReportCommonProps } from "@femida1/schemas";

export const SearchInfoWidget = ({
  type,
}: Omit<ReportCommonProps, "reportId">) => {
  const { reportId = "" } = useParams();

  const { data: infoReport, isLoading } = useReportsInfoRetrieve({
    id: reportId,
  });

  return (
    <Skeleton loading={isLoading}>
      {infoReport && (
        <SearchFull report={infoReport} type={type} reportId={reportId} />
      )}
    </Skeleton>
  );
};
