import type { PickerLocale } from "antd/es/date-picker/generatePicker";
import ru_RU from "antd/lib/locale/ru_RU";

export const datePickerLocale: PickerLocale = {
  ...ru_RU.DatePicker!,
  lang: {
    ...ru_RU.DatePicker!.lang,
    shortWeekDays: ["ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ"],
  },
};
