import type { ReportCommonProps } from "@femida1/schemas";
import { useParams } from "react-router-dom";
import { useReportsEmailLightRetrieve } from "@femida1/gui_web_core";
import { Skeleton } from "antd";
import { RetrieveEmailOrPhoneLight } from "@entities/RetrieveEmailOrPhoneLight/RetrieveEmailOrPhoneLight";

export const SearchEmailLightWidget = ({
  type,
}: Omit<ReportCommonProps, "reportId">) => {
  const { reportId = "" } = useParams();

  const { data: emailLightReport, isLoading } = useReportsEmailLightRetrieve({
    id: reportId,
  });

  return (
    <Skeleton loading={isLoading}>
      {emailLightReport && (
        <RetrieveEmailOrPhoneLight
          reportId={reportId}
          report={emailLightReport}
          type={type}
        />
      )}
    </Skeleton>
  );
};
