import type { FC } from "react";
import { Fragment } from "react";
import { Flex, Text, Button } from "@femida1/uikit";
import { UserCard } from "@components/UserCard/UserCard";
import type { CurrentUser, Group } from "@femida1/schemas";
import { appConfig } from "@app/providers/AppFeatures/appConfig";
// import s from './UserSettingsMainInfo.module.scss';

interface UserSettingsMainInfoProps {
  user: CurrentUser;
  group?: Group;
}

export const UserSettingsMainInfo: FC<UserSettingsMainInfoProps> = ({
  user,
  group,
}) => {
  const isFillEnabled = false;
  let { name, contract_number, contract_date } = group || {};
  name = name && typeof name === "string" ? name : "-";
  contract_number =
    contract_number && typeof contract_number === "string"
      ? contract_number
      : "-";
  contract_date =
    contract_date && typeof contract_date === "string" ? contract_date : "-";

  return (
    <Flex vertical gap={32}>
      <Flex justify="space-between">
        <Text variant="header-6">Основная информация</Text>
        {isFillEnabled ? <Button size="s">Пополнить баланс</Button> : null}
      </Flex>
      <UserCard user={user} type="border-box" size="m" editable />
      {group ? (
        <Flex vertical gap={8}>
          <Flex vertical gap={8}>
            <Text color="secondary" variant="body-5">
              {appConfig.features.userSettingsGroupType
                ? "Название Компании"
                : "Название Группы"}
            </Text>
            <Text variant="subheader-3">{name}</Text>
          </Flex>
          {appConfig.features.userSettingsGroupType ? (
            <>
              <Flex vertical gap={8}>
                <Text color="secondary" variant="body-5">
                  Номер договора
                </Text>
                <Text variant="subheader-3">{contract_number}</Text>
              </Flex>
              <Flex vertical gap={8}>
                <Text color="secondary" variant="body-5">
                  Дата заключение договора
                </Text>
                <Text variant="subheader-3">{contract_date}</Text>
              </Flex>
            </>
          ) : null}
        </Flex>
      ) : null}
    </Flex>
  );
};
