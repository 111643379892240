import type { FC } from "react";
import { Flex, Text } from "@femida1/uikit";
import { Link } from "react-router-dom";
import s from "./SearchItem.module.scss";

interface SearchItemProps {
  title: string;
  description?: string;
  href: string;
}

export const SearchItem: FC<SearchItemProps> = ({
  title,
  description,
  href,
}) => (
  <Link to={href} className={s.searchItem}>
    <Flex className={s.searchItem__content} vertical>
      <Text variant="header-2">{title}</Text>
      {description ? (
        <Text variant="body-5" color="secondary">
          {description}
        </Text>
      ) : null}
    </Flex>
  </Link>
);
