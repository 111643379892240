import type { FC } from "react";
import { useCallback } from "react";
import { Flex, Input } from "@femida1/uikit";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitButton } from "@pages/Main/components/MainTabs/components/SubmitButton/SubmitButton";
import type {
  MainTabFieldValues,
  MainTabProps,
} from "@pages/Main/components/MainTabs/types";
import { dropZoneFileListSchema } from "@pages/Main/components/MainTabs/constants/dropZoneFileListSchema";
import type { ReportsInnUlSearchRequest } from "@femida1/schemas";
import { useReportsInnUlSearch } from "@femida1/gui_web_core";
import { validationMessage } from "@utils/constants";
import { correctDigitRegexCallback } from "@utils/validation";
import s from "../MainTabCompany.module.scss";

interface ReportsInnUlFieldValues
  extends ReportsInnUlSearchRequest,
    MainTabFieldValues {}

const schema = yup
  .object<ReportsInnUlSearchRequest>()
  .shape({
    inn: yup
      .string()
      .test(
        "test-digits",
        validationMessage.WrongInn,
        correctDigitRegexCallback,
      )
      .required()
      .min(10)
      .max(10),
    sopdFileList: dropZoneFileListSchema,
  })
  .required();

type MainTabCompanyNormalProps = MainTabProps;

export const MainTabCompanyNormal: FC<MainTabCompanyNormalProps> = ({
  onSubmit,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      inn: "",
    },
  });

  const { mutateAsync: search, isPending: isSearchPending } =
    useReportsInnUlSearch();

  const onSubmitInner = useCallback(
    (requestData: ReportsInnUlFieldValues) =>
      onSubmit({ requestData, search, withSopd: true, onSuccess: reset }),
    [search, onSubmit, reset],
  );

  return (
    <form onSubmit={handleSubmit(onSubmitInner)}>
      <Flex gap={12} className={s.controllersContainer}>
        <Controller
          name="inn"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              mask="0000000000"
              tabIndex={0}
              required
              placeholder="ИНН Юридического лица"
              {...field}
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="sopdFileList"
          control={control}
          render={({ field }) => (
            <SubmitButton
              fileList={field.value}
              onFileListChange={field.onChange}
              disabled={!isValid || isSearchPending}
            />
          )}
        />
      </Flex>
    </form>
  );
};
