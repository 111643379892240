import { LuShieldX } from "react-icons/lu";
import { ScoringType } from "@femida1/gui_web_core";
import { ReportType } from "@femida1/schemas";
import type { StandardReportSpec } from "../types";
import { getScoringType } from "./getScoringType";

interface GetStandardReportsProps {
  reportType: string;
  scoringMarker?: number;
  scoringMarkerIsSuccess: boolean;
}

export const getStandardReports = ({
  reportType,
  scoringMarker,
  scoringMarkerIsSuccess,
}: GetStandardReportsProps): StandardReportSpec[] => [
  {
    title: "Краткая сводка",
  },
  {
    title: "Отчеты по найденным лицам",
  },
  {
    title: "Открытые данные",
  },
  {
    title: "Пересечение границы",
  },
  {
    title: "Связанные лица",
  },
  {
    title: "Адреса",
  },
  {
    title: "Доходы",
  },
  {
    color:
      scoringMarkerIsSuccess &&
      getScoringType(scoringMarker) === ScoringType.Bad
        ? "red"
        : undefined,
    title: "Результаты скоринга",
  },
  {
    title: "Карта заказов",
  },
  ...(reportType === ReportType.SEARCH_FULL_DOSSIER_LIGHT
    ? []
    : [
        {
          title: "Телефонная книга",
        },
      ]),
  {
    color: "red",
    icon: <LuShieldX size={18} />,
    title: "Метки",
  },
];
