import type { AppConfig } from "./types";
import { krechetProdConfig } from "./configs/krechet/prod";
import { krechetStageConfig } from "./configs/krechet/stage";
import { odysseyBlackProdConfig } from "./configs/odysseyBlack/prod";
import { odysseyBlackStageConfig } from "./configs/odysseyBlack/stage";
import { odysseyProdConfig } from "./configs/odyssey/prod";
import { odysseyStageConfig } from "./configs/odyssey/stage";

interface EnvType {
  MODE: string;
}

/**
 * DO NOT EXPORT THIS VARIABLE
 * use configs only
 * @deprecated
 */
export const env: EnvType = { ...import.meta.env };

/**
 * TODO make more secure checking of dev
 */
const isDev =
  window.location.host.includes("femida-dev") ||
  window.location.host.includes("localhost");
const mode = `${env.MODE}_${isDev ? "stage" : "prod"}`;
const mapEnvToAppConfig: Record<string, AppConfig> = {
  krechet_prod: krechetProdConfig,
  krechet_stage: krechetStageConfig,
  odyssey_black_prod: odysseyBlackProdConfig,
  odyssey_black_stage: odysseyBlackStageConfig,
  odyssey_prod: odysseyProdConfig,
  odyssey_stage: odysseyStageConfig,
};

export const appConfig: AppConfig = mapEnvToAppConfig[mode];
