import { Image } from "antd";
import { Text, Block, Button, useNotification, Flex } from "@femida1/uikit";
import type { FC } from "react";
import { useMemo } from "react";
import { AppRoute } from "@app/routes/constants";
import { useTelegramUserBotRetrieveAll } from "@femida1/gui_web_core";
import type { TelegramUserBot } from "@femida1/schemas";
import { TelegramUserBotStatus } from "@femida1/schemas";
import { PageHeader } from "@app/layouts/PageHeader/PageHeader";
import { MdFileCopy } from "react-icons/md";
import qrBotImage from "@assets/images/telegram_qr_code.png";
import s from "./Telegram.module.scss";
import { TelegramList } from "./components/TelegramList/TelegramList";

const QR_BOT_LINK = "https://t.me/odyssey_search_v2_bot";

const filterAwaitingApprovalBots = (bot: TelegramUserBot) =>
  bot.status === TelegramUserBotStatus.AwaitingApproval;
const filterNotAwaitingApprovalBots = (bot: TelegramUserBot) =>
  bot.status !== TelegramUserBotStatus.AwaitingApproval;

export const Telegram: FC = () => {
  const { data: botsResponse, isSuccess: isBotsSuccess } =
    useTelegramUserBotRetrieveAll({ limit: 100 });
  /**
   * TODO move filter to backend
   */
  const bots = useMemo(
    () => botsResponse?.filter(filterAwaitingApprovalBots),
    [botsResponse],
  );
  const [api] = useNotification();

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(QR_BOT_LINK);
      api.success({
        message: "Ссылка скопирована в буфер обмена",
      });
    } catch (error) {
      api.error({
        message: "Не удалось скопировать ссылку",
        description: JSON.stringify(String(error), null, 2),
      });
    }
  };

  return (
    <Flex vertical gap={32} className={s.wrapper}>
      <PageHeader
        title="Телеграм бот"
        mapPathToTitle={{ [AppRoute.Telegram]: "Телеграм бот" }}
        paths={[AppRoute.Telegram]}
      />
      <Block>
        <Flex vertical gap={32}>
          <Text variant="header-6">Вход по QR-коду</Text>
          <Flex vertical align="center" gap={10} className={s.textContainer}>
            <Image
              src={qrBotImage}
              preview={false}
              alt={QR_BOT_LINK}
              height={212}
              width={212}
            />
            <Text variant="subheader-4">
              Отсканируйте для входа с мобильного устройства
            </Text>
            <Button
              width="full"
              size="s"
              type="flat_secondary"
              link={QR_BOT_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text color="secondary" variant="subheader-3">
                или перейдите по ссылке
              </Text>
            </Button>
            <Button width="fit" type="secondary" onClick={handleCopy}>
              <Text underline>
                <Flex align="center" gap={4}>
                  {QR_BOT_LINK}
                  <MdFileCopy size={20} />
                </Flex>
              </Text>
            </Button>
          </Flex>
        </Flex>
      </Block>
      {isBotsSuccess && bots?.length ? (
        <Block>
          <Flex vertical gap={32}>
            <Text variant="header-6">Ожидают подтверждения</Text>
            <TelegramList
              withPagination
              filterBots={filterAwaitingApprovalBots}
            />
          </Flex>
        </Block>
      ) : null}
      <Block>
        <Flex vertical gap={32}>
          <Text variant="header-6">Аккаунты</Text>
          <TelegramList
            withPagination
            filterBots={filterNotAwaitingApprovalBots}
          />
        </Flex>
      </Block>
    </Flex>
  );
};
