import type { FC } from "react";
import { useCallback, useMemo } from "react";
import { Card as AntCard, Skeleton } from "antd";
import {
  Flex,
  Text,
  Button,
  useModalMessage,
  Dropdown,
  NotesPin,
} from "@femida1/uikit";
import { LuChevronRight } from "react-icons/lu";
import { CommonModal } from "@components/CommonModal/CommonModal";
import { AppRoute } from "@app/routes/constants";
import { generatePath, Link } from "react-router-dom";
import {
  mapReportTypeToLabel,
  toHumanReadableDate,
  useReportsDelete,
} from "@femida1/gui_web_core";
import { getReportTypeToTitleGetter } from "@utils/reports/getReportTypeToTitleGetter";
import { ReportType } from "@femida1/schemas";
import { MdStickyNote2 } from "react-icons/md";
import { IoMdMore } from "react-icons/io";
import type { Report } from "@femida1/schemas";
import { NoteModal } from "@components/NoteModal";
import styles from "./HistoryListCard.module.scss";
import { Status } from "./components/Status/Status";

interface HistoryListCardProps {
  report?: Report;
  isLoading?: boolean;
}

export const HistoryListCard: FC<HistoryListCardProps> = ({
  report,
  isLoading,
}) => {
  const {
    created_at = "",
    id = "",
    request = "",
    request_source = "",
    has_sopd,
    note,
    user_name,
    type = ReportType.SEARCH_ADDRESS,
  } = report || {};
  const { mutateAsync: reportDelete, isPending: isReportDeleting } =
    useReportsDelete();
  const { openModal } = useModalMessage();

  const onDelete = useCallback(() => {
    openModal({
      children: (
        <CommonModal
          type="normal"
          title={
            <>
              Вы уверены, что хотите удалить проверку?
              <br />
              Это действие необратимо, и отчет также
              <br />
              будет удален.
            </>
          }
          primaryButtonProps={{
            children: "Да, удалить",
            onClick: () => reportDelete({ id }),
            disabled: isReportDeleting,
          }}
          secondaryButtonProps={{ children: "Отменить" }}
        />
      ),
    });
  }, [id, reportDelete, openModal, isReportDeleting]);

  const label = mapReportTypeToLabel[type];
  const title = useMemo(
    () => getReportTypeToTitleGetter(type)?.(request),
    [type, request],
  );

  return (
    <AntCard className={styles.card}>
      <Skeleton loading={isLoading}>
        <Flex component="section" vertical gap={12}>
          <Flex
            component="h3"
            className={styles.header}
            align="center"
            justify="space-between"
          >
            <Flex gap={10} align="center">
              <Status reportStatus={report?.status} />
              {created_at && (
                <Text variant="body-2" color="secondary">
                  {toHumanReadableDate(created_at)}
                </Text>
              )}
              {user_name && (
                <Text variant="body-2" color="secondary">
                  {user_name}
                </Text>
              )}
              {has_sopd && (
                <Flex gap={2} className={styles.header_sopd}>
                  <MdStickyNote2 />
                  <Text variant="body-2" color="secondary">
                    СОПД
                  </Text>
                </Flex>
              )}
              {note && (
                <NoteModal
                  type={type}
                  reportId={id}
                  buttonTitle={
                    <Text variant="body-4" color="secondary" underline>
                      <Flex align="center" gap={4}>
                        <NotesPin /> Заметка
                      </Flex>
                    </Text>
                  }
                  note={note}
                  isNoteEditing={false}
                />
              )}
            </Flex>
            <Dropdown
              items={[
                {
                  key: "note",
                  label: (
                    <NoteModal
                      type={type}
                      reportId={id}
                      note={note}
                      buttonTitle={
                        <Text
                          variant="body-4"
                          className={styles.add_note_button}
                        >
                          {note ? "Изменить" : "Добавить"} заметку
                        </Text>
                      }
                      buttonClassName={styles.add_note_button}
                    />
                  ),
                },
                {
                  key: "delete",
                  label: "Удалить",
                  disabled: isReportDeleting,
                  onClick: onDelete,
                },
              ]}
              mouseLeaveDelay={0.6}
              placement="bottomRight"
              // eslint-disable-next-line react/no-unstable-nested-components
              body={() => <IoMdMore cursor="pointer" color="black" size={24} />}
            />
          </Flex>
          <Flex vertical gap={4}>
            {label && (
              <Text variant="body-2" color="secondary">
                {label}
              </Text>
            )}
            {title &&
              ([
                ReportType.SEARCH_PHOTO_BY_IMAGE,
                ReportType.SEARCH_PHOTO_BY_FACE_LIGHT,
              ].includes(type) ? (
                <img
                  width={48}
                  height={48}
                  className={styles.image}
                  alt="search_image"
                  src={`data:image/png;base64,${title}`}
                />
              ) : (
                <Text
                  variant="subheader-4"
                  className={styles.title}
                  ellipsisLines={1}
                >
                  {title}
                </Text>
              ))}
          </Flex>
          <Flex
            justify="space-between"
            align="center"
            className={styles.footer}
          >
            <Flex vertical gap={4}>
              <Text variant="body-2" color="secondary">
                Источник запроса
              </Text>
              <Text variant="body-2">{request_source}</Text>
            </Flex>
            <div className={styles.footer__link}>
              <Link
                to={`${generatePath(AppRoute.Report, {
                  reportId: id,
                })}?type=${type}`}
                state={{ id, type }}
                replace
                className={styles.footer__link}
              >
                <Button
                  size="s"
                  type="secondary"
                  width="full"
                  disabled={isReportDeleting}
                  iconRight={<LuChevronRight strokeWidth={3} />}
                >
                  Смотреть отчёт
                </Button>
              </Link>
            </div>
          </Flex>
        </Flex>
      </Skeleton>
    </AntCard>
  );
};
