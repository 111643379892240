import {
  calculateReportStatus,
  DataCard,
  FullDossier,
  OpenedDataCard,
  PhoneBookCard,
  mapReportTypeToTitle,
  RelatedPersonsCard,
  FoundPersonsCard,
} from "@femida1/gui_web_core";
import { RetrieveStatus } from "@entities/RetrieveStatus/RetrieveStatus";
import { OrderMap } from "@entities/SearchPhone/components/OrderMap/OrderMap";
import { useCallback, useEffect, useMemo } from "react";
import type {
  ReportsPhoneRetrieveResponse,
  EMultiQueryKeys,
  ReportCommonProps,
} from "@femida1/schemas";
import {
  isReportsOpenSourcesIoSphere,
  FoundStatus,
  ReportStatus,
} from "@femida1/schemas";
import {
  Expand,
  Text,
  useNotification,
  Flex,
  useModalMessage,
} from "@femida1/uikit";
import cn from "classnames";
// eslint-disable-next-line import/no-cycle
import { MultiQueryReportsByType } from "@entities/MultiQueryReportsByType/MultiQueryReportsByType";
import { ReportHeader } from "@components/ReportHeader/ReportHeader";
import { useSubReportsVisibility } from "@pages/Root/components/SubReportsVisibilityContextProps/useSubReportsVisibility";
import { NoteModalFullDossier } from "@components/NoteModal/NoteModalFullDossier";
import { NoteModalMultiQueryFullDossier } from "@components/NoteModal/NoteModalMultiQueryFullDossier";
import { SearchByFound } from "@components/SearchByFound/SearchByFound";
import { usePdfGenerateHandlers } from "@app/api/pdf/usePdfGenerateHandlers";
import { RelatedPersonsModal } from "@components/RelatedPersonsModal/RelatedPersonsModal";
import { DownloadCsvButton } from "@components/DownloadCsvButton/DownloadCsvButton";
import { appConfig } from "@app/providers/AppFeatures/appConfig";
import { renderFoundPerson } from "@components/FoundPersons/FoundPersons";
import s from "./SearchPhone.module.scss";

export type SearchPhoneProps = {
  report: ReportsPhoneRetrieveResponse;
  isSubReport?: boolean;
  isFullDossierReport?: boolean;
} & ReportCommonProps;

export const SearchPhone = ({
  report,
  type,
  reportId,
  isSubReport,
  isFullDossierReport,
}: SearchPhoneProps) => {
  const { openModal, closeModal } = useModalMessage();

  const { visibilityState, setVisibilityState } = useSubReportsVisibility();
  const isMultiQueryReport = useMemo(
    () => report.multi_query?.result !== null,
    [report.multi_query?.result],
  );

  useEffect(() => {
    if (report.request_describe)
      setVisibilityState(report.request_describe, !isSubReport);
  }, []);

  const expanded: boolean = useMemo(
    () => !!report.request_describe && visibilityState[report.request_describe],
    [report.request_describe, visibilityState],
  );
  const toggleExpanded = useCallback(() => {
    if (report.request_describe)
      setVisibilityState(report.request_describe, !expanded);
  }, [expanded, report.request_describe, setVisibilityState]);

  const [api] = useNotification();

  const { onReportLoad, onReportPrint, isLoadingGeneratePdf } =
    usePdfGenerateHandlers({
      api,
      type,
      reportId,
    });

  return (
    <RetrieveStatus
      isSubReport={isSubReport}
      status={
        calculateReportStatus({
          sourceStatuses: [
            report?.person?.status,
            report?.sub_reports?.status,
            report?.possible_networks?.status,
            report?.open_sources?.iosphere?.status,
          ],
          person: report?.person?.status,
          found: report?.person?.found === FoundStatus.found,
        }) || report?.status
      }
    >
      {report.multi_query && isMultiQueryReport && (
        <ReportHeader
          multiQueryReport={report.multi_query}
          isSubReport={isSubReport}
          type={type}
          status={report.multi_query.status}
          reportId={reportId}
          onReportLoad={onReportLoad}
          onReportPrint={onReportPrint}
          note={report.note}
          isLoadingGeneratePdf={isLoadingGeneratePdf}
          requestDescribe={report?.request_describe}
        />
      )}
      <div
        id={isSubReport ? report?.request_describe : undefined}
        data-name={isSubReport ? "block-sub-report" : undefined}
        className={cn(
          s.container,
          isMultiQueryReport && s.containerWithMultiSearch,
        )}
      >
        {report?.person?.status && (
          <FullDossier
            isSubReport={isSubReport}
            renderHeaderDescribe={
              isFullDossierReport
                ? () => `${mapReportTypeToTitle[type]}: `
                : undefined
            }
            isMultiQueryReport={isMultiQueryReport}
            phones={report?.person?.person_info?.phones}
            snilses={report?.person?.person_info?.snilses}
            inns={report?.person?.person_info?.inns}
            passports={report?.person.person_info?.passports}
            emails={report?.person?.person_info?.emails}
            requestDescribe={report?.request_describe}
            autos={report?.person?.person_info?.autos}
            persons={report?.person?.person_info?.persons}
            type={type}
            status={report?.person?.status}
            otherLastNames={report?.other_last_names}
            onReportLoad={onReportLoad}
            onReportPrint={onReportPrint}
            renderNoteModal={() => (
              <NoteModalFullDossier reportId={reportId} type={type} />
            )}
            renderMultiQueryNoteModal={({ note }) => (
              <NoteModalMultiQueryFullDossier
                reportId={reportId}
                type={type}
                note={note}
              />
            )}
            renderSearchByFound={(props) => <SearchByFound {...props} />}
            isLoadingGeneratePdf={isLoadingGeneratePdf}
          />
        )}
        {(!isSubReport || (isSubReport && expanded)) && (
          <>
            {report?.sub_reports?.status && (
              <FoundPersonsCard
                isSubReport={isSubReport}
                report_list={report?.sub_reports?.report_list}
                status={report?.sub_reports?.status}
                reportFioAsGift={appConfig.features.reportFioAsGift}
                renderFoundPerson={renderFoundPerson}
              />
            )}
            {report?.possible_networks?.status && (
              <RelatedPersonsCard
                isSubReport={isSubReport}
                possibleNetworks={report?.possible_networks?.result}
                frequentNetworks={report?.frequent_networks?.result}
                status={report?.possible_networks?.status}
                subtitle={<DownloadCsvButton reportId={reportId} />}
                onItemShow={(possibleNetworks) =>
                  openModal({
                    children: (
                      <RelatedPersonsModal
                        possibleNetworks={possibleNetworks}
                        onClose={closeModal}
                      />
                    ),
                    maskClosable: true,
                  })
                }
              />
            )}
            {report?.open_sources && (
              <OpenedDataCard
                isSubReport={isSubReport}
                isPhoneSearch
                openSources={report?.open_sources}
                status={
                  report?.open_sources &&
                  isReportsOpenSourcesIoSphere(report?.open_sources)
                    ? report?.open_sources?.iosphere?.status
                    : ReportStatus.Finished
                }
              />
            )}
            {report?.person?.status && (
              <PhoneBookCard
                isSubReport={isSubReport}
                phoneBook={report?.person?.person_info?.phone_book}
                status={report?.person?.status}
              />
            )}
            {report?.person?.status && Boolean(report?.order_map?.found) && (
              <OrderMap
                isSubReport={isSubReport}
                status={report?.order_map?.status}
                orderMap={report?.order_map?.data}
              />
            )}
            {report?.person?.person_info?.data?.map(
              (reportsPersonInfoData, index) => (
                <DataCard
                  /* eslint-disable-next-line react/no-array-index-key */
                  key={index}
                  reportsPersonInfoData={reportsPersonInfoData}
                  status={report?.person?.status}
                  isSubReport={isSubReport}
                />
              ),
            )}
          </>
        )}
        {isSubReport && (
          <Flex justify="center">
            <Expand
              state={expanded}
              toggle={toggleExpanded}
              iconSize={20}
              className={s.container__expand}
            >
              <Text color="inherit">{`${expanded ? "Скрыть" : "Показать"} отчет`}</Text>
            </Expand>
          </Flex>
        )}
      </div>
      {report.multi_query &&
        isMultiQueryReport &&
        Object.entries(report.multi_query.result).map(([key, value]) => {
          const multiQueryRequests = Object.values(value);
          if (!multiQueryRequests.length) return null;

          return (
            <MultiQueryReportsByType
              key={key}
              multiQueryRequests={value}
              multiQueryKey={key as EMultiQueryKeys}
            />
          );
        })}
    </RetrieveStatus>
  );
};
