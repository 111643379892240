import { useNotification } from "@femida1/uikit";
import { RetrieveVkView } from "@femida1/gui_web_core";
import type {
  ReportCommonProps,
  ReportsVkRetrieveResponse,
} from "@femida1/schemas";
import { NoteModalFullDossier } from "@components/NoteModal/NoteModalFullDossier";
import { NoteModalMultiQueryFullDossier } from "@components/NoteModal/NoteModalMultiQueryFullDossier";
import { SearchByFound } from "@components/SearchByFound/SearchByFound";
import { usePdfGenerateHandlers } from "@app/api/pdf/usePdfGenerateHandlers";
import { RetrieveStatus } from "@entities/RetrieveStatus/RetrieveStatus";

export interface RetrieveVkProps extends ReportCommonProps {
  report: ReportsVkRetrieveResponse;
}

export const RetrieveVk = ({ report, type, reportId }: RetrieveVkProps) => {
  const [api] = useNotification();

  const { onReportLoad, onReportPrint, isLoadingGeneratePdf } =
    usePdfGenerateHandlers({
      api,
      type,
      reportId,
    });

  return (
    <RetrieveStatus status={report.status}>
      <RetrieveVkView
        report={report}
        reportId={reportId}
        type={type}
        isLoadingGeneratePdf={isLoadingGeneratePdf}
        onReportLoad={onReportLoad}
        onReportPrint={onReportPrint}
        renderNoteModal={() => (
          <NoteModalFullDossier reportId={reportId} type={type} />
        )}
        renderMultiQueryNoteModal={({ note }) => (
          <NoteModalMultiQueryFullDossier
            reportId={reportId}
            type={type}
            note={note}
          />
        )}
        renderSearchByFound={(props) => <SearchByFound {...props} />}
      />
    </RetrieveStatus>
  );
};
