import type { NotificationInstance } from "antd/es/notification/interface";
import type { AxiosResponse } from "axios";
import axios from "axios";

interface HandleBackendErrorsProps {
  api: NotificationInstance;
  error: unknown;
}

const message4xx = "Не удалось выполнить запрос";
const message5xx = "Произошла непредвиденная ошибка";

interface IErrorResponse<T = unknown> extends Omit<AxiosResponse, "data"> {
  data: T & { localize_message: string };
}

const isErrorResponse = (
  response?: AxiosResponse,
): response is IErrorResponse => {
  if (!response) return false;
  return typeof response === "object" && true && "data" in response;
};

export const handleBackendErrors = ({
  api,
  error,
}: HandleBackendErrorsProps): boolean => {
  if (!axios.isAxiosError(error)) {
    return false;
  }

  const status = error.response?.status;
  if (!status) {
    return false;
  }
  const errorResponse = error.response;

  const description = isErrorResponse(errorResponse)
    ? `${status} ${errorResponse.data.localize_message}`
    : "";

  if (error.code === "ERR_NETWORK" || (status >= 500 && status < 600)) {
    api.error({
      message: message5xx,
      description,
    });

    return true;
  }

  if (status >= 400 && status < 500) {
    api.error({
      message: message4xx,
      description,
    });

    return true;
  }

  return false;
};
