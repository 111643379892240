import { Flex, Block, Text } from "@femida1/uikit";
import s from "./Tariffs.module.scss";
import { TariffBlock } from "./components/TariffBlock/TariffBlock";

export const Tariffs = () => (
  <Flex vertical gap={32} className={s.wrapper}>
    <Block>
      <Flex vertical gap={24}>
        <Text variant="subheader-5">Ежемесячный расчет по запросам</Text>
        <TariffBlock
          title="700"
          label="запросов в год"
          requestPrice={114}
          tariffPrice={80000}
        />
        <Text color="secondary" variant="body-4">
          При необходимости, согласованию могут подлежать иные индивидуальные
          условия предоплаты, касающиеся требуемого количества запросов и
          стоимости услуг на тот или иной отчетный период.
        </Text>
      </Flex>
    </Block>
  </Flex>
);
