export enum TestId {
  AsideMenu = "AsideMenu",
  AsideMenuButton = "AsideMenuButton",

  AuthFormModal = "AuthFormModal",
  AuthFormLoginCloseButton = "AuthFormLoginCloseButton",
  AuthFormRegisterCloseButton = "AuthFormRegisterCloseButton",
  AuthFormConfirmCodeCloseButton = "AuthFormConfirmCodeCloseButton",
  AuthFormChangePasswordCloseButton = "AuthFormChangePasswordCloseButton",
}
